import { useContext } from "react";
import { MapStateContext } from "../../../contexts/mapStateContext";
import { Button, Tooltip, Typography } from "antd";
import { PerimeterContext } from "../../../../../contexts/PerimeterContext";
import { TCityDict } from "../../../../../models/general/city";

function irisLabelFromIrisCode(cd: TCityDict, code: string | number | undefined) {
  if (!code) return "";

  const irisDef = Object.values(cd).find(c => !!c.irisCodes[code]);

  if (!irisDef) return "";

  return irisDef.irisCodes[code].name;
}

function roadTransportIdShapeFromFeatures(fs: Record<string, any> | undefined): { idShape: string | undefined, idShapeType: 'iris_code' | 'road_name' | 'link_id' } {
  if (!fs) return { idShape: undefined, idShapeType: 'iris_code' };

  if (fs?.road_name) {
    return { idShape: fs.road_name, idShapeType: 'road_name' };
  }

  if (fs?.link_id) {
    return { idShape: fs.link_id, idShapeType: 'link_id' };
  }

  return { idShape: undefined, idShapeType: 'iris_code' };
}

interface MapExportProps {
  exportsLeft: number | undefined;
  isDisabled: boolean;
  isLoading: boolean
  handleExport: (idShape: string | number, idShapeType: 'iris_code' | 'road_name' | 'link_id', idShapeLabel: string) => void;
}

const MapExport: React.FC<MapExportProps> = (props) => {
  const { isDisabled, handleExport, isLoading, exportsLeft } = props;
  const { selectedSector, selectedFeatures } = useContext(MapStateContext);
  const { cityDict } = useContext(PerimeterContext);

  const irisCodes = Object.values(cityDict).reduce((prev, curr) => {
    return prev.concat(Object.entries(curr.irisCodes).map(([key, value]) => ({ value: key, label: value.name })));
  }, [] as { value: string, label: string }[]);

  const irisCode = selectedFeatures?.iris_code;
  const irisCodeLabel = irisLabelFromIrisCode(cityDict, irisCode);

  const { idShape: roadIdShape, idShapeType: roadIdShapeType } = roadTransportIdShapeFromFeatures(selectedFeatures);

  const handleRoadExport = () => {
    if (!roadIdShape) return;
    handleExport(roadIdShape, roadIdShapeType, roadIdShape);
  };

  const handleIrisExport = () => {
    if (!irisCode) return;
    handleExport(irisCode, 'iris_code', irisCodeLabel);
  };

  const isButtonDisabled = !irisCode || isDisabled || isLoading || (exportsLeft !== undefined && exportsLeft <= 0);

  return (
    <div className="flex flex-col">
      <div className="font-extrabold text-lg mb-2">Zone sélectionnée</div>
      {selectedSector === 'roadtransport' &&
        <>
          <Typography.Text style={{ marginBottom: 8 }}>Zoomez et cliquez sur une route de la carte pour l'exporter</Typography.Text>
          {roadIdShape ?
            <>
              <Typography.Text italic style={{ marginBottom: 8 }}>Route sélectionnée :</Typography.Text>
              <Typography.Text strong style={{ marginBottom: 8 }}>{roadIdShape}</Typography.Text>
            </>
            :
            <Typography.Text italic style={{ marginBottom: 8 }}>Pas de route sélectionnée</Typography.Text>
          }

          <Button loading={isLoading} type="primary" disabled={!irisCode || isDisabled || isLoading} style={{}} onClick={handleRoadExport}>Exporter</Button>
        </>
      }
      {selectedSector !== 'roadtransport' && (
        <>
          <Typography.Text style={{ marginBottom: 8 }}>Zoomez et cliquez sur un bâtiment pour exporter une zone IRIS</Typography.Text>
          {irisCode ?
            <>
              <Typography.Text italic style={{ marginBottom: 8 }}>Zone sélectionnée :</Typography.Text>
              <Typography.Text strong style={{ marginBottom: 8 }}>{irisCodeLabel} </Typography.Text>
            </>
            :
            <Typography.Text italic style={{ marginBottom: 8 }}>Pas de zone sélectionnée</Typography.Text>
          }

          {exportsLeft !== undefined ?
            <Tooltip title={`Il vous reste ${exportsLeft} téléchargements`}>
              <Button loading={isLoading} type="primary" disabled={isButtonDisabled} style={{}} onClick={handleIrisExport}>Exporter</Button>
            </Tooltip>
            :
            <Button loading={isLoading} type="primary" disabled={isButtonDisabled} style={{}} onClick={handleIrisExport}>Exporter</Button>
          }
        </>
      )}
    </div>
  )
}

export default MapExport;