import React, { useContext, useEffect } from "react";
import { EntityContext } from "../../../../../contexts/EntityContext";
import { MapStateContext } from "../../../contexts/mapStateContext";
import { Empty, Typography } from "antd";
import withSuspenseAndFallback from "../../../../../components/hoc/withSuspenseAndFallback";
import ExploreTargetBarchart from "../../common/ExploreTargetBarchart";
import GaugeData from "./GaugeData";
import SkeletonLoader from "../../common/SkeletonLoader";
import { IScoresConf } from "../../../../../models/exploreV2/sector";

interface ScoreProps {
  config: IScoresConf
}

/**
 * Scores section for selected tile
 * @param props section configuration
 * @returns Main component for scores section
 */
const Scores: React.FC<ScoreProps> = (props) => {
  const { activeEntity } = useContext(EntityContext);
  const { selectedSector, selectedSubsector, selectedFeatures, currentTier } = useContext(MapStateContext);

  const { config } = props;

  const districtName = selectedFeatures?.district_name as string;

  // We use this convention in mapbox tilesets
  const prefix = selectedSector !== 'all'
    ? selectedSubsector !== 'all' ? `${selectedSubsector}_` : `${selectedSector}_`
    : '';
  const intensityValue = selectedFeatures?.[`${prefix}${config.intensityFieldStat}`] as number | undefined;
  const emissionsValue = selectedFeatures?.[`${prefix}${config.volumeFieldStat}`] as number | undefined;

  if (!config.intensityFieldStat && !config.volumeFieldStat && !config.barChart) return <Empty description="Pas de données" />;

  return (
    <div className="flex flex-col space-y-4 p-4 my-2">
      {(config.intensityFieldStat && intensityValue !== undefined) &&
        <GaugeData
          title="Intensité"
          description="Emission par unité en comparaison avec la moyenne du territoire"
          sector={selectedSector}
          subsector={selectedSubsector}
          tier={currentTier}
          fieldStat={config.intensityFieldStat}
          value={intensityValue}
        />
      }
      {(config.volumeFieldStat && emissionsValue !== undefined) &&
        <GaugeData
          title="Volume"
          description="Total des émissions en comparaison avec la moyenne du territoire"
          sector={selectedSector}
          subsector={selectedSubsector}
          tier={currentTier}
          fieldStat={config.volumeFieldStat}
          value={emissionsValue}
        />
      }
      {config.barChart &&
        <ExploreTargetBarchart
          title={config.barChart.title}
          sector={selectedSector}
          fieldStat={config.barChart.fieldStat}
          fieldGroupBy={config.barChart.fieldGroupBy}
          funcStat={config.barChart.funcStat}
          districtName={districtName}
          includeDistrict={config.barChart.includeDistrict}
          xLabels={config.barChart.xLabels}
          value={selectedFeatures?.[config.barChart.fieldGroupBy]}
        />
      }
    </div>
  );
}

export default withSuspenseAndFallback(Scores, <SkeletonLoader />);