import { Button, Divider, Tag, Typography } from "antd";
import { ILayerFilter } from "../../../../../../models/general/filters";
import _ from 'lodash';
import { CloseCircleOutlined, CloseOutlined, MinusOutlined, PlusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { IFilter } from "./ExploreFilters";
import LocationIcon from "../../../../../../assets/icons/LocationIcon";
// import { IFilter } from "./ExploreFiltersTmp";

const SELECTED_COLOR = "#00A947";
const UNSELECTED_COLOR = "#919EAB";

interface DropdownRenderProps {
  filters: IFilter[];
  onSelectFilter: (f: any) => void;
  onClose: () => void;
}

const DropdownRender: React.FC<DropdownRenderProps> = (props) => {
  const { filters, onSelectFilter, onClose } = props;

  const filtersByCat = _.groupBy(filters, 'category')

  return (
    <div className="rounded-lg bg-white shadow-lg p-4 max-h-[400px] overflow-y-auto flex flex-col">
      <Button className="self-end" type="text" icon={<CloseOutlined style={{ color: UNSELECTED_COLOR }} />} onClick={onClose} />
      {Object.keys(filtersByCat).map((cat) => {
        return (
          <>
            <div key={cat} className="mb-2">
              <Typography.Title level={4}>{cat}</Typography.Title>
              {filtersByCat[cat].map((filter) => (
                <>
                  {(filter.tier === 2 || !filter.tier) && <Tag color="cyan">⬡ Quartier</Tag>}
                  {(filter.tier === 3 || !filter.tier) && <Tag color="blue"><LocationIcon fill="blue"/> Bâtiment / Rue</Tag>}
                  <div
                    key={filter.name}
                    className="flex flex-row items-center justify-between"
                    style={{ color: filter.isSelected ? SELECTED_COLOR : UNSELECTED_COLOR }}
                  >

                    <p>{filter.name}</p>
                    <Button
                      type="text"
                      icon={filter.isSelected ? <MinusOutlined style={{ color: SELECTED_COLOR }} /> : <PlusOutlined style={{ color: UNSELECTED_COLOR }} />}
                      onClick={() => {
                        onSelectFilter(filter);
                      }}
                    />
                  </div>
                  <Divider className="mt-0 mb-1"/>
                </>
              ))}
            </div>
          </>
        )
      })}
    </div>
  );
};

export default DropdownRender;