import { IEntity } from "../../../models/general/entity";
import { IUserInput } from "../../../models/user";

import APIService, { APIResult, apiUrl } from "../common";

export class UserService extends APIService {
  private route = apiUrl + "/users";

  fetchEntities(userId: string): Promise<APIResult<IEntity[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${userId}/entities`);
  }

  saveUser(
    entityId: string,
    user: Omit<IUserInput, "id">
  ): Promise<APIResult<IEntity[]>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${entityId}`,
      {},
      user
    );
  }

  updateUser(user: IUserInput): Promise<APIResult<IEntity[]>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${user.id}`,
      {},
      user
    );
  }

  deleteUser(userId: string): Promise<APIResult<IUserInput[]>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/${userId}`);
  }

  fetchUsersByEntity(entityId: string): Promise<APIResult<IUserInput[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${entityId}`);
  }

  fetchExportsLeft(userId: string): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${userId}/exports-left`);
  }
}
