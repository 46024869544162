import { IDictionary } from "../../../../../models/general/dictionary";
import { featureFieldNameToLabel, readFromCommonDictionary } from "../../../../../services/utils";

function getNewKey(key: string, dict: IDictionary) {
  if (featureFieldNameToLabel(dict, key)) {
    if (dict.features[key]?.unit && dict.features[key]?.unitsLinker?.['1']) {
      return `${featureFieldNameToLabel(dict, key)} (${dict.features[key]?.unitsLinker?.['1']}${dict.features[key]?.unit})`;
    }
    return featureFieldNameToLabel(dict, key);
  } else {
    return readFromCommonDictionary(dict, key) ?? key;
  }
}

function formatExportData(data: any[], dict: IDictionary) {
  if (data.length === 0) return [];

  const res = data.map(d => {
    const ret = Object.keys(d).reduce((acc, key) => {
      if (key.includes('energy_consumption_mwh') && key ) {
        const index = key.indexOf('energy_consumption_mwh');
        const usage = key.slice(index + 'energy_consumption_mwh'.length + 1);
        const newKey = `${getNewKey('energy_consumption_mwh', dict)} - ${readFromCommonDictionary(dict, usage)}`;
        acc[newKey] = d[key];
      } else {
        const newKey = getNewKey(key, dict);
        acc[newKey] = d[key];
      }

      return acc;
    }, {} as Record<string, any>);

    return ret;
  });

  return res;
}

export { formatExportData }