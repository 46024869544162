import { Button, Input, Select, Tooltip, Typography } from "antd";
import { useContext, useState } from "react";
import { PerimeterContext } from "../../../../../contexts/PerimeterContext";

interface ZoneExportProps {
  isExportDisabled: boolean;
  exportsLeft: number | undefined;
  isLoading: boolean;
  handleExportCityCode: (cityCode: string) => void;
}

const ZoneExport: React.FC<ZoneExportProps> = (props) => {
  const { isExportDisabled, handleExportCityCode, isLoading, exportsLeft } = props;

  const { cityDict, relevantCityCodes } = useContext(PerimeterContext);

  const [selectedZone, setSelectedZone] = useState<Record<string, any>>();

  const _handleExport = () => {
    const value = selectedZone?.value;

    // City code
    handleExportCityCode(value);
  }

  const options = relevantCityCodes.map((cityCode) => ({ value: cityCode, label: cityDict[cityCode].name }));

  const isButtonDisabled = !selectedZone || isExportDisabled || isLoading || (exportsLeft !== undefined && exportsLeft <= 0);

  return (
    <>
      <div className="font-extrabold text-lg mb-2">Commune ou arrondissement</div>
      {/* <Typography.Text className="text-[#555555]">Code IRIS ou commune</Typography.Text> */}
      <Select
        labelInValue
        bordered={true}
        showSearch
        value={selectedZone}
        placeholder="Ex: Saint-Germain en Laye"
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={true}
        optionFilterProp="label"
        onChange={(value) => setSelectedZone(value)}
        options={options}
        style={{ width: '100%', borderRadius: 0, borderColor: '#555555', height: 40 }}
      />
      {exportsLeft !== undefined ?
        <Tooltip title={`Il vous reste ${exportsLeft} téléchargements`}>
          <Button loading={isLoading} type="primary" className="w-full mt-4" disabled={isButtonDisabled} onClick={_handleExport}>Exporter</Button>
        </Tooltip> :
        <Button loading={isLoading} type="primary" className="w-full mt-4" disabled={isButtonDisabled} onClick={_handleExport}>Exporter</Button>
      }
    </>
  );
}

export default ZoneExport;