import { Typography } from "antd";
import { useTimeSeries } from "../../../../../../services/dataApi/hooks";
import AreaChartV2, { AreaChartElement } from "../../../../../../components/graphs/AreaChart/AreaChartV2";
import { getDataRendering } from "./utils";
import moment from "moment";
import { EntityContext } from "../../../../../../contexts/EntityContext";
import { useContext } from "react";

interface BuildingsTimeSeriesProps {
  sector: string;
  subsector: string;
  idShape: string;
  tier: number;
  startDate: string;
  endDate: string;
};

const BuildingsTimeSeries: React.FC<BuildingsTimeSeriesProps> = (props) => {
  const { sector, idShape, tier, subsector, startDate, endDate } = props;
  const {activeEntity} = useContext(EntityContext);

  const field = "energy_consumption_mwh";

  const { data } = useTimeSeries(
    activeEntity.instance,
    activeEntity.batch,
    sector,
    subsector !== 'all' ? subsector : undefined,
    startDate,
    endDate,
    tier,
    "conso_usage",
    idShape,
    "month",
  );

  if (!data || data.length === 0) return <></>;

  const { keys, dataRendering } = getDataRendering(field, data[0]);

  const areaChartData = data.map((d) => {
    const res: Record<string, any> = {
      x: moment(d.date_time).format("MMM YYYY"),
    };

    keys.forEach(k => {
      res[k] = d[k];
    });

    return res;
  });

  const areaChartProps = {
    data: areaChartData as unknown as AreaChartElement[],
    dataRendering,
    featureKey: field,
    selectedYear: 2023
  }
  
  return (
    <div className="w-full h-[250px] p-2">
      <Typography.Title level={4}>Evolutions des consommations par type d’usage</Typography.Title>
      <AreaChartV2 {...areaChartProps} />
    </div>
  )
}

export default BuildingsTimeSeries;