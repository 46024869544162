import { Button, Input, Space, Typography, Upload, message, notification } from "antd";
import { useContext, useState } from "react";
import { MapStateContext, MapStateDispatch } from "../../../contexts/mapStateContext";
import { EyeInvisibleOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { colors } from "../../../../../assets/styles/colors";
import JSZip from "jszip";
import shp from "shpjs";
import { IUploadLayer } from "../../../../../models/explore/layers/uploadLayer";

const MAIN_COLOR = "#555555";

interface ImportContentProps {
}

const ImportContent: React.FC<ImportContentProps> = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { uploadLayers, visibleUploadLayers } = useContext(MapStateContext);
  const dispatch = useContext(MapStateDispatch);

  const [WMSInputValue, setWMSInputValue] = useState("");
  const [WFSInputValue, setWFSInputValue] = useState("");

  const handleWFSUpload = async (link: string) => {
    if (uploadLayers?.find((layer) => layer.id === link)) {
      notification.error({
        message: "Echec de l'import",
        description:
          "La couche a déjà été importée.",
        placement: "topRight",
      });
      return;
    }

    if (/^(https?:\/\/)/.test(link)) {
      try {
        const response = await fetch(link);

        if (response.ok) {
          const layer: IUploadLayer = {
            id: link,
            type: "WFS",
            name: "WFS",
            data: link,
            isVisible: true,
            level: "other",
          };

          dispatch({ type: "ADD_UPLOAD_LAYER", layer });
          messageApi.open({
            type: "success",
            content: "Upload successful",
            style: {
              marginTop: "5vh",
            },
          });
        }
      } catch (error) {
        messageApi.open({
          type: "error",
          content: "Upload Failed",
          style: {
            marginTop: "5vh",
          },
        });
        console.error(error);
      }
    } else {
      notification.error({
        message: "Echec de l'import",
        description:
          "Le lien utilisé est corrompu et ne peut pas être utilisé.",
        placement: "topRight",
      });
    }
    setWFSInputValue("");
  };

  const handleWMSUpload = async (link: string) => {
    if (/^(https?:\/\/)/.test(link)) {
      try {
        const response = await fetch(link);

        if (response.ok) {
          const layer: IUploadLayer = {
            id: link,
            type: "WMS",
            name: "WMS",
            data: link,
            isVisible: true,
            level: "other",
          };
          dispatch({ type: "ADD_UPLOAD_LAYER", layer });
          messageApi.open({
            type: "success",
            content: "Upload successful",
            style: {
              marginTop: "5vh",
            },
          });
        }
      } catch (error) {
        console.error('error : ', error);
        messageApi.open({
          type: "error",
          content: "Upload Failed",
          style: {
            marginTop: "5vh",
          },
        });
      }
    } else {
      notification.error({
        message: "Echec de l'import",
        description:
          "Le lien utilisé est corrompu et ne peut pas être utilisé.",
        placement: "topRight",
      });
    }
    setWMSInputValue("");
  };

  const handleUpload = async (file: File) => {
    if (
      file.name.split(".")[1] === "json" ||
      file.name.split(".")[1] === "geojson"
    ) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const json = JSON.parse(reader.result as string);

          if (uploadLayers?.find((layer) => layer.id === file.name)) {
            notification.error({
              message: "Echec de l'import",
              description:
                "La couche a déjà été importée.",
              placement: "topRight",
            });
            return;
          };
          
          const layer: IUploadLayer = {
            id: file.name,
            name: file.name,
            data: json,
            isVisible: true,
            level: "other",
            type: "other",
          };

          dispatch({ type: "ADD_UPLOAD_LAYER", layer });

          messageApi.open({
            type: "success",
            content: "Upload successful",
            style: {
              marginTop: "5vh",
            },
          });
        } catch (error) {
          notification.error({
            message: "Echec de l'import",
            description:
              "Le fichier importé est corrompu et ne peut pas être utilisé.",
            placement: "topRight",
          });
        }
      };
      reader.readAsText(file);
    } else {
      const zip = new JSZip();
      const blob = await file!.arrayBuffer();

      zip.loadAsync(blob).then(async (contents) => {
        const uint8Array = new Uint8Array(blob);

        shp(uint8Array.buffer).then((data) => {
          if (Array.isArray(data)) {
            data.forEach((shpData) => {
              const layer: IUploadLayer = {
                id: shpData.fileName as string,
                name: shpData.fileName as string,
                data: shpData,
                isVisible: true,
                level: "other",
                type: "other",
              };

              dispatch({ type: "ADD_UPLOAD_LAYER", layer });
            });
          } else {
            const layer: IUploadLayer = {
              id: data.fileName as string,
              name: data.fileName as string,
              data: data,
              isVisible: true,
              level: "other",
              type: "other",
            };

            dispatch({ type: "ADD_UPLOAD_LAYER", layer });
          }

          messageApi.open({
            type: "success",
            content: "Upload successful",
            style: {
              marginTop: "5vh",
            },
          });
        });
      });
    };
  };

  function onToggleLayer(layerId: string) {
    dispatch({
      type: "TOGGLE_UPLOAD_LAYER",
      layerId,
    });
  }

  return (
    <div className="flex flex-col px-3 my-3 space-y-2">
      <div className="font-semibold text-base text-[#555555]">Import depuis un fichier</div>
      <Typography.Text style={{ color: "#545F71", fontSize: 12 }}>
        Formats acceptés (json, geojson, zip)
      </Typography.Text>
      <Upload
        accept=".json, .geojson, .zip"
        action={""}
        showUploadList={false}
        beforeUpload={handleUpload}
      >
        <Button type="primary" style={{ width: '100%'}}>Choisir</Button>
      </Upload>
      <div className="h-2" />
      <div className="font-semibold text-base text-[#555555]">Connexion à un service</div>
      <Typography.Text style={{ fontSize: 12, color: "#555555" }}>Import vecteur WFS</Typography.Text>
      <Input
        placeholder="https://wfs.abc.com"
        allowClear
        style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
        value={WFSInputValue}
        onChange={(e) => setWFSInputValue(e.target.value)}
        suffix={
          <Button
            type="primary"

            style={{
              margin: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={() => handleWFSUpload(WFSInputValue)}
          >
            Charger
          </Button>
        }
      />
      <Typography.Text style={{ fontSize: 12, color: "#555555" }}>Import image WMS</Typography.Text>
      <Input
        placeholder="https://wms.xyz.com"
        allowClear
        style={{ paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
        value={WMSInputValue}
        onChange={(e) => setWMSInputValue(e.target.value)}
        suffix={
          <Button
            type="primary"
            style={{
              margin: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={() => handleWMSUpload(WMSInputValue)}
          >
            Charger
          </Button>
        }
      />
      <div className="h-2" />
      <div className="font-semibold text-base text-[#555555]">Couches importées</div>
      <div className="my-3">
        {uploadLayers && uploadLayers.length > 0 ? (
          uploadLayers.map((layer) => (
            <Space
              key={layer.id}
              style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
            >
              <Typography.Text>{layer.id}</Typography.Text>
              <Button
                type="default"
                icon={
                  visibleUploadLayers?.includes(layer.id) ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
                onClick={() => onToggleLayer(layer.id)}
              />
            </Space>
          ))
        ) : (
          <Typography.Text style={{ fontStyle: "italic" }}>
            Aucune couche importée
          </Typography.Text>
        )}
      </div>

    </div>
  );
};

export default ImportContent;