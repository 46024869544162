import { Expression } from "mapbox-gl";

/**
 * Generates a painting for a mapbox layer with ranges [stop1, stop2], ..., [stopN, stopN+1]
 * @param field field property of mapbox layer
 * @param stops
 * @param colors
 * @returns Mapbox expression
 */
function generatePainting(
  field: string,
  stops: number[],
  colors: string[]
): Expression | string {
  // Handle invalid input
  if (stops.length === 0) {
    return "#000000"; // No painting
    // throw new Error('The number of stops should be equal to the number of colors - 1');
  }

  const base: Expression = ["interpolate", ["linear"], ["get", field]];
  for (let i = 0; i < stops.length; i++) {
    base.push(stops[i], colors[i] ?? "#000000");
  }

  return base;
}

/**
 * Generates a styling for a mapbox layer
 * @param type 'fill' | 'line' | 'fill-extrusion'
 * @param field field property of mapbox layer
 * @param stops stops
 * @param colors
 * @returns Styling object
 */
function generateStyling(
  type: "fill" | "line" | "fill-extrusion",
  field: string,
  stops: number[],
  colors: string[]
) {
  switch (type) {
    case "fill":
      return {
        "fill-color": generatePainting(field, stops, colors),
        // 'fill-opacity': 0.5,
        "fill-opacity": [
          "case",
          ["==", ["feature-state", "clicked"], true],
          0.5,
          0.5,
        ],
      };
    case "line":
      return {
        "line-color": generatePainting(field, stops, colors),
        "line-width": 8,
        "line-opacity": 1,
      };
    case "fill-extrusion":
      return {
        "fill-extrusion-opacity": 0.8,
        "fill-extrusion-height": {
          property: "nb_etages",
          stops: [
            [1, 10 * 1],
            [15, 10 * 15],
          ],
        },
        "fill-extrusion-color": [
          "case",
          ["==", ["feature-state", "clicked"], true],
          "blue",
          generatePainting(field, stops, colors),
        ],
      };
  }

  return {};
}

export { generateStyling };
