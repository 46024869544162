import { TEmittingSector } from "../../models/general/common";
import APIService, { APIResult } from "./common";
import { ISpatioTemporalParameters, ISpatioTemporalResponse, IStatisticsParameters, IStatisticsResponse, ITemporalParameters, ITemporalResponse } from "./types";

export class DataAPIService extends APIService {
  private route = process.env.REACT_APP_DATA_API_URL || "http://localhost";

  public async getStatistics(params: IStatisticsParameters): Promise<APIResult<IStatisticsResponse>> {
    const res = await this.makeAxiosJSONRequest<IStatisticsResponse>("POST", `${this.route}/statistics/`, {}, params);
    return res;
  }

  public async getTemporal(params: ITemporalParameters): Promise<APIResult<ITemporalResponse>> {
    const res = await this.makeAxiosJSONRequest<ITemporalResponse>("POST", `${this.route}/temporal/`, {}, params);
    return res;
  }

  public async getSpatioTemporal(params: ISpatioTemporalParameters): Promise<APIResult<ISpatioTemporalResponse>> {
    const res = await this.makeAxiosJSONRequest<ITemporalResponse>("POST", `${this.route}/spatioTemporal/`, {}, params);
    return res;
  }
}