const IDIcon = ({ fill }: { fill?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M11.9996 14.8804C11.0896 14.8804 10.3496 14.1403 10.3496 13.2303V10.7604C10.3496 9.85038 11.0896 9.11035 11.9996 9.11035C12.9096 9.11035 13.6496 9.85038 13.6496 10.7604V13.2303C13.6496 14.1403 12.9096 14.8804 11.9996 14.8804Z" stroke={fill ?? "#212121"}  stroke-width="1.5" stroke-linecap="round" />
      <path opacity="0.4" d="M16.98 13.4697C16.78 16.0497 14.62 18.0697 12 18.0697C9.24 18.0697 7 15.8297 7 13.0697V10.9297C7 8.16969 9.24 5.92969 12 5.92969C14.59 5.92969 16.72 7.89968 16.97 10.4197" stroke={fill ?? "#212121"}  stroke-width="1.5" stroke-linecap="round" />
      <path d="M15 2H17C20 2 22 4 22 7V9" stroke={fill ?? "#212121"}  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2 9V7C2 4 4 2 7 2H9" stroke={fill ?? "#212121"}  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15 22H17C20 22 22 20 22 17V15" stroke={fill ?? "#212121"}  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2 15V17C2 20 4 22 7 22H9" stroke={fill ?? "#212121"}  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default IDIcon;