import { Button, DatePicker, Typography } from "antd";
import { useContext, useState } from "react";
import dayjs, { type Dayjs } from "dayjs";
import moment from "moment";
import { EntityContext } from "../../../../../contexts/EntityContext";

interface PeriodPickerProps {
  freq: 'month' | 'year';
  startDate: number | null;
  endDate: number | null;
  onStartDateChange: (date: number | null) => void;
  onEndDateChange: (date: number | null) => void;
}

const PeriodPicker: React.FC<PeriodPickerProps> = (props) => {
  const { freq, onEndDateChange, onStartDateChange, startDate, endDate } = props;
  const { activeEntity } = useContext(EntityContext);
  const lastAvailableMonth = activeEntity.availableMonth;
  const availableYear = activeEntity.availableYears[0];

  const minDate = moment().set('year', 2019).set('month', 0).unix();
  const maxDate = freq === 'month' ? 
    moment().set('year', lastAvailableMonth.year).set('month', lastAvailableMonth.month).endOf('month').unix() : 
    moment().set('year', availableYear).endOf('year').unix();

  const handleStartDateChange = (date: Dayjs | null) => {
    const ts = freq === 'month' ? moment(dayjs(date).unix() * 1000).startOf('month').unix() : moment(dayjs(date).unix() * 1000).startOf('year').unix();
    onStartDateChange(ts);
  }

  const handleEndDateChange = (date: Dayjs | null) => {
    const ts = freq === 'month' ? moment(dayjs(date).unix() * 1000).endOf('month').unix() : moment(dayjs(date).unix() * 1000).endOf('year').unix();
    onEndDateChange(ts);
  }

  return (
    <>
      <div>
        <Typography.Text className="font-extrabold text-lg mb-2">Période</Typography.Text>
      </div>
      <Typography.Text className="text-[#555555]">Début</Typography.Text>
      <DatePicker
        picker={freq === 'month' ? 'month' : 'year'}
        size="large"
        format={freq === 'month' ? "MMMM YYYY" : "YYYY"}
        style={{ width: "100%", boxShadow: "0px 0px 5px 0px #0000001A" }}
        value={startDate ? dayjs.unix(startDate) : null}
        onChange={handleStartDateChange}
        disabledDate={(current) => current <= dayjs.unix(minDate) || current >= dayjs.unix(maxDate)}
      />
      <div className="h-2" />
      <Typography.Text className="text-[#555555]">Fin</Typography.Text>
      <DatePicker
        picker={freq === 'month' ? 'month' : 'year'}
        size="large"
        format={freq === 'month' ? "MMMM YYYY" : "YYYY"}
        style={{ width: "100%", boxShadow: "0px 0px 5px 0px #0000001A" }}
        value={endDate ? dayjs.unix(endDate) : null}
        onChange={handleEndDateChange}
        disabledDate={(current) => current <= dayjs.unix(minDate) || current >= dayjs.unix(maxDate)}
      />
    </>
  )
}

export default PeriodPicker;