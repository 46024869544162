import { useContext, useEffect, useRef, useState } from "react";
import {
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  Layer,
  LngLat,
  Map,
  MapRef,
  NavigationControl,
  Popup,
  Source,
  ViewStateChangeEvent,
  useMap,
} from "react-map-gl";
import "./MapboxMapV2.css";
import {
  propsFromStaticLayer,
  propsFromUploadLayer,
  propsFromUploadLineLayer,
  propsFromWMSLayer,
} from "./utilsV2";
import LayerV2 from "./LayerV2";
import {
  MapStateContext,
  MapStateDispatch,
} from "../../pages/explore-target/contexts/mapStateContext";
import { IAdditionalLayer } from "../../models/exploreV2/configuration";
import { Button, Space, Typography } from "antd";
import { readFromCommonDictionary } from "../../services/utils";
import { DictionaryContext } from "../../contexts/DictionaryContext";
import mapboxgl from "mapbox-gl";
import GeocoderControl from "./geocoder-control";
import DrawControl from "./DrawControl";
import { DrawModeChangeEvent } from "@mapbox/mapbox-gl-draw";
import MapContext from "../../pages/explore-target/contexts/mapContext";
import { track } from "../../services/analytics/analytics";
import { EXPLORE_TARGET_TILE_CLICK } from "../../services/analytics/constants";
import { EntityContext } from "../../contexts/EntityContext";

interface MapboxMapProps {
  mapId: string;
  layers: any[]; // TODO : update
  onClickLocation?: () => void;
  zoomThreshold: number;
  additionalLayers?: IAdditionalLayer[];
}

const MapboxMap = (props: MapboxMapProps) => {
  const { mapId, layers, onClickLocation, zoomThreshold, additionalLayers } =
    props;

  const {
    selectedSector,
    visibleAdditionalLayers,
    currentTier,
    selectedFeatures,
    uploadLayers,
    visibleUploadLayers,
    isDrawing,
    isExporting,
    activeFilters,
  } = useContext(MapStateContext);
  const mapStateDispatch = useContext(MapStateDispatch);
  const dictionary = useContext(DictionaryContext);
  const mapContext = useContext(MapContext);
  const { activeEntity } = useContext(EntityContext)

  const mapRef = useRef<MapRef>(null);
  const drawControlRef = useRef<MapboxDraw>(null);
  const { [mapId]: map } = useMap();

  useEffect(() => {
    mapStateDispatch({
      type: "SET_DRAW_CONTROL",
      drawControl: drawControlRef.current,
    });
  }, [drawControlRef.current]);

  const [selectedTile, setSelectedTile] = useState<any | undefined>(undefined);

  // Set map context
  useEffect(() => {
    mapContext.setMap(mapRef.current?.getMap());
  }, [mapRef.current]);

  // Resize map on window/parent resize
  useEffect(() => {
    try {
      const resizer = new ResizeObserver(() =>
        mapRef.current?.getMap().resize()
      );
      resizer.observe(mapRef.current?.getMap().getContainer() as HTMLElement);
    } catch (e) {
      console.log(e);
    }
  }, [map]);

  const onSelectFeature = (
    feature: mapboxgl.MapboxGeoJSONFeature,
    type: "layer" | "additional",
    center: LngLat
  ) => {
    if (feature?.layer) {
      map?.setFeatureState(
        {
          source: feature?.layer?.source as string,
          sourceLayer: feature?.layer?.["source-layer"],
          id: feature?.id,
        },
        { clicked: true }
      );
      setSelectedTile(feature);
      mapStateDispatch({
        type: "SELECT_FEATURES",
        features: feature?.properties,
        latLng: { lng: center.lng, lat: center.lat },
        featuresType: type,
        featuresSourceLayer: feature?.layer?.["source-layer"],
      });
    }
  };

  const onClick = (e: mapboxgl.MapLayerMouseEvent) => {
    if (isDrawing) return;

    const map = mapRef.current?.getMap();
    setLngLat(e.lngLat);

    // Center map on click
    map?.easeTo({
      center: e.lngLat,
    });

    // Unselect previous features
    if (selectedFeatures)
      mapStateDispatch({ type: "SELECT_FEATURES", features: undefined });

    // Unselect previous tile
    if (selectedTile) {
      map?.setFeatureState(
        {
          source: selectedTile?.layer?.source as string,
          sourceLayer: selectedTile?.layer?.["source-layer"],
          id: selectedTile?.id,
        },
        { clicked: false, lngLat: e.lngLat }
      );
    }

    if (e.features) {
      track(window, EXPLORE_TARGET_TILE_CLICK);

      // Take only first occurence of each layer
      const features = e.features.filter(
        (feature, index, self) =>
          index === self.findIndex((t) => t.layer.id === feature.layer.id)
      );

      // Trigger popup when multiple features are selected, else select feature
      if (features.length > 1) {
        // Take only first occurence of each layer
        const features = e.features.filter(
          (feature, index, self) =>
            index === self.findIndex((t) => t.layer.id === feature.layer.id)
        );
        setIsOpenFeatureSelection(true);
        setFeatures(features);
        return;
      } else if (features.length === 1) {
        const l = layers.find(
          (layer) => layer.tileset === e?.features?.[0].layer.id
        );
        const al = additionalLayers?.find(
          (layer) => layer.id === e?.features?.[0].layer.id
        );
        onSelectFeature(
          e.features?.[0],
          l ? "layer" : al ? "additional" : "layer",
          e.lngLat
        );
      }
    }
  };

  const onUnselectTile = () => {
    if (selectedTile)
      map?.setFeatureState(
        {
          source: selectedTile?.layer?.source as string,
          sourceLayer: selectedTile?.layer?.["source-layer"],
          id: selectedTile?.id,
        },
        { clicked: false }
      );
    setSelectedTile(undefined);
    mapStateDispatch({ type: "SELECT_FEATURES", features: undefined });
  };

  useEffect(() => {
    if (!selectedFeatures) {
      onUnselectTile();
    }
  }, [selectedFeatures]);

  /*
   * Map event handlers
   */
  const onZoomEnd = (e: ViewStateChangeEvent) => {
    // Set current tier for data display
    if (e.viewState.zoom >= zoomThreshold && currentTier !== 3) {
      mapStateDispatch({ type: "SET_CURRENT_TIER", tier: 3 });
      onUnselectTile();
    }

    if (e.viewState.zoom < zoomThreshold && currentTier !== 2) {
      mapStateDispatch({ type: "SET_CURRENT_TIER", tier: 2 });
      setSelectedTile(undefined);
      onUnselectTile();
    }

    if (e.viewState.zoom >= zoomThreshold) {
      mapRef.current?.easeTo({
        pitch: 45,
      });
    } else if (e.viewState.pitch !== 0) {
      mapRef.current?.easeTo({
        pitch: 0,
      });
    }
  };

  /**
   * Handle multiple layers
   */
  const [lngLat, setLngLat] = useState<LngLat | undefined>(undefined);
  const [features, setFeatures] = useState<any[]>([]);
  const [isOpenFeatureSelection, setIsOpenFeatureSelection] = useState(false);
  const onSelectFeatureLayer = (
    feature: mapboxgl.MapboxGeoJSONFeature,
    type: "layer" | "additional"
  ) => {
    onSelectFeature(feature, type, lngLat as LngLat);
    setIsOpenFeatureSelection(false);
  };

  return (
    <Map
      ref={mapRef}
      id={props.mapId}
      reuseMaps
      minZoom={isExporting ? 14 : 8}
      // TODO : update with config
      initialViewState={{
        longitude: activeEntity.centroid.lon,
        latitude: activeEntity.centroid.lat,
        zoom: 11,
      }}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      attributionControl={false}
      onLoad={(e) => {
        // Change locale
        const labels = [
          "country-label",
          "state-label",
          "settlement-label",
          "settlement-subdivision-label",
          "airport-label",
          "poi-label",
          "water-point-label",
          "water-line-label",
          "natural-point-label",
          "natural-line-label",
          "waterway-label",
          "road-label",
        ];

        labels.forEach((label) => {
          e.target.setLayoutProperty(label, "text-field", ["get", "name_fr"]);
        });
      }}
      locale={{
        "AttributionControl.ToggleAttribution": "Afficher attribution",
        "AttributionControl.MapFeedback": "Map feedback",
        "FullscreenControl.Enter": "Plein écran",
        "FullscreenControl.Exit": "Quitter le plein écran",
        "GeolocateControl.FindMyLocation": "Afficher ma position",
        "GeolocateControl.LocationNotAvailable": "Position non disponible",
        "LogoControl.Title": "Logo Mapbox",
        "Map.Title": "Carte",
        "NavigationControl.ResetBearing": "Réinitialiser l'orientation",
        "NavigationControl.ZoomIn": "Zoom avant",
        "NavigationControl.ZoomOut": "Zoom arrière",
        "ScrollZoomBlocker.CtrlMessage":
          "Utilisez Ctrl + scroll pour zoomer sur la carte",
        "ScrollZoomBlocker.CmdMessage":
          "Utilisez ⌘ + scroll pour zoomer sur la carte",
        "TouchPanBlocker.Message":
          "Utilisez deux doigts pour déplacer la carte",
      }}
      onZoomEnd={onZoomEnd}
      onClick={(e) => {
        setIsOpenFeatureSelection(false);
        onClick(e);
        onClickLocation?.();
      }}
      interactiveLayerIds={layers
        .map((layer) => layer.tileset)
        .concat(additionalLayers?.map((layer) => layer.id) || [])
        .concat(uploadLayers?.map((layer) => layer.id) || [])}
      // onRender={(event) => event.target.resize()}
    >
      {layers.map((layer, index) => {
        return (
          selectedSector === layer.emittingSector && (
            <>
              <LayerV2
                key={index}
                layer={layer}
                isVisible={layer.isVisible}
                zoomThreshold={zoomThreshold}
              />
            </>
          )
        );
      })}
      {additionalLayers &&
        additionalLayers.map((layer: IAdditionalLayer) => {
          return (
            <Source
              id={`source-${layer.id}`}
              type="vector"
              url={`mapbox://nexqt-ds.${layer.tileset}`}
            >
              <Layer
                {...propsFromStaticLayer(
                  layer,
                  !!visibleAdditionalLayers?.includes(layer.id) &&
                    (!layer.tier || layer.tier === currentTier)
                )}
              />
            </Source>
          );
        })}

      {uploadLayers &&
        uploadLayers.map((layer) => {
          if (layer.type === "WMS") {
            return (
              <Source
                id={`source-${layer.id}`}
                type="raster"
                tiles={[layer.id]}
                tileSize={256}
              >
                <Layer
                  {...propsFromWMSLayer(
                    layer,
                    !!visibleUploadLayers?.includes(layer.id)
                  )}
                />
              </Source>
            );
          }

          return (
            <Source id={`source-${layer.id}`} type="geojson" data={layer.data}>
              <Layer
                {...propsFromUploadLayer(
                  layer,
                  !!visibleUploadLayers?.includes(layer.id)
                )}
              />
              <Layer
                {...propsFromUploadLineLayer(
                  layer,
                  !!visibleUploadLayers?.includes(layer.id)
                )}
              />
            </Source>
          );
        })}
      <AttributionControl customAttribution="NEXQT" />
      <GeocoderControl
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN ?? ""}
        position="top-right"
        onResult={(e: any) => console.log(e)}
      />
      <GeolocateControl position="top-right" />
      <FullscreenControl position="top-right" />
      <NavigationControl position="top-right" />
      <DrawControl
        ref={drawControlRef}
        position="top-right"
        controls={{
          polygon: false,
          trash: false,
        }}
        onModeChange={() => {}}
        onDrawCreate={(e) =>
          mapStateDispatch({
            type: "SET_DRAW_FEATURES",
            drawFeatures: e.features,
          })
        }
      />
      {lngLat?.lng && lngLat?.lat && !!isOpenFeatureSelection && (
        <Popup
          longitude={lngLat?.lng}
          latitude={lngLat?.lat}
          closeButton={false}
          closeOnClick={false}
          onClose={onUnselectTile}
        >
          <Space
            direction="vertical"
            className="flex flex-col items-center w-[200px]"
          >
            <Typography.Text>Sélectionnez une couche :</Typography.Text>
            {features.map((feature, index) => {
              const l = layers.find(
                (layer) => layer.tileset === feature.layer.id
              );
              const al = additionalLayers?.find(
                (layer) => layer.id === feature.layer.id
              );
              const ul = uploadLayers?.find(
                (layer) => layer.id === feature.layer.id
              );

              if (l) {
                return (
                  <div key={index}>
                    <Button
                      type="default"
                      style={{ width: 180 }}
                      onClick={() => onSelectFeatureLayer(feature, "layer")}
                    >
                      {l.emittingSector === "all"
                        ? "Tous secteurs"
                        : readFromCommonDictionary(
                            dictionary,
                            l.emittingSector
                          )}
                    </Button>
                  </div>
                );
              } else if (al) {
                return (
                  <div key={index}>
                    <Button
                      type="default"
                      style={{ width: 180, textOverflow: "ellipsis" }}
                      onClick={() =>
                        onSelectFeatureLayer(feature, "additional")
                      }
                    >
                      <Typography.Text ellipsis>{al.name}</Typography.Text>
                    </Button>
                  </div>
                );
              } else if (ul) {
                return (
                  <div key={index}>
                    <Button
                      type="default"
                      style={{ width: 180, textOverflow: "ellipsis" }}
                      onClick={() =>
                        onSelectFeatureLayer(feature, "additional")
                      }
                    >
                      {ul.name}
                    </Button>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </Space>
        </Popup>
      )}
    </Map>
  );
};

export default MapboxMap;
