import React, { useEffect } from "react";
import mainLoader from "../../assets/animations/mainLoader.json"; // Remplacez par le chemin de votre fichier JSON
import lottie from "lottie-web";
import { LayoutContext } from "../../contexts/LayoutContext";
import { colors } from "../../assets/styles/colors";
import { Space, Typography } from "antd";
import { boxShadow } from "../mapbox/constants";

const MainLoader: React.FC<{ content: string }> = ({ content }) => {
  const { isDarkMode } = React.useContext(LayoutContext);
  useEffect(() => {
    const container = document.getElementById("loader");
    const anim = lottie.loadAnimation({
      container: container as any,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: mainLoader,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Space
        direction="vertical"
        size="large"
        style={{
          backgroundColor: isDarkMode ? colors.darkMode : colors.white,
          boxShadow: boxShadow,
          borderRadius: 12,
          width: 600,
          height: 430,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div id="loader" style={{ width: 412, height: 275 }} />
        <Typography.Text strong>{content}</Typography.Text>
      </Space>
    </div>
  );
};

export default MainLoader;
