import React, { useContext, useEffect, useState } from 'react';
import { IFilterConfiguration } from '../../../../../../models/exploreV2/filters';
import { getRelevantFilters } from './utils';
import { MapStateContext, MapStateDispatch } from '../../../../contexts/mapStateContext';
import { Button, Divider, Dropdown, Space, Tag, Typography } from 'antd';
import DropdownRender from './DropdownRender';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { colors } from '../../../../../../assets/styles/colors';
import ExploreFilter from './ExploreFilter';
import LocationIcon from '../../../../../../assets/icons/LocationIcon';

export type IFilter = IFilterConfiguration & { isSelected: boolean; };

interface ExploreFiltersProps {
  filters: IFilterConfiguration[];
}

const ExploreFilters: React.FC<ExploreFiltersProps> = (props) => {
  const { filters: allFilters } = props;
  const { selectedSector, selectedSubsector, analysisFilters } = useContext(MapStateContext);
  const dispatch = useContext(MapStateDispatch);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const relevantFilters = getRelevantFilters(allFilters, selectedSector, selectedSubsector);

  const initialFilters = relevantFilters.map((filter) => {
    return ({
      ...filter,
      isSelected: false,
    })
  });

  const [filtersSector, setFiltersSector] = useState("");
  const [filtersSubsector, setFiltersSubsector] = useState("");
  const [filters, setFilters] = useState<IFilter[]>(initialFilters);

  // Used to force component remount
  const [filtersKey, setFiltersKey] = useState<number>(0);

  useEffect(() => {
    // If analysis filters change, update filters
    if (analysisFilters) {
      setFiltersKey((k) => k + 1);
      const newFilters = relevantFilters.map((filter) => {
        const analysisFilter = analysisFilters.find((f) => f.name === filter.name);
        if (analysisFilter) {
          return {
            ...analysisFilter,
            isSelected: true,
          }
        } else {
          return {
            ...filter,
            isSelected: false,
          }
        }
      });
      setFilters(newFilters);
      setFiltersSector(selectedSector);
      setFiltersSubsector(selectedSubsector);
      dispatch({ type: 'RESET_ANALYSIS', filters: newFilters.filter(f => f.isSelected) });
      return;
    };

    // If sector changes, reset filters
    if (selectedSector !== filtersSector) {
      console.log('Sector changed')
      const newFilters = relevantFilters.map((filter) => {
        return {
          ...filter,
          isSelected: false,
        }
      });
      setFilters(newFilters);
      setFiltersSector(selectedSector);
      setFiltersSubsector(selectedSubsector);
      return;
    };

    // If subsector changes, update filters
    if (selectedSubsector !== filtersSubsector) {
      const newFilters = relevantFilters.filter((f) => {
        if (f.subsectors && !f.subsectors.includes(selectedSubsector)) return false;
        return true;
      }).map((filter) => {
        const existingFilter = filters.find((f) => f.name === filter.name);
        if (existingFilter) {
          return existingFilter;
        } else {
          return {
            ...filter,
            isSelected: false,
          }
        };
      });
      setFilters(newFilters);
      setFiltersSector(selectedSector);
      setFiltersSubsector(selectedSubsector);
      return;
    };
  }, [allFilters, selectedSector, selectedSubsector, analysisFilters])

  // // Trigger when input filters change, should happen only when conf changes
  // useEffect(() => {
  //   console.log('Calling All filters change')
  //   setFilters(relevantFilters.map((filter) => {
  //     return {
  //       ...filter,
  //       value: undefined,
  //       isSelected: false,
  //     }
  //   }));
  // }, [allFilters]);

  // // When selected sector changes, reset filters
  // useEffect(() => {
  //   console.log('Calling Selected sector change')
  //   setFilters(relevantFilters.map((filter) => {
  //     return {
  //       ...filter,
  //       value: undefined,
  //       isSelected: false,
  //     }
  //   }));
  // }, [allFilters, selectedSector]);

  // // When selected subsector changes, update filters
  // useEffect(() => {
  //   console.log('Calling Selected subsector change')
  //   const newFilers = filters.filter((f) => {
  //     if (f.subsectors && !f.subsectors.includes(selectedSubsector)) return false;
  //     return true;
  //   });
  //   setFilters(newFilers);
  // }, [allFilters, selectedSubsector]);

  // // Triggered when analysis is applied
  // useEffect(() => {
  //   console.log('Calling Analysis filters change')
  //   if (analysisFilters) {
  //     setFiltersKey((k) => k + 1);
  //     const relevantFilters = getRelevantFilters(allFilters, selectedSector, selectedSubsector);
  //     setFilters(relevantFilters.map((filter) => {
  //       const analysisFilter = analysisFilters.find((f) => f.name === filter.name);
  //       if (analysisFilter) {
  //         return {
  //           ...analysisFilter,
  //           isSelected: true,
  //         }
  //       } else {
  //         return {
  //           ...filter,
  //           isSelected: false,
  //         }
  //       }
  //     }));
  //     dispatch({ type: 'RESET_ANALYSIS' });
  //   }
  // }, [allFilters, analysisFilters]);

  // // Special logic when analysis is applied
  // useEffect(() => {
  //   if (analysisFilters) {
  //     const relevantFilters = getRelevantFilters(allFilters, selectedSector, selectedSubsector);
  //     setFilters(relevantFilters.map((filter) => {
  //       const analysisFilter = analysisFilters.find((f) => f.name === filter.name);
  //       if (analysisFilter) {
  //         return {
  //           ...analysisFilter,
  //           isSelected: true,
  //         }
  //       } else {
  //         return {
  //           ...filter,
  //           isSelected: false,
  //         }
  //       }
  //     }));
  //     dispatch({ type: 'RESET_ANALYSIS' })
  //   }
  // }, [analysisFilters, selectedSector, selectedSubsector]);

  const onSelectFilter = (f: IFilterConfiguration) => {
    setFilters(filters.map((filter) => {
      if (filter.name === f.name) {
        return {
          ...filter,
          isSelected: !filter.isSelected,
          value: undefined,
        }
      }
      return filter;
    }));
  }

  const onUnselectFilter = (f: IFilterConfiguration) => {
    setFilters(filters.map((filter) => {
      if (filter.name === f.name) {
        return {
          ...filter,
          isSelected: false,
          value: undefined,
        }
      }
      return filter;
    }));
  };

  // const onUpdateFilterValue = (f: IFilter) => {
  //   const newFilters = filters.map(filter => {
  //     if (filter.name === f.name) {
  //       return {
  //         ...filter,
  //         value: f.value,
  //       }
  //     }
  //     return filter;
  //   });
  //   setFilters(newFilters);
  // }

  return (
    <>
      <div className="w-full flex flex-row justify-between items-center">
        <Typography.Text style={{ fontStyle: "italic", color: "#A4A4A4" }}>
          Ajouter un filtre
        </Typography.Text>
        <Dropdown
          open={isDropdownOpen}
          onOpenChange={(open) => setIsDropdownOpen(open)}
          menu={{ items: [] }}
          disabled={filters.length === 0}
          trigger={["click"]}
          dropdownRender={(props) => {
            return <DropdownRender
              filters={filters}
              onSelectFilter={onSelectFilter}
              onClose={() => setIsDropdownOpen(false)}
            />
          }
          }
        >
          <Button type="text" icon={<PlusOutlined />}></Button>
        </Dropdown>
      </div>
      <Space
        style={{
          // margin: 10,
          backgroundColor: colors.white,
          // padding: 10,
          borderRadius: 10,
          width: "100%",
          // display: 'flex',
          // flexDirection: 'column',
          // justifyContent: 'center'
        }}
        direction="vertical"
      >
        {filters.filter(f => !!f.isSelected).map((filter, idx) => (
          // We need to set the key so that filter is re-created when changes
          <div key={`${filter.name}-${filtersKey}`} className='w-full flex flex-col justify-center'>
            <div>
              {(filter.tier === 2 || !filter.tier) && <Tag color="cyan">⬡ Quartier</Tag>}
              {(filter.tier === 3 || !filter.tier) && <Tag color="blue"><LocationIcon fill="blue" /> Bâtiment / Rue</Tag>}
            </div>
            <Space>
              <Typography.Text>{filter.name}</Typography.Text>
              <DeleteOutlined
                style={{ color: colors.failure }}
                onClick={() => onUnselectFilter(filter)}
              />
            </Space>
            <div className='w-full'>
              <ExploreFilter config={filter} />
            </div>
            {idx < filters.filter(f => !!f.isSelected)?.length - 1 && <div className='w-full h-[0.5px] bg-gray-400 my-5' />}
            {/* {idx < filters?.length - 1 && <Divider style={{ marginTop: 10 }} />} */}
          </div>
        ))}
      </Space>
    </>
  );
};

export default ExploreFilters;