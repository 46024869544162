function getPieChartDataFromFeatures(
  features: Record<string, string | number> | undefined,
  key: string,
  sector: string,
  subsector: string
): { label: string, value: number, color: string }[] {

  const prefix = (() => {
    if (subsector === 'all') { return sector; }
    return subsector;
  })();

  const substring = `${prefix}_${key}`;
  const keys = Object.keys(features ?? {}).filter(k => k.includes(substring));

  const usages = keys.map(k => {
    const index = k.indexOf(substring);
    const usage = k.slice(index + substring.length + 1);

    return usage;
  }).filter(e => e !== '').filter(e => e !== 'real');

  const total = features?.[`${prefix}_${key}`] as number;

  return usages.map((usage, index) => {
    const value = features?.[`${prefix}_${key}_${usage}`] as number;
    return {
      label: usage,
      value: Math.round((value / total) * 100),
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
    }
  });
}

function getAllSectorsPieChartDataFromFeatures(
  features: Record<string, string | number> | undefined,
  key: string,
): { label: string, value: number, color: string }[] {
  const keys = Object.keys(features ?? {}).filter(k => k.includes(key));

  const usages = keys.map(k => {
    const index = k.indexOf(key);
    const usage = k.slice(index + key.length + 1);

    return usage;
  }).filter(e => e !== '');

  const total = features?.[key] as number;

  return usages.map((usage, index) => {
    const value = features?.[`${key}_${usage}`] as number;
    return {
      label: usage,
      value: Math.round((value / total) * 100),
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
    }
  });
}

function getTotalFromFeatures(
  features: Record<string, string | number> | undefined,
  field: string,
  sector: string,
  subsector: string): number | undefined {
  if (sector === 'all') {
    return features?.[field] as number | undefined;
  }

  const prefix = (() => {
    if (subsector === 'all') { return sector; }
    return subsector;
  })();

  return features?.[`${prefix}_${field}`] as number | undefined;
}

function getAvailableAnalysisFromFeatures(
  features: Record<string, string | number> | undefined,
): string[] {
  const res = new Set<string>();

  Object.keys(features ?? {}).forEach(k => {
    if (k.includes('ghg_emissions_tco2eq')) {
      res.add('ghg_emissions_tco2eq');
    }

    if (k.includes('energy_consumption_mwh')) {
      res.add('energy_consumption_mwh');
    }
  });
  
  return Array.from(res);
}

export {
  getPieChartDataFromFeatures,
  getTotalFromFeatures,
  getAvailableAnalysisFromFeatures,
  getAllSectorsPieChartDataFromFeatures
};