import { Empty, Typography } from 'antd';
import React from 'react';

export const ErrorFallback: React.FC<{content: string}> = ({content}) => {
    return (
        <div style={{width:"100%"}}>
            <Empty description={
                <Typography.Text strong>{content}</Typography.Text>
            }/>
        </div>
    )
}

export default ErrorFallback;