import * as React from "react";
import { Divider, Image, Typography } from "antd";
import { PerimeterContext } from "../../../../../contexts/PerimeterContext";
import WARNING from "../../../../../assets/icons/warning.png";

const GREEN = "#00A947";

interface GeneralInfoProps {
  features: Record<string, string | number> | undefined;
  tier: number;
  sector: string;
}

/**
 * General info displayed on top of ID section
 * It uses features from layer to display information
 * See specs in notion
 * - If tier3 : displays address
 * - If tier3 and sector is roadtransport : displays road name
 * - If present : displays city code and discrtict name
 * @param props features from layer, current tier and current sector
 * @returns header component for ID section
 */
const GeneralInfo: React.FC<GeneralInfoProps> = (props) => {
  const { features, tier, sector } = props;
  const { cityDict } = React.useContext(PerimeterContext);

  return (
    <>
      <div className="px-4 py-2 my-2">
        {tier === 3 && features?.adress && (
          <Typography.Title level={4} className="p-2" style={{ color: GREEN }}>
            {features["adress"]}
          </Typography.Title>
        )}
        {sector === "roadtransport" && features?.road_name && (
          <Typography.Title level={4} className="p-2" style={{ color: GREEN }}>
            {features["road_name"]}
          </Typography.Title>
        )}
        {features?.city_code && (
          <p>
            Nom commune :{" "}
            <b>{cityDict[features.city_code].name ?? features.city_code}</b>
          </p>
        )}
        {/* If tier 3, display IRIS code */}
        {tier === 3 && features?.iris_code && (
          <p>
            Nom IRIS :{" "}
            <b>
              {cityDict[features.city_code]?.irisCodes[features.iris_code]
                ?.name ?? features.iris_code}
            </b>
          </p>
        )}
      </div>
      <Divider className="m-0" />
    </>
  );
};

export default GeneralInfo;
