import React from "react";
import { screenWidth } from "../assets/styles/commons";
import { EntityContext } from "./EntityContext";
import { colorPalette } from "../assets/styles/colors";

export const LayoutContext = React.createContext<{
  isDarkMode: boolean;
  setIsDarkMode: (val: boolean) => void;
  isCollapsed: boolean;
  setIsCollapsed: (val: boolean) => void;
  currentScreenWidth: number;
  setCurrentScreenWidth: (val: number) => void;
  colorMapping: any;
  indexMapping: any;
}>({} as any);

const ColorProvider = ({
  children,
  isDarkMode,
  setIsDarkMode,
}: {
  children: React.ReactElement;
  isDarkMode: boolean;
  setIsDarkMode: (val: boolean) => void;
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [currentScreenWidth, setCurrentScreenWidth] =
    React.useState(screenWidth);
  const { activeEntity } = React.useContext(EntityContext);

  const colorMapping: any = {};
  const indexMapping: any = {};

  if (activeEntity.emittingSectors) {
    for (const emittingSector of activeEntity.emittingSectors) {
      colorMapping[emittingSector.sector] =
        colorPalette[emittingSector.colorId];
    }
  }

  if (activeEntity.energies) {
    for (const energy of activeEntity.energies) {
      colorMapping[energy.energy] = colorPalette[energy.colorId];
    }
  }

  // Adding DPE colors
  colorMapping["A"] = { gradient: ["#134E5E", "#71B280"] };
  colorMapping["B"] = { gradient: ["#1D976C", "#93F9B9"] };
  colorMapping["C"] = { gradient: ["#7FDD53", "#2FEA9B"] };
  colorMapping["D"] = { gradient: ["#FF8008", "#FFC837"] };
  colorMapping["E"] = { gradient: ["#FF4080", "#FFBF1A"] };
  colorMapping["G"] = { gradient: ["#7A2828", "#A73737"] };
  colorMapping["F"] = { gradient: ["#D93E30", "#FF5029"] };

  // Handle building periods
  colorMapping["avant 1948"] = { gradient: ["#134E5E", "#71B280"] };
  colorMapping["1948 a 1974"] = { gradient: ["#1D976C", "#93F9B9"] };
  colorMapping["1975 a 1990"] = { gradient: ["#7FDD53", "#2FEA9B"] };
  colorMapping["1991 a 2005"] = { gradient: ["#FF8008", "#FFC837"] };
  colorMapping["apres 2005"] = { gradient: ["#FF4080", "#FFBF1A"] };

  indexMapping["avant 1948"] = 0;
  indexMapping["1948 a 1974"] = 1;
  indexMapping["1975 a 1990"] = 2;
  indexMapping["1991 a 2005"] = 3;
  indexMapping["apres 2005"] = 4;
  
  // colorMapping["value"] = { gradient: ["#134E5E", "#71B280"] };

  // TODO see how to manage sub sectors color gradients
  colorMapping["cars"] = colorPalette[6];
  colorMapping["trucks"] = colorPalette[7];
  colorMapping["buses"] = colorPalette[9];

  React.useEffect(() => {
    const handleResize = () => {
      setCurrentScreenWidth(window.innerWidth);
    };

    // Ajoutez un écouteur d'événement de redimensionnement
    window.addEventListener("resize", handleResize);

    // Nettoyez l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <LayoutContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
        isCollapsed,
        setIsCollapsed,
        currentScreenWidth,
        setCurrentScreenWidth,
        colorMapping,
        indexMapping,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default ColorProvider;
