import { ConfigProvider, theme } from "antd";
import { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { hasAuthParams, useAuth } from "react-oidc-context";
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from "react-query";
import { colors } from "./assets/styles/colors";
import Loader from "./components/common/Loader";
import ErrorMessage from "./components/common/ErrorMessage";
import "./config/i18n";
import ColorProvider from "./contexts/LayoutContext";
import DictionaryProvider from "./contexts/DictionaryContext";
import EntityProvider from "./contexts/EntityContext";
import PerimeterProvider from "./contexts/PerimeterContext";
import { Router } from "./routes/Router";
import { APIError, Errors } from "./services/api/common";
import ReactGA from "react-ga4";
import frFR from "antd/es/locale/fr_FR";
import { LocalizationProvider } from "./contexts/LocalizationContext";

ReactGA.initialize("G-NHW7049QWT");

const reactQueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: true,
      retry: (failureCount: number, error: unknown) => {
        const nonRetryableErrors = [
          Errors.Unauthorized,
          Errors.NotFound,
          Errors.BadRequest,
        ];
        // Retry for 3 times only for retryable errors.
        return (
          failureCount < 3 &&
          !nonRetryableErrors.includes((error as APIError)?.error)
        );
      },
      retryDelay: (attemptIndex: number) =>
        Math.min(1000 * 2 ** attemptIndex, 5000),
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchInterval: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
};

const queryClient = new QueryClient(reactQueryConfig);

export const App = () => {
  console.log("Running on env: ", process.env.REACT_APP_ENV);

  const auth = useAuth();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
  ]);

  if (auth.activeNavigator || auth.isLoading) {
    return <Loader />;
  }

  if (!auth.isLoading && !auth.isAuthenticated) {
    return <ErrorMessage message="Authentification impossible" />;
  }

  return (
    <Suspense>
      <ErrorBoundary
        fallback={
          <ErrorMessage message="Erreur serveur, veuillez rafraîchir dans quelques minutes ou nous contacter à l'adresse hello@nexqt.com si l'erreur persiste" />
        }
      >
        <QueryClientProvider client={queryClient}>
          <EntityProvider>
            <LocalizationProvider>
              <ColorProvider
                isDarkMode={isDarkMode}
                setIsDarkMode={setIsDarkMode}
              >
                <ConfigProvider
                  theme={{
                    algorithm: isDarkMode
                      ? theme.darkAlgorithm
                      : theme.defaultAlgorithm,
                    token: {
                      colorPrimary: colors.secondary,
                      colorBgContainer: isDarkMode
                        ? colors.darkMode
                        : colors.lightMode,
                      fontFamily: "RobotoFlex, sans-serif",
                    },
                    components: {
                      Menu: {
                        itemSelectedBg: isDarkMode
                          ? colors.darkGrey
                          : colors.secondaryLight,
                        itemSelectedColor: isDarkMode
                          ? colors.lightMode
                          : colors.secondary,
                      },
                      Switch: {
                        trackHeight: 14,
                        trackMinWidth: 34,
                        handleSize: 20,
                        trackPadding: -3,
                      },
                      Select: {
                        colorBgBase: "red",
                        borderRadius: 14,
                        // colorBorder: "transparent",
                        boxShadow: "2px 3 17px rgba(124, 141, 181, 0.122)",
                      },
                      Input: {
                        colorPrimary: "#eb2f96",
                      },
                    },
                  }}
                  locale={frFR}
                >
                  <PerimeterProvider>
                    <DictionaryProvider>
                      <Router />
                    </DictionaryProvider>
                  </PerimeterProvider>
                </ConfigProvider>
              </ColorProvider>
            </LocalizationProvider>
          </EntityProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
};
