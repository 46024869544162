import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Routes as Switch } from "react-router-dom";
import Loader from "../components/common/Loader";
import { AppLayout } from "../layouts/AppLayout";
import { routes } from "./routes";
import ExploreTargetPage from "../pages/explore-target/ExploreTargetPageV2";
import { useAuth } from "react-oidc-context";
import { jwtDecode } from "jwt-decode";

const CarbonBudgetTrackerPage = React.lazy(
  () => import("../pages/dashboard/CarbonBudgetTrackerPage")
);
// const ExploreTargetPage = React.lazy(
//   () => import("../pages/explore-target/ExploreTargetPageV2")
// );
const WhatIfPage = React.lazy(() => import("../pages/WhatIfSimulationPage"));
const AdminPage = React.lazy(() => import("../pages/admin/AdminPage"));
const NoMatchPage = React.lazy(() => import("../pages/NoMatchPage"));

export const Router = () => {
  const auth = useAuth();

  // Adding metadata to umami sessions
  useEffect(() => {
    if ((window as any).umami) {
      const jwt: any = jwtDecode(auth?.user?.access_token ?? "");

      if (jwt) {
        (window as any).umami.identify({ name: jwt.name, email: jwt.email, user_id: jwt.sub });
      }
    }
  }, []);

  return (
    <Fragment>
      <React.Suspense
        fallback={
          <div style={{ padding: 200 }}>
            <Loader content="Chargement en cours" />
          </div>
        }
      >
        <BrowserRouter>
          <Switch>
            <Route path={routes.root} element={<AppLayout />}>
              <Route
                path={routes.dashboard}
                element={<CarbonBudgetTrackerPage />}
              />
              <Route path={routes.admin} element={<AdminPage />} />
              <Route path={routes.whatIf} element={<WhatIfPage />} />
              <Route
                path={routes.exploreAndTarget}
                element={<ExploreTargetPage />}
              />
            </Route>
            <Route path="*" element={<NoMatchPage />} />
          </Switch>
        </BrowserRouter>
      </React.Suspense>
    </Fragment>
  );
};
