import { Card, Space, Typography } from "antd";
import React from "react";
import EllipsisIconTitle from "./EllipsisIconTitle";
import { colors } from "../../assets/styles/colors";
import { LayoutContext } from "../../contexts/LayoutContext";
import { boxShadow } from "../mapbox/constants";

interface IDataContainer {
  children: React.ReactElement;
  extra?: React.ReactElement;
  title?: string | React.ReactElement;
  icon?: React.ReactElement;
  titlePosition?: "top" | "bottom";
  height?: number;
  width?: number;
  subtitle?: string;
  infoTitle?: string;
  infoContent?: string | React.ReactElement;
}

export const DataContainer: React.FC<IDataContainer> = ({
  children,
  extra,
  title,
  icon,
  titlePosition = "top",
  height = "100%",
  width = "100%",
  subtitle,
  infoTitle,
  infoContent,
}) => {
  const { isDarkMode } = React.useContext(LayoutContext);
  return (
    <Card
      style={{
        width: width,
        stroke: "none",
        boxShadow: boxShadow,
        height: height,
        paddingLeft: 25,
        paddingRight: 25,
        backgroundColor: isDarkMode ? colors.darkGrey : colors.lightMode,
      }}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
        display: "flex",
        position: "relative",
        height: "calc(100% - 80px)",
        padding: 0,
      }}
      headStyle={{
        padding: 0,
        margin: 0,
      }}
      hoverable
      title={
        titlePosition === "top" &&
        (typeof title === "string" ? (
          <div
            style={{
              lineHeight: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <EllipsisIconTitle
              title={title}
              icon={icon}
              infoTitle={infoTitle}
              infoContent={infoContent}
            />
            <Typography.Text
              style={{
                fontWeight: 300,
                fontSize: 11,
                color: isDarkMode ? colors.white : colors.dashboardGrey,
              }}
            >
              {subtitle}
            </Typography.Text>
          </div>
        ) : (
          title
        ))
      }
      extra={extra}
    >
      {children}
      {titlePosition === "bottom" && (
        <Space direction="horizontal" style={{ marginTop: 10 }}>
          {icon}
          <Typography.Text style={{ fontStyle: "italic" }}>
            {title}
          </Typography.Text>
        </Space>
      )}
    </Card>
  );
};
