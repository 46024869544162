import { Skeleton } from "antd"

const SkeletonLoader: React.FC = () => {
  return (
    <div className="p-2">
      <Skeleton className="p-2" paragraph={{ rows: 4 }} active />
    </div>
  )
}

export default SkeletonLoader;