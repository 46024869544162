import { Button, Radio, Typography, message } from "antd";
import ZoneExport from "./ZoneExport";
import TargetZone from "./TargetZone";
import SectorSelection from "../SectorSelection";
import { useContext, useEffect, useState } from "react";
import PeriodPicker from "./PeriodPicker";
import { ExplorationService } from "../../../../../services/api/exploration/api";
import { useAuth } from "react-oidc-context";
import { adaptUseQueryFetcher } from "../../../../../services/api/common";
import { EntityContext } from "../../../../../contexts/EntityContext";
import { PerimeterContext } from "../../../../../contexts/PerimeterContext";
import { exportToExcel } from "../../../../../services/utils";
import { ITemporalParameters } from "../../../../../services/dataApi/types";
import moment from "moment";
import { DataAPIService } from "../../../../../services/dataApi/api";
import { adaptUseQueryFetcher as adaptUseQueryFetcherDataAPI } from "../../../../../services/dataApi/common";
import { formatExportData } from "./dataFormatting";
import { DictionaryContext } from "../../../../../contexts/DictionaryContext";
import MapExport from "./MapExport";
import { MapStateContext, MapStateDispatch } from "../../../contexts/mapStateContext";
import { filtersToAPIFilters } from "../../statistics/utils";
import _, { set } from "lodash";
import { useUserExportsLeft } from "../../../../../services/api/user/hooks";
import { useQueryClient } from "react-query";
import { EXPLORE_TARGET_EXPORT } from "../../../../../services/analytics/constants";
import { track } from "../../../../../services/analytics/analytics";

export interface ExportContentProps {
  sectors: string[];
  subsectors: { emittingSector: string; subsector: string }[];
}

const ExportContent: React.FC<ExportContentProps> = (props) => {
  const { sectors, subsectors } = props;
  const auth = useAuth();
  const { relevantCityCodes } = useContext(PerimeterContext);
  const { activeEntity } = useContext(EntityContext);
  const dictionary = useContext(DictionaryContext);
  const { selectedSector, selectedSubsector, activeFilters, triggerExport, drawFeatures } = useContext(MapStateContext);
  const dispatch = useContext(MapStateDispatch);

  const queryClient = useQueryClient();
  const { data: exportsLeft } = useUserExportsLeft(auth.user?.profile?.sub as string);
  const service = new ExplorationService(auth.user?.access_token ?? "");

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFreq, setSelectedFreq] = useState<"month" | "year">("month");
  const [startDate, setStartDate] = useState<number | null>(null); // UNIX timestamp
  const [endDate, setEndDate] = useState<number | null>(null); // UNIX timestamp

  const onSelectFreq = (freq: "month" | "year") => {
    setSelectedFreq(freq);
    setStartDate(null);
    setEndDate(null);
  }

  const handleExportCityCode = async (cityCode: string) => {
    if (!startDate || !endDate) return;
    setIsLoading(true);

    const { numericFilters, otherFilters } = filtersToAPIFilters(activeFilters, selectedSubsector, 3);
    otherFilters["city_code"] = cityCode;

    track(window, EXPLORE_TARGET_EXPORT, { type: "city_code", filters: activeFilters, cityCode, sector: selectedSector, subsector: selectedSubsector, freq: selectedFreq, startDate, endDate });

    await adaptUseQueryFetcher<any[]>(service.fetchExportByCityCode(activeEntity.id, cityCode, selectedSector, selectedSubsector, startDate, endDate, selectedFreq, numericFilters, otherFilters, relevantCityCodes))
      .then((res) => {
        const data = res?.data ?? [];
        exportToExcel(formatExportData(data, dictionary), `export_carto_explorer_${cityCode}_${moment().format('YYYYMMDDHHmm')}.xlsx`);
        messageApi.open({
          type: "success",
          content: "Téléchargement réussi",
          style: {
            marginTop: "5vh",
          },
        });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Erreur lors du téléchargement",
          style: {
            marginTop: "5vh",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
        queryClient.invalidateQueries("exports-left");
      });
  };

  const handleExportZone = async (idShape: string | number, idShapeType: 'iris_code' | 'road_name' | 'link_id', idShapeLabel: string) => {
    if (!startDate || !endDate) return;
    setIsLoading(true);

    const { numericFilters, otherFilters } = filtersToAPIFilters(activeFilters, selectedSubsector, 3);
    switch (idShapeType) {
      case 'iris_code':
        otherFilters["iris_code"] = idShape.toString();
        break;
      case 'road_name':
        otherFilters["road_name"] = idShape as string;
        break;
      case 'link_id':
        otherFilters["link_id"] = idShape.toString();
        break
      default:
        break;
    }

    track(window, EXPLORE_TARGET_EXPORT, { type: "shape", filters: activeFilters, idShape, idShapeType, sector: selectedSector, subsector: selectedSubsector, freq: selectedFreq, startDate, endDate });

    await adaptUseQueryFetcher<any[]>(service.fetchExportByZone(activeEntity.id, selectedSector, selectedSubsector, startDate, endDate, selectedFreq, numericFilters, otherFilters, relevantCityCodes))
      .then((res) => {
        const data = res?.data ?? [];
        exportToExcel(data, `export_carto_explorer_${idShapeLabel}_${moment().format('YYYYMMDDHHmm')}.xlsx`);
        messageApi.open({
          type: "success",
          content: "Téléchargement réussi",
          style: {
            marginTop: "5vh",
          },
        });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Erreur lors du téléchargement",
          style: {
            marginTop: "5vh",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
        queryClient.invalidateQueries("exports-left");
      });
  };

  const handleExportPolygon = async (polygon: string) => {
    if (!startDate || !endDate) return;

    const { numericFilters, otherFilters } = filtersToAPIFilters(activeFilters, selectedSubsector, 3);

    track(window, EXPLORE_TARGET_EXPORT, { type: "polygon", filters: activeFilters, sector: selectedSector, subsector: selectedSubsector, freq: selectedFreq, startDate, endDate });

    await adaptUseQueryFetcher<any[]>(service.fetchExportByPolygon(activeEntity.id, selectedSector, selectedSubsector, startDate, endDate, selectedFreq, numericFilters, otherFilters, relevantCityCodes, polygon))
      .then((res) => {
        const data = res?.data ?? [];
        exportToExcel(data, `export_carto_explorer_selection_${moment().format('YYYYMMDDHHmm')}.xlsx`);
        messageApi.open({
          type: "success",
          content: "Téléchargement réussi",
          style: {
            marginTop: "5vh",
          },
        });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Erreur lors du téléchargement",
          style: {
            marginTop: "5vh",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
        queryClient.invalidateQueries("exports-left");
      });
  };

  const isExportDisabled = !selectedSector || !selectedFreq || !startDate || !endDate;

  return (
    <div className="w-full p-2">
      {/* <SectorSelection
        showAll={false}
        selectedSector={selectedSector}
        selectedSubsector={selectedSubsector}
        sectors={sectors}
        onSelectSector={onSelectSector}
        onSelectSubsector={onSelectSubsector}
        availableSubsectors={subsectors?.filter(
          (s) => s.emittingSector === selectedSector
        )}
      />
      <div className="h-4" /> */}
      {selectedSector === "all" ? (
        <div className="flex items-center justify-center">
          <Typography.Text italic style={{ alignSelf: 'center' }} className="text-base">Veuillez choisir un secteur pour exporter de la donnée</Typography.Text>
        </div>
      ) : (
        <>
          <div className="font-extrabold text-lg mb-2">Fréquence</div>
          <div className="flex justify-center">
            <Radio.Group value={selectedFreq} onChange={(e) => onSelectFreq(e.target.value)}>
              <Radio value="month">Mensuel</Radio>
              <Radio value="year">Annuel</Radio>
            </Radio.Group>
          </div>
          <div className="h-4" />

          <PeriodPicker
            freq={selectedFreq}
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
          <div className="h-8" />

          <ZoneExport
            exportsLeft={exportsLeft}
            isExportDisabled={isExportDisabled}
            handleExportCityCode={(cityCode: string) => handleExportCityCode(cityCode)}
            isLoading={isLoading}
          />
          <div className="h-8" />

          <MapExport isDisabled={isExportDisabled} isLoading={isLoading} handleExport={handleExportZone} exportsLeft={exportsLeft} />
          <div className="h-8" />


          <TargetZone isDisabled={isExportDisabled} isLoading={isLoading} handleExport={handleExportPolygon} exportsLeft={exportsLeft} />
        </>
      )
      }

      {contextHolder}
    </div>
  );
}

export default ExportContent;