const WasteIcon = ({ fill = "#9BA5B7" }: { fill?: string }) => {
  return (
    <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.52613 16.543H0.539724C0.242876 16.543 0 16.7858 0 17.0827C0 17.3795 0.242876 17.6224 0.539724 17.6224H0.863559L1.72712 24.5849C1.78109 25.1246 2.23986 25.5294 2.77958 25.5294H7.28628C7.826 25.5294 8.28477 25.1246 8.33874 24.5849L9.2023 17.6224H9.52613C9.82298 17.6224 10.0659 17.3795 10.0659 17.0827C10.0659 16.7589 9.82298 16.543 9.52613 16.543ZM6.74655 22.0482C6.82751 22.1291 6.82751 22.264 6.74655 22.318L6.31477 22.7498C6.23381 22.8308 6.09888 22.8308 6.04491 22.7498L5.01943 21.7243L4.02095 22.7498C3.93999 22.8308 3.80506 22.8308 3.75108 22.7498L3.3193 22.318C3.23834 22.2371 3.23834 22.1021 3.3193 22.0482L4.34478 21.0227L3.3193 19.9972C3.23834 19.9162 3.23834 19.7813 3.3193 19.7273L3.75108 19.2956C3.83204 19.2146 3.96697 19.2146 4.02095 19.2956L5.04642 20.321L6.0719 19.2956C6.15286 19.2146 6.28779 19.2146 6.34176 19.2956L6.77354 19.7273C6.8545 19.8083 6.8545 19.9432 6.77354 19.9972L5.74806 21.0227L6.74655 22.0482Z" fill={fill} />
      <path d="M5.50586 13.7089L5.72175 15.0852C5.74874 15.1662 5.82969 15.2471 5.91065 15.2201L7.28695 15.0043C7.36791 14.9773 7.44887 14.8963 7.42188 14.8153L7.20599 13.4391C7.179 13.3581 7.09805 13.2771 7.01709 13.3041L5.64079 13.52C5.55983 13.52 5.50586 13.601 5.50586 13.7089Z" fill={fill} />
      <path d="M3.56259 15.5442C3.61656 15.6251 3.72451 15.6251 3.80547 15.5442L4.80396 14.6536C4.88492 14.5997 4.88492 14.4917 4.80396 14.4108L3.91341 13.4123C3.85944 13.3313 3.7515 13.3313 3.67054 13.4123L2.67205 14.3028C2.59109 14.3568 2.59109 14.4647 2.67205 14.5457L3.56259 15.5442Z" fill={fill} />
      <path d="M3.588 12.3324L4.72142 13.088C4.80238 13.142 4.91032 13.115 4.96429 13.034L5.71991 11.9006C5.77388 11.8196 5.74689 11.7117 5.66593 11.6577L4.53251 10.9021C4.45156 10.8481 4.34361 10.8751 4.28964 10.9561L3.53402 12.0895C3.50704 12.1705 3.53402 12.2784 3.588 12.3324Z" fill={fill} />
      <path d="M15.7867 5.6671C17.3516 5.6671 18.6202 4.39848 18.6202 2.83355C18.6202 1.26862 17.3516 0 15.7867 0C14.2217 0 12.9531 1.26862 12.9531 2.83355C12.9531 4.39848 14.2217 5.6671 15.7867 5.6671Z" fill={fill} />
      <path d="M22.0483 13.3046C21.6705 11.6854 20.2133 6.36914 17.1908 6.36914H14.6002C13.4397 6.36914 12.3873 6.88188 11.6587 7.77242L10.4713 9.25666H8.06949C7.39483 9.25666 6.82812 9.82338 6.82812 10.498C6.82812 11.1727 7.39483 11.7394 8.06949 11.7394H10.4713C11.1999 11.7394 11.9015 11.4156 12.3603 10.8489L13.1159 9.90433V13.6014L12.1174 24.0451C12.0365 24.8547 12.6302 25.5563 13.4128 25.6373C13.4667 25.6373 13.5207 25.6373 13.5477 25.6373C14.2763 25.6373 14.924 25.0706 15.0049 24.315L15.8415 15.7064L16.6781 24.315C16.759 25.0706 17.3797 25.6373 18.1353 25.6373C18.1893 25.6373 18.2163 25.6373 18.2703 25.6373C19.0799 25.5563 19.6466 24.8547 19.5656 24.0451L18.5671 13.6014V10.6599C18.9989 11.6045 19.4307 12.8188 19.6736 13.8713C19.8085 14.438 20.3212 14.8428 20.8879 14.8428C20.9689 14.8428 21.0768 14.8428 21.1578 14.8158C21.7785 14.6539 22.1833 13.9793 22.0483 13.3046Z" fill={fill} />
    </svg>

  )
};

export default WasteIcon;