const mapboxDrawLayersIds = [
    'gl-draw-polygon-fill-inactive.cold',
    'gl-draw-polygon-fill-active.cold',
    'gl-draw-polygon-midpoint.cold',
    'gl-draw-polygon-stroke-inactive.cold',
    'gl-draw-polygon-stroke-active.cold',
    'gl-draw-line-inactive.cold',
    'gl-draw-line-active.cold',
    'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
    'gl-draw-polygon-and-line-vertex-inactive.cold',
    'gl-draw-point-point-stroke-inactive.cold',
    'gl-draw-point-inactive.cold',
    'gl-draw-point-stroke-active.cold',
    'gl-draw-point-active.cold',
    'gl-draw-polygon-fill-static.cold',
    'gl-draw-polygon-stroke-static.cold',
    'gl-draw-line-static.cold',
    'gl-draw-point-static.cold',
    'gl-draw-polygon-fill-inactive.hot',
    'gl-draw-polygon-fill-active.hot',
    'gl-draw-polygon-midpoint.hot',
    'gl-draw-polygon-stroke-inactive.hot',
    'gl-draw-polygon-stroke-active.hot',
    'gl-draw-line-inactive.hot',
    'gl-draw-line-active.hot',
    'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
    'gl-draw-polygon-and-line-vertex-inactive.hot',
    'gl-draw-point-point-stroke-inactive.hot',
    'gl-draw-point-inactive.hot',
    'gl-draw-point-stroke-active.hot',
    'gl-draw-point-active.hot',
    'gl-draw-polygon-fill-static.hot',
    'gl-draw-polygon-stroke-static.hot',
    'gl-draw-line-static.hot',
    'gl-draw-point-static.hot',
];

const boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.05)"

export { mapboxDrawLayersIds, boxShadow };