import { ReloadOutlined } from "@ant-design/icons";
import { Button, Space, Switch, Tag, Typography, Select } from "antd";
import React, { Suspense } from "react";
import { colors } from "../../assets/styles/colors";
import { selectorHeight, selectorWidth } from "../../assets/styles/commons";
import Loader from "./Loader";
import { PerimeterContext } from "../../contexts/PerimeterContext";
import { TCity } from "../../models/general/city";
import { TCityGroup, TGroupType } from "../../models/general/cityGroup";
import { useCities, useCityGroups } from "../../services/api/entity/hooks";
import { groupCodesForUser, sortByName } from "../../services/utils";
import { ReactComponent as RESET } from "../../assets/icons/reset.svg";
import { LayoutContext } from "../../contexts/LayoutContext";
import SelectDS from "./designSystem/SelectDS";
import { useAuth } from "react-oidc-context";
import { EntityContext } from "../../contexts/EntityContext";
import {
  defaultCitySelector,
  defaultGroupSelectors,
} from "../admin/components/examples";
import ResetIcon from "../../assets/icons/ResetIcon";
import { track } from "../../services/analytics/analytics";
import { CITY_SELECTION } from "../../services/analytics/constants";

const handleFilter = (input: any, option: any) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 1;
};

export interface IGroupSelector {
  type: TGroupType;
  parentType: TGroupType | "none";
  title: string;
  noDataContent: string;
}

export interface ICitySelector {
  type: "city";
  parentType: TGroupType;
  title: string;
  noDataContent: string;
}

interface IGroupSelectorProps {
  entityId: string;
  selector: IGroupSelector;
  parentCodes: string[];
  updateFilters: (t: TGroupType, values: string[]) => void;
  triggerReset: boolean;
  authorisedCodes: string[];
}

export type GroupFilter = Record<TGroupType | "none", string[]>;

const DropdownRender: React.FC<{
  menuContent: any;
  showSelectionButtons: boolean;
  onSelectAll: () => void;
  onReset: () => void;
}> = ({ menuContent, showSelectionButtons, onSelectAll, onReset }) => (
  <div>
    {showSelectionButtons && (
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Button
          type="link"
          style={{
            padding: 0,
          }}
          onClick={onSelectAll}
        >
          <Typography.Text style={{ color: colors.secondary }}>
            Tout sélectionner
          </Typography.Text>
        </Button>
        <Button
          icon={<ReloadOutlined />}
          type="text"
          color={colors.secondary}
          onClick={onReset}
        />
      </Space>
    )}
    {menuContent}
  </div>
);

const GroupSelector: React.FC<IGroupSelectorProps> = ({
  entityId,
  selector,
  parentCodes,
  updateFilters,
  triggerReset,
  authorisedCodes,
}) => {
  const { data } = useCityGroups(
    entityId,
    selector.type,
    parentCodes,
    authorisedCodes
  );
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([]);

  React.useEffect(() => {
    setSelectedGroups([]);
  }, [triggerReset]);

  const MultiSelectTag = ({ value }: any) => {
    if (selectedGroups.length === 1) {
      const groupName = data.find(
        (g: TCityGroup) => g.groupCode === value
      )?.name;
      return groupName ? (
        <Tag>
          {groupName.length > 20 ? groupName.slice(0, 17) + "..." : groupName}
        </Tag>
      ) : (
        ""
      );
    }
    return value === selectedGroups[0] ? (
      <Tag>{`${selectedGroups.length} ${
        selectedGroups.length === 1 ? "sélectionné" : "sélectionnés"
      }`}</Tag>
    ) : (
      (null as any)
    );
  };

  React.useEffect(() => {
    if (data && data.length === 1) {
      setSelectedGroups([data[0].groupCode]);
    }
  }, [data, triggerReset]);

  return (
    <SelectDS
      allowClear
      disabled={data?.length === 1}
      style={{
        width: selectorWidth,
        height: selectorHeight,
        boxShadow: "2px 3 17px rgba(124, 141, 181, 0.122)",
        borderRadius: 14,
      }}
      placeholder={selector?.title}
      filterOption={handleFilter}
      mode="multiple"
      tagRender={MultiSelectTag}
      value={selectedGroups}
      dropdownRender={(menuContent) => (
        <DropdownRender
          menuContent={menuContent}
          showSelectionButtons={data?.length > 0}
          onSelectAll={() => {
            const allGroups = data?.map((d: TCityGroup) => d.groupCode);
            setSelectedGroups(allGroups);
            updateFilters(selector.type, allGroups);
          }}
          onReset={() => {
            setSelectedGroups([]);
            updateFilters(selector.type, []);
          }}
        />
      )}
      onChange={(values) => {
        updateFilters(selector.type, values);
        setSelectedGroups(values);
      }}
      notFoundContent={
        <Typography.Text style={{ fontStyle: "italic", color: colors.grey }}>
          {selector.noDataContent}
        </Typography.Text>
      }
    >
      {sortByName(data).map((d: TCityGroup) => {
        return <Select.Option key={d.groupCode}>{d.name}</Select.Option>;
      })}
    </SelectDS>
  );
};

const CitySelector: React.FC<{
  entityId: string;
  selector: ICitySelector;
  groupCodes: string[];
  selectedCities: string[];
  setSelectedCities: (s: string[]) => void;
}> = ({
  entityId,
  selector,
  groupCodes,
  selectedCities,
  setSelectedCities,
}) => {
  const { data } = useCities(entityId, groupCodes);

  const MultiSelectTag = ({ value }: { value: string }) => {
    if (selectedCities.length === 1) {
      const city = data.find((c: TCity) => c.cityCode === selectedCities[0]);
      return <Tag>{city?.name}</Tag>;
    }

    const displayedCity = data.find(
      (c: TCity) => c.cityCode === selectedCities[0]
    );
    return displayedCity && value === selectedCities[0] ? (
      <Tag>{` ${
        displayedCity?.name.length > 16
          ? displayedCity?.name.slice(0, 13) + "..."
          : displayedCity?.name
      } et ${selectedCities.length - 1} ${
        selectedCities.length === 2 ? "autre" : "autres"
      }`}</Tag>
    ) : (
      (null as any)
    );
  };

  return (
    <SelectDS
      style={{
        width: selectorWidth,
        height: selectorHeight,
      }}
      placeholder={selector?.title}
      mode="multiple"
      tagRender={MultiSelectTag}
      value={selectedCities}
      filterOption={handleFilter}
      optionFilterProp="children"
      dropdownRender={(menuContent) => (
        <DropdownRender
          menuContent={menuContent}
          showSelectionButtons={groupCodes?.length > 0 && data?.length > 0}
          onSelectAll={() =>
            setSelectedCities(data.map((c: TCity) => c.cityCode))
          }
          onReset={() => setSelectedCities([])}
        />
      )}
      onChange={(values: string[]) => setSelectedCities(values)}
      notFoundContent={
        <Typography.Text style={{ fontStyle: "italic", color: colors.failure }}>
          Aucune ville disponible dans la sélection de groupe effectuée
        </Typography.Text>
      }
    >
      {groupCodes?.length > 0 &&
        sortByName(data).map((c: TCity) => (
          <Select.Option key={c.cityCode}>{c.name}</Select.Option>
        ))}
    </SelectDS>
  );
};

export const LocalisationSelector: React.FC<{}> = () => {
  const { activeEntity } = React.useContext(EntityContext);
  const { filters, setFilters, setCities, maxPerimeter } =
    React.useContext(PerimeterContext);
  const auth = useAuth();
  const [selectedCities, setSelectedCities] = React.useState<string[]>([]);
  const [triggerReset, setTriggerReset] = React.useState<boolean>(true);
  const { isDarkMode, setIsDarkMode } = React.useContext(LayoutContext);
  function updateParentFilters(type: TGroupType, values: string[]) {
    const newFilters = { ...filters };
    newFilters[type] = values;
    setFilters(newFilters);
  }

  const onClickValidate = () => {
    setCities(selectedCities);
    track(window, CITY_SELECTION, { cities: selectedCities });
  };

  return (
    <Space
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Space>
        {(activeEntity.groupSelectors ?? defaultGroupSelectors).map(
          (selector: IGroupSelector) => (
            <Suspense
              fallback={
                <Select
                  style={{ width: selectorWidth, height: selectorHeight }}
                  placeholder={selector?.title}
                  loading
                  notFoundContent={
                    <Loader content="Chargement des groupes de votre périmètre"></Loader>
                  }
                />
              }
            >
              <GroupSelector
                entityId={activeEntity.id}
                selector={selector}
                parentCodes={filters[selector.parentType]}
                updateFilters={updateParentFilters}
                triggerReset={triggerReset}
                authorisedCodes={groupCodesForUser(auth)}
              />
            </Suspense>
          )
        )}
        <Suspense
          fallback={
            <Select
              style={{ width: selectorWidth, height: selectorHeight }}
              placeholder={activeEntity.citySelector?.title}
              notFoundContent={
                <Loader content="Chargement des villes de votre périmètre"></Loader>
              }
              loading
            />
          }
        >
          <CitySelector
            entityId={activeEntity.id}
            selector={activeEntity.citySelector}
            groupCodes={
              filters[
                (activeEntity.citySelector ?? defaultCitySelector).parentType
              ] &&
              filters[
                (activeEntity.citySelector ?? defaultCitySelector).parentType
              ]?.length > 0
                ? filters[
                    (activeEntity.citySelector ?? defaultCitySelector)
                      .parentType
                  ]
                : maxPerimeter.groups
            }
            selectedCities={selectedCities}
            setSelectedCities={setSelectedCities}
          />
        </Suspense>
      </Space>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <Switch
            disabled
            value={!isDarkMode}
            onChange={(checked: boolean) => setIsDarkMode(!checked)}
          />
        </div>
        <Typography.Text>
          {isDarkMode ? "Dark mode" : "Light mode"}
        </Typography.Text>
      </div>
      <Space>
        <Button
          onClick={onClickValidate}
          type="primary"
          style={{
            fontSize: 15,
            borderColor: colors.secondary,
            width: 100,
            height: selectorHeight,
            borderRadius: 5,
          }}
        >
          Valider
        </Button>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              backgroundColor: "#ADE3C4",
              width: selectorHeight,
              height: selectorHeight,
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            icon={<ResetIcon fill="#00A947" />}
            onClick={() => {
              setSelectedCities([]);
              setCities([]);
              setFilters({} as GroupFilter);
              setTriggerReset(!triggerReset);
            }}
          ></Button>
        </div>
      </Space>
    </Space>
  );
};

export const LocalisationSelectorSuspense: React.FC = () => {
  return <Loader content="Chargement des données de votre périmètre" />;
};
