const RoadIcon = ({ fill = "#9BA5B7" }: { fill?: string }) => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 16.875L6.5 1.125L2 16.875ZM15.5 1.125L20 16.875L15.5 1.125ZM11 4.5V2.25V4.5ZM11 10.125V7.875V10.125ZM11 15.75V13.5V15.75Z" fill={fill} />
      <path d="M2 16.875L6.5 1.125M15.5 1.125L20 16.875M11 4.5V2.25M11 10.125V7.875M11 15.75V13.5" stroke={fill} stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
};

export default RoadIcon;