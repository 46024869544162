import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { colors } from "../../assets/styles/colors";
import { Button, Divider, Dropdown, Space, Typography } from "antd";

interface ILoaderProps {
  content?: string;
  inputStyle?: any;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loader: React.FC<ILoaderProps> = ({ content, inputStyle }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        ...inputStyle,
      }}
    >
      <Space
        direction="vertical"
        style={{ display: "flex", alignContent: "center" }}
      >
        <Spin
          indicator={antIcon}
          style={{ color: colors.secondary, width: "100%" }}
        />
        <Typography.Text style={{ color: colors.grey }}>
          {content}
        </Typography.Text>
      </Space>
    </div>
  );
};

export default Loader;
