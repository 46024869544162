import { TComponentType } from "../../../models/dashboard/common";
import {
  ISelectOption,
  TEmittingSector,
  TEnergy,
} from "../../../models/general/common";
import {
  ICitySelector,
  IGroupSelector,
} from "../../common/LocalisationSelector";

export const defaultGroupSelectors: IGroupSelector[] = [
  {
    type: "epci",
    parentType: "none",
    title: "Niveau 1",
    noDataContent: "Aucune choix de niveau 1 n'est disponible",
  },
  {
    type: "ept",
    parentType: "epci",
    title: "Niveau 2",
    noDataContent: "Aucun choix de niveau 2 n'est disponible",
  },
];

export const defaultCitySelector: ICitySelector = {
  type: "city",
  parentType: "ept",
  title: "Niveau 3",
  noDataContent: "Aucune choix de niveau 3 n'est disponible",
};

export const defaultEmittingSectors: { [key in TEmittingSector]: string } = {
  residential: `{
    "sectorId": "residential",
    "sector": "residential",
    "colorId":7
  }`,
  services: `{
    "sectorId": "services",
    "sector": "services",
    "colorId":9
  }`,
  roadtransport: `{
    "sectorId": "roadtransport",
    "sector": "roadtransport",
    "colorId":2
  }`,
  industry: `{
    "sectorId": "industry",
    "sector": "industry",
    "colorId":8
  }`,
  agriculture: `{
    "sectorId": "agriculture",
    "sector": "agriculture",
    "colorId":5
  }`,
  waste: `{
    "sectorId": "waste",
    "sector": "waste",
    "colorId":3
  }`,
  maritime: `{
    "sectorId": "maritime",
    "sector": "maritime",
    "colorId":6
  }`,
};

export const defaultEnergies: { [key in TEnergy]: string } = {
  electricity: `{
    "energyId": "electricity",
    "energy": "electricity",
    "colorId":5
  }`,
  naturalgas: `{
    "energyId": "naturalgas",
    "energy": "naturalgas",
    "colorId":2
  }`,
  fueloil: `{
    "energyId": "fueloil",
    "energy": "fueloil",
    "colorId":0
  }`,
  biomass: `{
    "energyId": "biomass",
    "energy": "biomass",
    "colorId":11
  }`,
  districtheating: `{
    "energyId": "districtheating",
    "energy": "districtheating",
    "colorId":10
  }`,
};

export const models: ISelectOption[] = [
  {
    key: "tree_map",
    value: "Graphe en aires",
  },
  {
    key: "temporal_graph",
    value: "Graphique temporel",
  },
  {
    key: "basic_indicator",
    value: "Indicteur basique",
  },
  {
    key: "indicator_list",
    value: "Liste d'indicateurs",
  },
  {
    key: "evolution_indicator",
    value: "Indicateur d'évolution",
  },
  {
    key: "bar_chart",
    value: "Histogramme",
  },
  {
    key: "geo_bar_chart",
    value: "Bar chart géographique",
  },
  {
    key: "sankey",
    value: "Sankey",
  },
  {
    key: "description",
    value: "Description",
  },
  {
    key: "table",
    value: "Tableau",
  },
];

export const examples: { [key in TComponentType]: string } = {
  tree_map: `{
        "name":"Tree map principal",
        "component_type": "tree_map",
        "unit": "kteqCO2",
        "sorter": 1,
        "decimals": 0,
        "section": "carbon_bilan",
        "title": "Bilan GES par secteur (ktCO2eq)",
        "dataSource": [
            {
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='residential' and usage='heating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
                "fill": "#D5E9E9",
                "key": "residential"
            },
            {
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='residential' and usage='waterHeating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
                "fill": "#D9E9E9",
                "key": "residential"
            },
            {
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='services' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
                "fill": "#ACBCFF",
                "key": "services"
            },
            {
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='industry' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
                "fill": "#F5F0BB",
                "key": "industry"
            },
            {
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='roadtransport' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
                "fill": "#FEA1A1",
                "key": "roadtransport"
            },
            {
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='othertransport' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
                "fill": "#8884d8",
                "key": "othertransport"
            },
            {
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='agriculture' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
                "fill": "#B3C890",
                "key": "agriculture"
            },
            {
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='waste' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
                "fill": "#7A3E3E",
                "key": "waste"
            }
        ],
        "size_24": 24
    }`,
  temporal_graph: ` {
        "name":"Jour de dépassement",
        "component_type": "temporal_graph",
        "unit": "tCO2",
        "sorter": 1,
        "decimals": 2,
        "section": "temporal_evolution",
        "title": "Cumul quotidien des émissions sur 12 mois VS objectif plan climat 2050",
        "dataSource": [
            {
                "sqlRequest": "with data as (select date, sum(value) as emission_tco2eq from daily_indicators where city_code in (%cityCodes%) and type = 'ghg_emissions_tco2eq' and date between date '2022-04-20' and date '2023-04-20' group by date) select date, sum(emission_tco2eq) over (order by date asc rows between unbounded preceding and current row) from data",
                "fill": "#ACBCFF",
                "type": "area",
                "stroke": "#ACBCFF",
                "key": "tous_secteurs",
                "objectiveRequest": "sum(value) as result from objectives where city_code in (%cityCodes%) and type = 'ghg_emissions_tco2eq' and date = date '2050-01-01'"
            }
        ],
        "size_24": 12,
        "labels": {
            "x": "",
            "y": "Emission (tCO2)"
        }
    }`,
  basic_indicator: ``,
  indicator_list: `{
        "component_type": "indicator_list",
        "name": "Liste d'indicateurs 1",
        "title": "Différence entre effet du climat et consommation réellement observée",
        "sorter": 2,
        "section": "inhabitants_impact",
        "size_24": 6,
        "dataSource": [
          {
            "title": "Part de l effet sobriété sur la consommation d énergie résidentiel, tertiaire et industrie",
            "sqlRequest": "(a.real/b.cc-1)*100 as result from ((select city_code, SUM(value) as real FROM yearly_indicators WHERE city_code in (%cityCodes%) and type = 'energy_consumption_mwh' and year between (%periodStart%) and (%periodEnd%) group by city_code) as a JOIN(select city_code, SUM(value) as cc FROM yearly_indicators WHERE city_code in (%cityCodes%) and type = 'energy_consumption_mwh_cc' and year between (%periodStart%) and (%periodEnd%) group by city_code) as b ON a.city_code = b.city_code)",
            "unit": "%"
          }
        ],
        "decimals": 2
      }
      `,
  evolution_indicator: `{
    "component_type": "map",
    "section": "attractive_map",
    "icon": {
      "name": "home",
      "size": 40,
      "color": "green"
    },
    "dataRendering": {
      "tooltipContent": "Veuillez souscrire aux fonctionnalités Premium pour accéder à toutes les cartes et outils d'analyse."
    },
    "sorter": 0,
    "dataSource": {
      "layers": [
        {
          "field": "ghg_emissions_tco2eq",
          "tileset": "t_270_sum_2022_tier2",
          "name": "Emissions totales (maille quartier)",
          "aggType": "sum",
          "isVisible": true,
          "filters": [],
          "category": "2D",
          "type": "Couches quartier (tier 2)",
          "sector": "total",
          "table": "public.t_270_sum_2022_tier2",
          "colors": [
            "green",
            "yellow",
            "orange",
            "red",
            "purple"
          ],
          "colorType": "quantile"
        }
      ],
      "mapId": "demo",
      "zoomThreshold": 18,
      "height": 10
    },
    "name": "Description carte interactive",
    "size_24": 24,
    "title": "Voici une carte attractive invisible"
  }
      `,
  bar_chart: `{
        "component_type": "bar_chart",
        "sorter": 2,
        "section": "inhabitants_impact",
        "size_24": 18,
        "name": "Hisotgramme",
        "stacked": false,
        "dataSource": [
          {
            "name": "Résidentiel",
            "bars": [
              {
                "key": "climat_reel",
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='residential' \n            and city_code in (%cityCodes%)\n            and type = 'energy_consumption_mwh'\n            and year between (%periodStart%) and (%periodEnd%)\n            "
              },
              {
                "key": "corrige_du_climat",
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='residential' \n            and city_code in (%cityCodes%)\n            and type = 'energy_consumption_mwh_cc'\n            and year between (%periodStart%) and (%periodEnd%)\n            "
              }
            ]
          },
          {
            "name": "Tertiaire",
            "bars": [
              {
                "key": "climat_reel",
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='services' \n          and city_code in (%cityCodes%)\n          and type = 'energy_consumption_mwh'\n          and year between (%periodStart%) and (%periodEnd%)\n          "
              },
              {
                "key": "corrige_du_climat",
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='services' \n          and city_code in (%cityCodes%)\n          and type = 'energy_consumption_mwh_cc'\n          and year between (%periodStart%) and (%periodEnd%)\n          "
              }
            ]
          },
          {
            "name": "Industrie",
            "bars": [
              {
                "key": "climat_reel",
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='industry' \n          and city_code in (%cityCodes%)\n          and type = 'energy_consumption_mwh'\n          and year between (%periodStart%) and (%periodEnd%)\n          "
              },
              {
                "key": "corrige_du_climat",
                "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector='industry' \n          and city_code in (%cityCodes%)\n          and type = 'energy_consumption_mwh_cc'\n          and year between (%periodStart%) and (%periodEnd%)\n          "
              }
            ]
          }
        ],
        "colorPalette": {
          "climat_reel": "#ACBCFF",
          "corrige_du_climat": "#8884d8"
        },
        "title": "Consommation climat réel VS climat constant - Visualisation de l'effet sobriété",
        "decimals": 0,
        "unit": "GWh"
      }
      `,
  geo_bar_chart: `{
    "unit": "GWh",
    "dataRendering": {
      "stacked": false
    },
    "name": "Effet climat et sobriete",
    "component_type": "bar_chart",
    "section": "energetic_bilan",
    "sorter": 2,
    "decimals": 0,
    "dataSource": {
      "value": "nb_dwellings",
      "normalizeValue": true,
      "colEnergyLabel": "energylabelclassification",
      "table": "t_270_residential_2023_tier3"
    },
    "size_24": 8,
    "title": "Répartition des DPE - Tertiaire"
  }`,
  sankey: `{
        "component_type": "sankey",
        "section": "energetic_bilan",
        "entityId": "abb284c6-7e0d-487d-b358-663875bf8bc0",
        "sorter": 2,
        "dataSource": [
          {
            "links": [
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 7
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 9
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 10
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 7
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 9
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 10
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 7
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 9
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 10
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 3,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 3,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 4,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 4,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 7
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 9
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 10
              }
            ],
            "nodes": [
              {
                "name": "residential"
              },
              {
                "name": "services"
              },
              {
                "name": "industry"
              },
              {
                "name": "roadtransport"
              },
              {
                "name": "othertransport"
              },
              {
                "name": "agriculture"
              },
              {
                "name": "electricity"
              },
              {
                "name": "naturalgas"
              },
              {
                "name": "fueloil"
              },
              {
                "name": "biomass"
              },
              {
                "name": "districtheating"
              }
            ]
          }
        ],
        "id": "635bb48a-8fdf-4c92-b1b3-04c651e710e4",
        "name": "Sankey",
        "colorPalette": {
          "agriculture": "#B3C890",
          "naturalgas": "#EA906C",
          "biomass": "#9BABB8",
          "residential": "#D2E9E9",
          "roadtransport": "#FEA1A1",
          "fueloil": "#D7C0AE",
          "districtheating": "#EEE3CB",
          "industry": "#F5F0BB",
          "electricity": "#94B49F",
          "services": "#ACBCFF",
          "othertransport": "#8884d8"
        },
        "size_24": 12,
        "title": "Analyse par secteur d'émission et énergie"
      }
      {
        "component_type": "sankey",
        "section": "energetic_bilan",
        "entityId": "abb284c6-7e0d-487d-b358-663875bf8bc0",
        "sorter": 2,
        "dataSource": [
          {
            "links": [
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 7
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 9
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 0,
                "target": 10
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 7
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 9
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 1,
                "target": 10
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 7
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 9
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 2,
                "target": 10
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 3,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 3,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 4,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 4,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 6
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 7
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 8
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 9
              },
              {
                "sqlRequest": "SUM(value) as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type='ghg_emissions_tco2eq';",
                "source": 5,
                "target": 10
              }
            ],
            "nodes": [
              {
                "name": "residential"
              },
              {
                "name": "services"
              },
              {
                "name": "industry"
              },
              {
                "name": "roadtransport"
              },
              {
                "name": "othertransport"
              },
              {
                "name": "agriculture"
              },
              {
                "name": "electricity"
              },
              {
                "name": "naturalgas"
              },
              {
                "name": "fueloil"
              },
              {
                "name": "biomass"
              },
              {
                "name": "districtheating"
              }
            ]
          }
        ],
        "id": "635bb48a-8fdf-4c92-b1b3-04c651e710e4",
        "name": "Sankey",
        "colorPalette": {
          "agriculture": "#B3C890",
          "naturalgas": "#EA906C",
          "biomass": "#9BABB8",
          "residential": "#D2E9E9",
          "roadtransport": "#FEA1A1",
          "fueloil": "#D7C0AE",
          "districtheating": "#EEE3CB",
          "industry": "#F5F0BB",
          "electricity": "#94B49F",
          "services": "#ACBCFF",
          "othertransport": "#8884d8"
        },
        "size_24": 12,
        "title": "Analyse par secteur d'émission et énergie"
      }`,
  table: `{
      "component_type": "table",
      "section": "carbon_bilan",
      "entityId": "abb284c6-7e0d-487d-b358-663875bf8bc0",
      "sorter": 3,
      "decimals": 1,
      "dataSource": [
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Résidentiel",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Services",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%)  and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Industrie",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'ch4' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Transport routier",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Transport non-routier",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Agriculture",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Déchets",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Total",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        }
      ],
      "id": "e9016b28-2df7-45e2-a963-7afba344db86",
      "name": "Table des consommations",
      "size_24": 24,
      "colorPalette": {
        "headerBackground": "grey",
        "background": "rgb(23, 24, 24)",
        "text": "white"
      },
      "title": "Bilan GES par secteur et type de flux physique (ktCO2eq)"
    }
    {
      "component_type": "table",
      "section": "carbon_bilan",
      "entityId": "abb284c6-7e0d-487d-b358-663875bf8bc0",
      "sorter": 3,
      "decimals": 1,
      "dataSource": [
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Résidentiel",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'residential' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Services",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%)  and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'services' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Industrie",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'ch4' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'industry' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Transport routier",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'roadtransport' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Transport non-routier",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'othertransport' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Agriculture",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'agriculture' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Déchets",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE emitting_sector = 'waste' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        },
        {
          "columns": [
            {
              "sqlRequest": "",
              "value": "Total",
              "key": "emitting_sector"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='electricity' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "electricity"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='naturalgas' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "naturalgas"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='fueloil' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "fueloil"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='biomass' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "biomass"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='districtheating' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "districtheating"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE energy='ch4' and city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "ch4"
            },
            {
              "sqlRequest": "SUM(value)/1e3 as result FROM yearly_indicators WHERE city_code in (%cityCodes%) and year between (%periodStart%) and (%periodEnd%) and type = 'ghg_emissions_tco2eq'",
              "key": "total"
            }
          ]
        }
      ],
      "id": "e9016b28-2df7-45e2-a963-7afba344db86",
      "name": "Table des consommations",
      "size_24": 24,
      "colorPalette": {
        "headerBackground": "grey",
        "background": "rgb(23, 24, 24)",
        "text": "white"
      },
      "title": "Bilan GES par secteur et type de flux physique (ktCO2eq)"
    }`,
  description: `{
      "component_type": "description",
      "section": "carbon_bilan",
      "icon": {
        "name": "home",
        "size": 40,
        "color": "red"
      },
      "entityId": "abb284c6-7e0d-487d-b358-663875bf8bc0",
      "sorter": 0,
      "dataSource": [
        "Le bilan GES (BEGES) du territoire que vous avez sélectionné regroupe les émissions de CO2, CH4, N20 et autres gaz à effet serre. \n Un bilan de consommation d'énergie est établi à la maille bâtiment par nos algorithmes en cohérence avec les flux mesurés par les distributeurs d'énergie puis projeté à l'aide de données disponibles en quasi temps-réel afin de vous être proposé à jour du mois dernier. Il est proposé en scope 1 et 2.\nLes secteurs transport, agriculture et déchets sont en cours de développement. \nLes émissions non-énergétiques ne sont pas encore traitées ce qui explique des valeurs légèrement en deça des inventaires régionaux sur les années qui se recoupent (2019)."
      ],
      "id": "d176a479-fa2f-4c9a-8cc1-b6a0ea141481",
      "name": "Description du bilan carbone",
      "size_24": 12,
      "title": "Le bilan GES territorial dynamique"
    }
    {
      "component_type": "description",
      "section": "carbon_bilan",
      "icon": {
        "name": "home",
        "size": 40,
        "color": "red"
      },
      "entityId": "abb284c6-7e0d-487d-b358-663875bf8bc0",
      "sorter": 0,
      "dataSource": [
        "Le bilan GES (BEGES) du territoire que vous avez sélectionné regroupe les émissions de CO2, CH4, N20 et autres gaz à effet serre. \n Un bilan de consommation d'énergie est établi à la maille bâtiment par nos algorithmes en cohérence avec les flux mesurés par les distributeurs d'énergie puis projeté à l'aide de données disponibles en quasi temps-réel afin de vous être proposé à jour du mois dernier. Il est proposé en scope 1 et 2.\nLes secteurs transport, agriculture et déchets sont en cours de développement. \nLes émissions non-énergétiques ne sont pas encore traitées ce qui explique des valeurs légèrement en deça des inventaires régionaux sur les années qui se recoupent (2019)."
      ],
      "id": "d176a479-fa2f-4c9a-8cc1-b6a0ea141481",
      "name": "Description du bilan carbone",
      "size_24": 12,
      "title": "Le bilan GES territorial dynamique"
    }`,
  map: `{
        "component_type": "map",
        "section": "attractive_map",
        "icon": {
          "name": "home",
          "size": 40,
          "color": "green"
        },
        "entityId": "abb284c6-7e0d-487d-b358-663875bf8bc0",
        "sorter": 0,
        "dataSource": [
          {
            "layers": [
              {
                "aggResult": 4967313.439996692,
                "legend": {
                  "type": "linear",
                  "max": 6264.885620096539,
                  "min": 5.881734432497449e-7
                },
                "aggType": "sum",
                "isVisible": true,
                "filters": [
                  {
                    "name": "Filtrer par émission de CO2 (tCO2eq)",
                    "field": "ghg_emissions_tco2eq",
                    "type": "numeric",
                    "value": [
                      0,
                      6000
                    ]
                  },
                  {
                    "name": "Filtrer par consommation d'énergie (MWh)",
                    "field": "energy_consumption_mwh",
                    "type": "numeric",
                    "value": [
                      0,
                      40000
                    ]
                  }
                ],
                "type": "Couches quartier (tier 2)",
                "colors": [
                  "#2B8388",
                  "#ABDDA4",
                  "#00FFBF",
                  "#FDAE61",
                  "#D7191C"
                ],
                "colorType": "linear",
                "field": "ghg_emissions_tco2eq",
                "name": "Secteur résidentiel (maille quartier)",
                "id": "mgp_residential_2022_tier2_v1_4",
                "category": "2D",
                "table": "public.mgp_residential_2022_tier2_v1_4"
              }
            ],
            "mapId": "demo",
            "zoomThreshold": 13,
            "height": 10
          }
        ],
        "id": "0e8a0749-4e4e-4a97-a512-da38afdac425",
        "name": "Description carte interactive",
        "size_24": 24,
        "title": "Voici une carte attractive invisible",
        "tooltipContent": "Veuillez souscrire aux fonctionnalités Premium pour accéder à toutes les cartes et outils d'analyse."
    }`,
};
