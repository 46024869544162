import { Divider, Empty, Skeleton, Typography } from "antd";
import { useContext } from "react";
import withSuspenseAndFallback from "../../../../../components/hoc/withSuspenseAndFallback";
import { DictionaryContext } from "../../../../../contexts/DictionaryContext";
import { IDictionary } from "../../../../../models/general/dictionary";
import {
  featureCategoryFieldNameToLabel,
  featureFieldNameToLabel,
} from "../../../../../services/utils";
import ConfigContext from "../../../contexts/configContext";
import { MapStateContext } from "../../../contexts/mapStateContext";
import ExploreTargetBarchart from "../../common/ExploreTargetBarchart";
import SkeletonLoader from "../../common/SkeletonLoader";
import GeneralInfo from "./GeneralInfo";
import Tier2SpecificSection, { AllSectorsData } from "./Tier2SpecificSection";
import {
  getDisplayedFeatures,
  getFeatureValue,
  getFeaturesByCategory,
  isFeatureAvailable,
} from "./utils";
import { useFeatures } from "../../../../../services/dataApi/hooks";
import { EntityContext } from "../../../../../contexts/EntityContext";
import moment from "moment";

const GREY = "#F4F4F4";
const GREEN = "#00A947";

const displayedFeaturesSample = [
  "ghg_emissions_tco2eq",
  "ghg_intensity_kg_m",
  "ghg_intensity_of_energy_gco2_kwh",
  "energy_consumption_mwh",
  "energy_consumption_mwh_fueloil",
  "energy_consumption_mwh_electricity",
  "energy_consumption_mwh_naturalgas",
  "vkm",
  "energy_label",
];

export const CellDisplayLoader: React.FC<{ isGrey: boolean }> = ({
  isGrey,
}) => {
  return (
    <div
      className="flex items-center justify-center h-[50px] p-4"
      style={{ backgroundColor: isGrey ? GREY : "white" }}
    >
      <Skeleton className="m-0 p-0" paragraph={false} active />
    </div>
  );
};

interface CellDisplayProps {
  label: string;
  value: string;
  isGrey?: boolean;
}

export const CellDisplay: React.FC<CellDisplayProps> = (props) => {
  return (
    <div
      className="flex items-center p-4"
      style={{ backgroundColor: props.isGrey ? GREY : "white" }}
    >
      {props.value === "" ? (
        <p>{props.label}</p>
      ) : (
        <p>
          {props.label} : <b>{props.value}</b>
        </p>
      )}
    </div>
  );
};

interface Tier3DataProps {
  sector: string;
  subsector: string;
  features: Record<string, string | number> | undefined;
  displayedFeatures: string[];
  dictionary: IDictionary;
}

const Tier3Data: React.FC<Tier3DataProps> = (props) => {
  const { sector, subsector, features, displayedFeatures, dictionary } = props;

  const availableFeatures = displayedFeatures
    .filter((f) => f !== "adress" && f !== 'road_name') // This features are already displayed in the general info
    .filter((f) => isFeatureAvailable(f, sector, subsector, features))

  const featuresByCategory = getFeaturesByCategory(
    availableFeatures,
    dictionary
  );
  if (!featuresByCategory || featuresByCategory.length === 0) return null;

  return (
    <>
      {/* <Typography.Title level={5} className="p-2">Activité principale</Typography.Title> */}
      {/* <CellDisplay label={readFromCommonDictionary(dictionary, getPrincipalActivity(sector, features) ?? "")} value="" isGrey /> */}
      {/* <Divider className="m-2" /> */}
      {featuresByCategory &&
        featuresByCategory.length > 0 &&
        featuresByCategory.map((fc, i) => (
          <>
            <Typography.Title level={5} className="p-2">
              {featureCategoryFieldNameToLabel(dictionary, fc.category)}
            </Typography.Title>
            {fc.features.map((f, i) => (
              <CellDisplay
                key={i}
                label={featureFieldNameToLabel(dictionary, f)}
                value={`${getFeatureValue(
                  sector,
                  subsector,
                  f,
                  features,
                  dictionary
                )}`}
                isGrey={i % 2 === 0}
              />
            ))}
            {i !== featuresByCategory.length - 1 && <Divider className="m-2" />}
          </>
        ))}
    </>
  );
};

interface Tier2DataProps {
  sector: string;
  subsector: string;
  features: Record<string, string | number> | undefined;
  displayedFeatures: string[];
  displayPrimaryEnergy?: boolean;
  barchart?: {
    category: string;
    title: string;
    fieldGroupBy: string;
    fieldStat: string;
    funcStat: string;
    includeDistrict: boolean;
    xLabels?: string[];
  };
  dictionary: IDictionary;
}

const Tier2Data: React.FC<Tier2DataProps> = (props) => {
  const {
    sector,
    subsector,
    features,
    displayedFeatures,
    dictionary,
    barchart,
    displayPrimaryEnergy,
  } = props;

  const districtName = features?.district_name as string;

  const availableFeatures = displayedFeatures.filter((f) =>
    isFeatureAvailable(f, sector, subsector, features)
  );
  const featuresByCategory = getFeaturesByCategory(
    availableFeatures,
    dictionary
  );

  if (barchart) {
    if (!featuresByCategory.find((fc) => fc.category === barchart.category)) {
      featuresByCategory.push({
        category: barchart.category,
        features: [],
      });
    }
  }

  return (
    <div className="my-2">
      {featuresByCategory &&
        featuresByCategory.length > 0 &&
        featuresByCategory.map((fc, i) => (
          <>
            <Typography.Title level={5} className="p-2">
              {featureCategoryFieldNameToLabel(dictionary, fc.category)}
            </Typography.Title>
            {fc.features.map((f, i) => (
              <CellDisplay
                key={i}
                label={featureFieldNameToLabel(dictionary, f)}
                value={`${getFeatureValue(
                  sector,
                  subsector,
                  f,
                  features,
                  dictionary
                )}`}
                isGrey={i % 2 === 0}
              />
            ))}
            {/* Add barchart in infra category */}
            {barchart && barchart.category === fc.category && (
              <div className="p-2">
                <ExploreTargetBarchart
                  title={barchart.title}
                  sector={sector}
                  fieldStat={barchart.fieldStat}
                  fieldGroupBy={barchart.fieldGroupBy}
                  funcStat={barchart.funcStat}
                  districtName={districtName}
                  includeDistrict={barchart.includeDistrict}
                  rotateXAxis={true}
                  xLabels={barchart.xLabels}
                />
              </div>
            )}
          </>
        ))}
      {/* Specific section */}
      <Tier2SpecificSection
        sector={sector}
        features={features}
        displayPrimaryEnergy={displayPrimaryEnergy}
        primaryEnergyField={barchart?.fieldStat}
      />
    </div>
  );
};

interface IDProps {}

const IDAnySector: React.FC<IDProps> = (props) => {
  const dictionary = useContext(DictionaryContext);
  const mapState = useContext(MapStateContext);
  const config = useContext(ConfigContext);
  const entityContext = useContext(EntityContext);

  const features = mapState.selectedFeatures;
  const selectedSector = mapState.selectedSector;
  const selectedSubsector = mapState.selectedSubsector;
  const selectedTier = mapState.currentTier;

  const selectedConfig = config.sectors.find(
    (s) => s.sector === selectedSector
  );

  const displayedFeatures = getDisplayedFeatures(selectedTier, selectedConfig);

  const availableYear = entityContext.activeEntity.availableYears[0] ?? 2023;
  const startDate = moment()
    .set({ year: availableYear })
    .startOf("year")
    .format("YYYY-MM-DD HH:mm:ss");
  const endDate = moment()
    .set({ year: availableYear })
    .endOf("year")
    .format("YYYY-MM-DD HH:mm:ss");

  const idShape =
    (features?.id_building as string) ?? (features?.link_id as string) ?? (features?.id_others as string) ?? "";

  // Retrieve features
  const { data: res } = useFeatures({
    batch: entityContext.activeEntity.batch,
    sector: selectedSector,
    subsector: selectedSubsector,
    dateMin: startDate,
    dateMax: endDate,
    tier: mapState.currentTier,
    idShape: selectedTier === 2 ? undefined : idShape,
    districtName:
      selectedTier === 2 ? (features?.district_name as string) : undefined,
    instance: entityContext.activeEntity.instance,
    selectedFeatures: displayedFeatures,
  });

  const featuresAPI = res?.[0] ?? {};

  if (!features || !selectedSector || !selectedSubsector || !selectedConfig)
    return <Empty className="p-2" description="Pas de données" />;

  return (
    <div className="w-full">
      {/* General info */}
      <GeneralInfo
        features={featuresAPI}
        tier={mapState.currentTier}
        sector={mapState.selectedSector}
      />

      {/* Specific display for tier2 */}
      {mapState.currentTier === 2 && (
        <Tier2Data
          sector={selectedSector}
          subsector={selectedSubsector}
          displayedFeatures={
            selectedConfig?.tier2.idConf.displayedFeatures ?? []
          }
          displayPrimaryEnergy={
            selectedConfig?.tier2.idConf.displayPrimaryEnergy
          }
          barchart={selectedConfig.tier2.idConf.barChart}
          features={featuresAPI}
          dictionary={dictionary}
        />
      )}

      {/* Specific display for tier3 */}
      {mapState.currentTier === 3 && (
        <Tier3Data
          sector={selectedSector}
          subsector={selectedSubsector}
          features={featuresAPI}
          displayedFeatures={
            selectedConfig?.tier3?.idConf.displayedFeatures ?? []
          }
          dictionary={dictionary}
        />
      )}
    </div>
  );
};

const IDAllSectors: React.FC<IDProps> = (props) => {
  const mapState = useContext(MapStateContext);
  const features = mapState.selectedFeatures;

  return (
    <>
      <GeneralInfo
        features={features}
        tier={mapState.currentTier}
        sector={mapState.selectedSector}
      />
      <AllSectorsData features={features} />
    </>
  );
};

const ID: React.FC<IDProps> = (props) => {
  const mapState = useContext(MapStateContext);

  if (mapState.selectedSector === "all") {
    return <IDAllSectors />;
  } else {
    return <IDAnySector />;
  }
};

export default withSuspenseAndFallback(ID, <SkeletonLoader />);
