import React, { memo, useContext } from "react";
import { colors } from "../../../assets/styles/colors";
import { DictionaryContext } from "../../../contexts/DictionaryContext";
import { LayoutContext } from "../../../contexts/LayoutContext";
import {
  featureFieldNameToLabel,
  hexToRgb,
  readFromCommonDictionary,
  smartUnits,
} from "../../../services/utils";

const ToolTipV2 = memo(function CustomTooltip(props: any) {
  const { payload, dataRendering, hoverIdx, dictionary } = props;
  const { isDarkMode } = React.useContext(LayoutContext);
  const data = payload?.[0]?.payload;

  if (hoverIdx === null || hoverIdx === undefined || !data) return <></>;

  const selected = dataRendering[hoverIdx];
  const value = data?.[selected.key];
  const total = dataRendering.reduce(
    (acc: number, cv: any) => acc + data?.[cv.key],
    0
  );
  const smartValue = smartUnits(
    value,
    // TODO get dynamic feature key
    dataRendering[hoverIdx].key,
    dictionary.features
  ).split(" ");
  const displayValue = smartValue[0];
  const displayUnit = smartValue[1];
  
  const label = (() => {
    const fromCommonDict = readFromCommonDictionary(dictionary, selected.label);
    const fromFeatures = featureFieldNameToLabel(dictionary, selected.label);

    if (fromCommonDict !== selected.label) {
      return fromCommonDict;
    }
    
    if (fromFeatures !== selected.label) {
      return fromFeatures;
    }

    return selected.label;
  })();

  return (
    <div
      className="flex flex-col"
      style={{
        minWidth: 250,
        height: 100,
        backgroundColor: isDarkMode ? colors.darkGrey : colors.lightMode,
        borderRadius: 10,
        boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.2)",
      }}
    >
      <div className="flex flex-row items-center m-2">
        <div
          style={{
            backgroundColor: selected.fillColor ?? "black",
            height: 10,
            width: 10,
            borderRadius: 10,
            marginRight: 10,
          }}
        />
        <div
          className="text-lg"
          style={{ color: isDarkMode ? colors.lightMode : colors.darkGrey }}
        >
          {label}
        </div>
      </div>
      <div
        className="mx-2"
        style={{ height: 1, backgroundColor: "lightgray" }}
      />
      <div className="flex flex-1 flex-row items-center justify-between m-2">
        <div
          className="px-2 py-1 text-xl font-extrabold"
          style={{
            color: selected.fillColor ?? "transparent",
            borderRadius: 6,
            backgroundColor: `rgba(${hexToRgb(selected.fillColor)}, 0.2)`,
          }}
        >
          {((100 * value) / total).toFixed(2)}%
        </div>
        <div className="flex flex-row items-baseline">
          <div
            className="text-xl font-extrabold"
            style={{ color: isDarkMode ? colors.lightMode : colors.darkGrey }}
          >
            {displayValue}
          </div>
          <div
            className="font-light"
            style={{ color: isDarkMode ? colors.lightMode : colors.darkGrey }}
          >
            &nbsp;{displayUnit}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ToolTipV2;
