import { useRef } from "react";

function computeProgress(min: number, max: number, value: number) {
  const progress = ((value - min) / (max - min)) * 100;

  if (progress < 0) return 0;
  if (progress > 100) return 100;

  return progress;
}

interface ProgressBarProps {
  min: number;
  max: number;
  value: number;
  unit: string;
  displayExtremeties?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { min, max, value, unit, displayExtremeties } = props;
  const  progress = computeProgress(min, max, value);

  const containerRef = useRef<any>(null);
  const tooltipRef = useRef<any>(null);

  return (
    <div className="flex flex-col relative">
      <div className="my-2 inline-flex relative h-10 items-center" ref={containerRef}>
        <div style={{ flex: progress}}></div>
        <div
          className="p-2 bg-white rounded-lg shadow-md"
          ref={tooltipRef}
          onResize={() => console.log('changed')}
        >
          <p className="text-[#00A947] font-medium">{value.toFixed(2)} {unit}</p>
        </div>
        <div style={{ flex: 100-progress }}></div>
      </div>
      <div
        className="relative w-full h-6 rounded-3xl flex items-center"
        style={{ background: 'linear-gradient(90deg, #5DCC96 1.29%, #FFBF31 50.64%, #E5442F 100%)' }}
      >
        <div className="absolute h-5 w-5 bg-white" style={{ borderRadius: '50%', left: `calc((100% - 20px) * ${progress / 100})` }} />
      </div>
      {displayExtremeties && <div className="flex flex-row justify-between mx-2">
        <div className="flex flex-col items-start">
          <p>-</p>
          <p className="text-[#9BA5B7]">{min.toFixed(0)} {unit}</p>
        </div>
        <div className="flex flex-col items-end">
          <p>+</p>
          <p className="text-[#9BA5B7]">{max.toFixed(0)} {unit}</p>
        </div>
      </div>}
    </div>
  );
};

export default ProgressBar; 
