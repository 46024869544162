import moment from "moment";
import AllSectorsTimeSeries from "./AllSectorsTimeSeries";
import BuildingsTimeSeries from "./BuildingsTimeSeries";
import RoadtransportimeSeriesTier3 from "./RoadtransportTimeSeriesTier3";
import RoadtransportimeSeriesTier2 from "./RoadtransportTimeSeriesTier2";
import { useContext } from "react";
import { EntityContext } from "../../../../../../contexts/EntityContext";

interface DetailsTimeSeriesProps {
  tier: number;
  features: Record<string, string | number> | undefined;
  sector: string;
  subsector: string;
  idShape: string;
  cityCode?: string;
}

const DetailsTimeSeries: React.FC<DetailsTimeSeriesProps> = (props) => {
  const { sector, tier, idShape, cityCode, subsector, features } = props;
  const { activeEntity } = useContext(EntityContext);

  const availableMonth = activeEntity.availableMonth ?? { month: 1, year: 2023 };
  // Start date is the first day of the available month
  const startDate = moment().set({ year: availableMonth.year - 1, month: availableMonth.month - 1 }).startOf('month').format('YYYY-MM-DD HH:mm:ss') + "+01:00";
  const endDate = moment().set({ year: availableMonth.year, month: availableMonth.month - 1 }).endOf('month').format('YYYY-MM-DD HH:mm:ss') + "+01:00";

  switch (sector) {
    case 'all':
      return <AllSectorsTimeSeries districtName={idShape} startDate={startDate} endDate={endDate} />;
    case 'residential':
    case 'services':
    case 'industry':
      return <BuildingsTimeSeries idShape={idShape} sector={sector} subsector={subsector} tier={tier} startDate={startDate} endDate={endDate} />
    case 'roadtransport':
      if (tier === 2) {
        return <RoadtransportimeSeriesTier2 idShape={idShape} sector={sector} subsector={subsector} cityCode={cityCode as string} startDate={startDate} endDate={endDate} />
      } else {
        if (subsector === "buses" || features?.road_type === "buses") return <></>;
        return <RoadtransportimeSeriesTier3 idShape={idShape} sector={sector} subsector={subsector} cityCode={cityCode as string} endDate={endDate} />
      }
    default:
      return <></>;
  }
};

export default DetailsTimeSeries;