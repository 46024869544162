import { IExplorationConfiguration } from "../../../../models/exploreV2/configuration";
import { ILayerFilter, INumericFilter } from "../../../../models/exploreV2/filters";

function getStatisticsFieldsFromConf(config: IExplorationConfiguration, sector: string, tier: number) {
  const defaultField = "ghg_emissions_tco2eq";
  const selectedConfig = config.sectors.find((c) => c.sector === sector);

  if (!selectedConfig) return { fieldSum: defaultField, fieldMean: defaultField };

  switch (tier) {
    case 2:
      return { fieldSum: selectedConfig.tier2.fieldSum ?? defaultField, fieldMean: selectedConfig.tier2.fieldStat ?? defaultField };
    case 3:
      return { fieldSum: selectedConfig.tier3?.fieldSum ?? defaultField, fieldMean: selectedConfig.tier3?.fieldStat ?? defaultField };
    default:
      return { fieldSum: defaultField, fieldMean: defaultField };
  }
}

function filtersToAPIFilters(filters: ILayerFilter[], subsector: string | undefined, tier?: number) {
  const apiNumericFilters: Record<string, any> = {};
  const apiOtherFilters: Record<string, any> = {};
  filters.forEach((f) => {
    switch (f.type) {
      case 'numeric':
        if (tier && f.tier && f.tier !== tier) break;
        const min = f.value[0] === f.min ? undefined : f.value[0];
        const max = f.value[1] === f.max ? undefined : f.value[1];
        if (!max && !min) break;
        if (!max) {
          apiNumericFilters[f.field] = { min };
          break;
        };
        if (!min) {
          apiNumericFilters[f.field] = { max };
          break;
        }
        apiNumericFilters[f.field] = { max: f.value[1], min: f.value[0] };
        break;
      case 'dropdown':
        if (tier && f.tier !== tier) break;
        // Handle case when there is a special option for API
        if (f.options_api) {
          const idx = (f.options as any[]).indexOf(f.value);
          if (idx !== -1) {
            apiOtherFilters[f.field] = f.options_api[idx];
          }
        } else {
          apiOtherFilters[f.field] = f.value.toString();
        }
        break;
      case 'checkbox':
        if (tier && f.tier !== tier) break;
        // Handle case when there is a special option for API
        if (f.options_api) {
          f.value.forEach((v) => {
            const idx = (f.options as any[]).indexOf(v);
            if (idx !== -1) {
              apiOtherFilters[f.field] = apiOtherFilters[f.field] ? [...apiOtherFilters[f.field], f.options_api?.[idx]] : [f.options_api?.[idx]];
            }
          });
        } else {
          apiOtherFilters[f.field] = f.value.length > 0 ? f.value.map(f => f.toString()) : undefined;
        }
        break;
    };
  });

  return { numericFilters: apiNumericFilters, otherFilters: apiOtherFilters };
}

export { getStatisticsFieldsFromConf, filtersToAPIFilters };