import { CircleLayer, FillLayer, LineLayer, RasterLayer } from "mapbox-gl";
import { IAdditionalLayer } from "../../models/exploreV2/configuration";
import { IUploadLayer } from "../../models/explore/layers/uploadLayer";

function getRandomHexColor() {
  // Generate random hexadecimal color code
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

function propsFromLayer(
  layer: any,
) {
  const baseProps = {
    id: `${layer.tileset}`,
    type: "fill",
    'source-layer': `${layer.tileset}`,
    paint: {
      // Pick a random color
      'fill-color': getRandomHexColor(),
    }
  };

  return baseProps as FillLayer;
}

function propsFromStaticLayer(layer: IAdditionalLayer, isVisible: boolean) {
  if (layer.geometryType === 'polygon') {
    const baseProps = {
      id: `${layer.id}`,
      source: `source-${layer.tileset}`,
      'source-layer': `${layer.tileset}`,
      layout: {
        visibility: isVisible ? 'visible' : 'none'
      },
      type: "fill",
      paint: {
        "fill-color": layer.color,
        "fill-opacity": layer.opacity,
      }
    }

    return { ...baseProps } as FillLayer;
  }
  else if (layer.geometryType === 'line') {
    const baseProps = {
      id: `${layer.id}`,
      source: `source-${layer.tileset}`,
      'source-layer': `${layer.tileset}`,
      layout: {
        visibility: isVisible ? 'visible' : 'none'
      },
      type: "line",
      paint: {
        "line-color": layer.color,
        "line-width": layer.lineWidth ?? 2.5,
        "line-opacity": layer.opacity

      },
    }

    return { ...baseProps } as LineLayer;
  }
  else {
    const baseProps = {
      id: `${layer.id}`,
      source: `source-${layer.tileset}`,
      'source-layer': `${layer.tileset}`,
      layout: {
        visibility: isVisible ? 'visible' : 'none'
      },
      type: "circle",
      paint: {
        "circle-color": layer.color,
        "circle-opacity": layer.opacity,
        "circle-radius": 10,
      },
    }

    return { ...baseProps } as CircleLayer;
  }
}

function propsFromUploadLayer(layer: IUploadLayer, isVisible: boolean) {
  const baseProps = {
    id: `${layer.id}`,
    source: `source-${layer.id}`,
    type: "fill",
    paint: {
      "fill-color": "#0000FF",
      "fill-opacity": 0.5,
    },
    layout: {
      visibility: isVisible ? 'visible' : 'none'
    }
  }

  return { ...baseProps } as FillLayer;
}

function propsFromUploadLineLayer(layer: IUploadLayer, isVisible: boolean) {
  const baseProps: LineLayer = {
    id: `${layer.id}line`,
    source: `source-${layer.id}`,
    type: "line",
    paint: {
      "line-color": "#000",
      "line-width": 3,
    },
    layout: {
      visibility: isVisible ? 'visible' : 'none'
    }
  }

  return { ...baseProps } as LineLayer;
}

function propsFromWMSLayer(layer: IUploadLayer, isVisible: boolean) {
  const baseProps = {
    id: `${layer.id}`,
    source: `source-${layer.id}`,
    type: "raster",
    paint: {
      "raster-opacity": 0.5,
    },
    layout: {
      visibility: isVisible ? 'visible' : 'none'
    }
  }

  return { ...baseProps } as RasterLayer;
}

export { propsFromLayer, propsFromStaticLayer, propsFromUploadLayer, propsFromUploadLineLayer, propsFromWMSLayer }