import React from "react";
import { IExplorationConfiguration } from "../../../models/exploreV2/configuration";

const initialConfig: IExplorationConfiguration = {
  id: '',
  sizeLimit: 0,
  zoomThreshold: 0,
  pitch: 0,
  opacity: 0,
  opacity3D: 0,
  height: 0,
  filters: [],
  sectors: [],
  additionalLayers: [],
}; 

const ConfigContext = React.createContext<IExplorationConfiguration>(initialConfig);

export default ConfigContext;