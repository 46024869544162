import React, { Suspense, useContext } from "react";
import { useMean, useSum } from "../../../../services/dataApi/hooks";
import withSuspenseAndFallback from "../../../../components/hoc/withSuspenseAndFallback";
import SkeletonLoader from "../common/SkeletonLoader";
import { smartUnits } from "../../../../services/utils";
import { DictionaryContext } from "../../../../contexts/DictionaryContext";
import { PerimeterContext } from "../../../../contexts/PerimeterContext";
import { MapStateContext } from "../../contexts/mapStateContext";
import { IExplorationConfiguration } from "../../../../models/exploreV2/configuration";
import { SectorConfiguration } from "../../../../models/exploreV2/sector";
import { getStatisticsFieldsFromConf } from "./utils";
import { EntityContext } from "../../../../contexts/EntityContext";
import { useAllSectorsStatistics } from "../../../../services/api/exploration/hooks";

const GREEN = "#00A947";
const LIGHT_GREEN = "#E6F6ED";

const TerritoryStatisticsLoader: React.FC = () => {
  return (
    <div className={`p-4 w-[310px] h-[200px] flex flex-col justify-between bg-white rounded-lg`}>
      <SkeletonLoader />
    </div>
  )
}

interface TerritoryStatisticsProps {
  config: IExplorationConfiguration;
}

const TerritoryStatistics: React.FC<TerritoryStatisticsProps> = (props) => {
  const { config } = props;
  const { relevantCityCodes } = useContext(PerimeterContext);
  const dictionary = useContext(DictionaryContext);
  const { selectedSector: sector } = useContext(MapStateContext);
  const { activeEntity } = useContext(EntityContext);
  const tier = sector === 'all' ? 2 : 3;

  const { fieldSum, fieldMean } = getStatisticsFieldsFromConf(config, sector, tier);

  const sectorArg = sector === 'all' ? undefined : sector;
  const cumulatedSectors = sector === 'all' ? true : false;

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;

  const { data: sumRes } = useSum({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: sectorArg,
    subsector: undefined,
    startDate: startDate,
    endDate: endDate,
    tier,
    field: fieldSum,
    cumulatedSectors,
    cities: relevantCityCodes,
  });
  
  const { data: meanRes } = useMean({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: sectorArg,
    subsector: undefined,
    startDate: startDate,
    endDate: endDate,
    tier,
    field: fieldMean,
    cumulatedSectors,
    cities: relevantCityCodes,
  });


  const sum = sumRes?.[0]?.[fieldSum] ?? sumRes?.[fieldSum];
  const mean = meanRes?.[0]?.[fieldMean] ?? sumRes?.[fieldMean];
  
  const sumSmart = smartUnits(sum, fieldSum, dictionary.features).split(' ');
  const sumValue = sumSmart[0];
  const sumUnit = sumSmart[1];

  const meanSmart = smartUnits(mean, fieldMean, dictionary.features).split(' ');
  const meanValue = meanSmart[0];
  const meanUnit = meanSmart[1];

  return (
    <div className={`p-4 w-[310px] h-[200px] flex flex-col justify-between bg-white rounded-lg`}>
      <h1 style={{ fontSize: 22, marginBottom: 10 }}>Total Territoire</h1>
      <p style={{ color: GREEN, fontSize: 22 }}><span style={{ color: GREEN, fontWeight: 800, fontSize: 48 }}>{sumValue}</span> {sumUnit}/an</p>
      <div className="flex items-center">
        <p className="text-[16px] mr-2">Moyenne :</p>
        <div className="items-center justify-center rounded-lg p-2" style={{ backgroundColor: LIGHT_GREEN, color: GREEN }}><span style={{ color: GREEN, fontWeight: 800 }}>{meanValue}</span>{' '}{meanUnit}/an</div>
      </div>
    </div>
  );
};

const TerritoryStatisticsAllSectors: React.FC<TerritoryStatisticsProps> = (props) => {
  const { config } = props;
  const { activeEntity } = useContext(EntityContext);
  const { relevantCityCodes } = useContext(PerimeterContext);
  const { selectedSector: sector } = useContext(MapStateContext);
  const dictionary = useContext(DictionaryContext);
  const { fieldSum, fieldMean } = getStatisticsFieldsFromConf(config, 'all', 2);
  const tier = sector === 'all' ? 2 : 3;

  const sectorArg = sector === 'all' ? undefined : sector;
  const cumulatedSectors = sector === 'all' ? true : false;

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;

  const res = useAllSectorsStatistics(
    fieldSum,
    fieldMean,
    relevantCityCodes,
    startDate,
    endDate,
    {},
    {}
  );

  const { data: meanRes } = useMean({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: sectorArg,
    subsector: undefined,
    startDate: startDate,
    endDate: endDate,
    tier,
    field: fieldMean,
    cumulatedSectors,
    cities: relevantCityCodes,
  });


  const sum =res.data?.sum;
  const mean = meanRes?.[0]?.[fieldMean];
  
  const sumSmart = smartUnits(sum, fieldSum, dictionary.features).split(' ');
  const sumValue = sumSmart[0];
  const sumUnit = sumSmart[1];

  const meanSmart = smartUnits(mean, fieldMean, dictionary.features).split(' ');
  const meanValue = meanSmart[0];
  const meanUnit = meanSmart[1];

  return (
    <div className={`p-4 w-[310px] h-[200px] flex flex-col justify-between bg-white rounded-lg`}>
      <h1 style={{ fontSize: 22, marginBottom: 10 }}>Total Territoire</h1>
      <p style={{ color: GREEN, fontSize: 22 }}><span style={{ color: GREEN, fontWeight: 800, fontSize: 48 }}>{sumValue}</span> {sumUnit}/an</p>
      <div className="flex items-center">
        <p className="text-[16px] mr-2">Moyenne :</p>
        <div className="items-center justify-center rounded-lg p-2" style={{ backgroundColor: LIGHT_GREEN, color: GREEN }}><span style={{ color: GREEN, fontWeight: 800 }}>{meanValue}</span>{' '}{meanUnit}/an</div>
      </div>
    </div>
  );
};

const TerritoryStatisticsWrapper: React.FC<TerritoryStatisticsProps> = (props) => {
  const { config } = props;
  const { selectedSector, currentTier } = useContext(MapStateContext);

  const shouldDisplay = (() => {
    const selectedConfig = config?.sectors?.find((c) => c.sector === selectedSector);
    if (!selectedConfig) return false;

    switch (currentTier) {
      case 2:
        return !!selectedConfig.tier2;
      case 3:
        return !!selectedConfig.tier3;
      default:
        return false;
    }
  })();

  if (!shouldDisplay) return <></>;

  if (selectedSector === 'all') {
    return <TerritoryStatisticsAllSectors {...props} />
  }

  return <TerritoryStatistics {...props} />
}

export default withSuspenseAndFallback(TerritoryStatisticsWrapper, <TerritoryStatisticsLoader />);