import { Select, Tag } from "antd";
import React from "react";

export interface SelectMultipleProps {
  options: { label: string | number; value: string | number }[];
  onChange: (values: string[]) => void;
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
}

const SelectMultiple: React.FC<SelectMultipleProps> = ({
  options,
  onChange,
  selectedValues,
  setSelectedValues,
}) => {
  const MultiSelectTag = ({ value }: { value: string }) => {
    return value === selectedValues?.[0] ? (
      <Tag>{`${selectedValues.length} sélectionés`}</Tag>
    ) : (
      (null as any)
    );
  };

  return (
    <Select
      onChange={(values: string[]) => {
        setSelectedValues(values);
        onChange(values);
      }}
      allowClear
      tagRender={MultiSelectTag}
      mode="multiple"
      placeholder={"Sélectionner des options"}
      options={options}
      style={{ width: "100%" }}
      value={selectedValues}
    />
  );
};

export default SelectMultiple;
