import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../services/translations/en.json';

const resources = {
    en
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: ['en'],
        detection: {
            order: ['queryString', 'cookie'],
            caches: ['cookie']
        }
    });
