import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space, Typography } from "antd";
import React, { useContext, useState } from "react";
import { ILayer } from "../../../../../../models/explore/layers/layerV2";
import { IUploadLayer } from "../../../../../../models/explore/layers/uploadLayer";
import { IAdditionalLayer } from "../../../../../../models/exploreV2/configuration";
import DropdownRender from "./DropdownRender";
import {
  MapStateContext,
  MapStateDispatch,
} from "../../../../contexts/mapStateContext";

export type IAdditionalLayerSelected = IAdditionalLayer & {
  isSelected: boolean;
};

interface LayerSelectionProps {
  layers: IAdditionalLayer[];
}

const LayerSelection: React.FC<LayerSelectionProps> = (props) => {
  const { layers: layerProps } = props;
  const dispatch = useContext(MapStateDispatch);
  const { visibleAdditionalLayers, additionalLayers } =
    useContext(MapStateContext);

  const layers = layerProps.map((l) => ({
    ...l,
    isSelected: additionalLayers?.includes(l.id) ?? false,
  }));

  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false);

  const onSelectLayer = (layer: IAdditionalLayerSelected) => {
    dispatch({ type: "TOGGLE_ADDITIONAL_LAYER", layerId: layer.id });
  };

  const onToggleLayerVisibility = (layerId: string) => {
    dispatch({ type: "TOGGLE_ADDITIONAL_LAYER_VISIBILITY", layerId });
  };

  return (
    <div className="px-3 my-3 w-full">
      <div className="w-full flex flex-row justify-between items-center my-2">
        <Typography.Text style={{ fontStyle: "italic", color: "#A4A4A4" }}>
          Ajouter une couche
        </Typography.Text>
        <Dropdown
          open={isOpenDropDown}
          menu={{ items: [] }}
          disabled={layers.length === 0}
          trigger={["click"]}
          dropdownRender={(props) => {
            return (
              <DropdownRender
                layers={layers}
                onSelectLayer={onSelectLayer}
                onClose={() => setIsOpenDropDown(false)}
              />
            );
          }}
          onOpenChange={(open) => setIsOpenDropDown(open)}
        >
          <Button type="text" icon={<PlusOutlined />}></Button>
        </Dropdown>
      </div>
      <Space direction="vertical" className="w-full">
        {layers
          .filter((l) => !!l.isSelected)
          .map((layer) => (
            <Space
              key={layer.id}
              className="w-full"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {layer.name}
              <Button
                type="default"
                icon={
                  visibleAdditionalLayers?.includes(layer.id) ? (
                    <EyeOutlined />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
                onClick={() => onToggleLayerVisibility(layer.id)}
              />
            </Space>
          ))}
      </Space>
    </div>
  );
};

export default LayerSelection;
