import React, { Suspense } from "react";
import ErrorFallback from "../common/ErrorFallback";
import ErrorBoundaryWithFallback from "../common/ErrorBoundaryWithFallback";
import Loader from "../common/Loader";

const withSuspenseAndFallback = (
  Component: React.ComponentType<any>,
  SuspenseComponentFallback?: any,
  ErrorComponentFallback?: any
) => {
  const defaultSuspenseFallback = (
    <div
      style={{
        padding: 200,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Loader content="Chargement de vos données" />
    </div>
  );

  const defaultErrorFallback = (
    <ErrorFallback content="Une erreur est survenue. Veuillez réessayer plus tard ou nous contacter à l'adresse hello@nexqt.com si l'erreur persiste." />
  );

  return (props: any) => (
    <Suspense fallback={SuspenseComponentFallback ?? defaultSuspenseFallback}>
      <ErrorBoundaryWithFallback
        fallback={ErrorComponentFallback ?? defaultErrorFallback}
      >
        <Component {...props} />
      </ErrorBoundaryWithFallback>
    </Suspense>
  );
};

export default withSuspenseAndFallback;
