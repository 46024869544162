import { CloseOutlined } from "@ant-design/icons";
import React, { useContext, useEffect } from "react";
import IDIcon from "../../../../assets/icons/exploreTarget/IDIcon";
import ScoreIcon from "../../../../assets/icons/exploreTarget/ScoreIcon";
import DetailsIcon from "../../../../assets/icons/exploreTarget/DetailsIcon";
import { MapStateContext } from "../../contexts/mapStateContext";
import ConfigContext from "../../contexts/configContext";

// Importing main components
import { ID } from "./id";
import { Scores } from "./scores";
import { DetailsSection } from "./details";
import { CellDisplay } from "./id/ID";
import { Button } from "antd";
import AdditionalFeatures from "./AdditionalFeatures";

const MENU_HEIGHT = 75;

const GREEN = "#00A947";
const bgWhiteCn = "bg-[#F9FAFF]";
const bgGreenCn = "bg-[#00A947]";
const bgGreyCn = "bg-[#E8E8E8]";
const bgLightGreenCn = "bg-[#E6F6ED]";
const textGreyCn = "text-[#9BA5B7]";
const textGreenCn = "text-[#00A947]";

const clickableCn = "cursor-pointer hover:opacity-50";
const flexCenterCn = "flex items-center justify-center";

interface MenuProps {
  selected: number;
  setSelected: (index: number) => void;
  onClickClose: () => void;
}

const Menu: React.FC<MenuProps> = ({ selected, setSelected, onClickClose }) => {
  return (
    <div
      className={`w-full ${bgWhiteCn} drop-shadow-sm overflow-hidden flex flex-row`}
      style={{ height: MENU_HEIGHT }}
    >
      <div
        className="flex-1 relative"
        style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}
      >
        {["Infos", "Score", "Détails"].map((i, idx) => (
          <div
            className={`${clickableCn} ${flexCenterCn} flex-col font-medium ${
              selected === idx ? bgLightGreenCn : ""
            }`}
            onClick={() => setSelected(idx)}
          >
            {(() => {
              switch (i) {
                case "Infos":
                  return <IDIcon fill={selected === idx ? GREEN : undefined} />;
                case "Score":
                  return (
                    <ScoreIcon fill={selected === idx ? GREEN : undefined} />
                  );
                case "Détails":
                  return (
                    <DetailsIcon fill={selected === idx ? GREEN : undefined} />
                  );
              }
            })()}
            <div className={selected === idx ? textGreenCn : textGreyCn}>
              {i}
            </div>
          </div>
        ))}
        {/* Indicator */}
        <div
          className={`w-6 absolute -bottom-1 h-2 ${bgGreenCn} rounded-md`}
          style={{
            transition: "left 0.3s ease-in-out",
            left: `calc(${selected * 33.33}% + 16.67% - 12px)`,
          }}
        />
      </div>
      {/* Close button */}
      <div
        className={`w-9 h-full ${flexCenterCn} ${bgGreyCn} ${clickableCn}`}
        onClick={onClickClose}
      >
        <CloseOutlined />
      </div>
    </div>
  );
};

interface TileInfosProps {
  onClose: () => void;
}

/**
 * Tile infos component displayed in the upper left panel of a the overlay when a tile is selected
 * @param props onClose callback
 * @returns component
 */
const TileInfos: React.FC<TileInfosProps> = (props) => {
  const { onClose } = props;

  const mapState = useContext(MapStateContext);
  const config = useContext(ConfigContext);

  const selectedSector = mapState.selectedSector;
  const selectedConfig = config.sectors.find(
    (c) => c.sector === selectedSector
  );
  const scoresConf =
    mapState.currentTier === 2
      ? selectedConfig?.tier2.scoresConf
      : selectedConfig?.tier3?.scoresConf;

  const [currentTier, setCurrentTier] = React.useState(mapState.currentTier);
  const [selected, setSelected] = React.useState(0);

  // Close when changing tier
  useEffect(() => {
    if (mapState.currentTier !== currentTier) {
      setCurrentTier(mapState.currentTier);
      onClose();
    }
  }, [mapState.currentTier]);

  return (
    <div className="w-[400px] bg-white h-full flex flex-col">
      {mapState.selectedFeaturesType === "layer" ? (
        <>
          <div style={{ height: MENU_HEIGHT }}>
            <Menu
              selected={selected}
              setSelected={setSelected}
              onClickClose={onClose}
            />
          </div>
          <div className="overflow-y-auto overflow-x-hidden h-full">
            {selected === 0 && <ID />}
            {selected === 1 && scoresConf && <Scores config={scoresConf} />}
            {selected === 2 && <DetailsSection />}
          </div>
        </>
      ) : (
        <div className="py-2 overflow-auto flex flex-col">
          <Button
            className="self-end my-2 me-4"
            type="text"
            icon={<CloseOutlined style={{ color: "#919EAB" }} />}
            onClick={onClose}
          />
          <AdditionalFeatures />
        </div>
      )}
    </div>
  );
};

export default TileInfos;
