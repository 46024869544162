import { IExplorationConfiguration } from "../../../models/exploreV2/configuration";
import { IAdditionalLayer } from "../../../models/explore/layers/additionnalLayer";
import { ILayer } from "../../../models/explore/layers/layer";
import APIService, { APIResult, apiUrl } from "../common";
import { IAnalysis } from "../../../models/exploreV2/analysis";

export class ExplorationService extends APIService {
  private route = `${apiUrl}/exploration`;

  public async fetchExplorationConfig(entityId: string): Promise<APIResult<IExplorationConfiguration>> {
    return this.makeAxiosJSONRequest<IExplorationConfiguration>("GET", `${this.route}/${entityId}/configuration`)
  }

  public async updateExplorationConfig(id: string, conf: IExplorationConfiguration): Promise<APIResult<IExplorationConfiguration>> {
    return this.makeAxiosJSONRequest<IExplorationConfiguration>("PUT", `${this.route}/configuration/${id}`, {}, conf)
  }

  public async saveLayer(conf: ILayer): Promise<APIResult<ILayer>> {
    return this.makeAxiosJSONRequest<ILayer>("POST", `${this.route}/layer`, {}, conf)
  }

  public async updateLayer(id: string, conf: ILayer): Promise<APIResult<ILayer>> {
    return this.makeAxiosJSONRequest<ILayer>("PUT", `${this.route}/layer/${id}`, {}, conf)
  }

  public async deleteLayer(id: string): Promise<APIResult<ILayer>> {
    return this.makeAxiosJSONRequest<ILayer>("DELETE", `${this.route}/layer/${id}`)
  }

  public async fetchAnalyses(entityId: string): Promise<APIResult<IAnalysis[]>> {
    return this.makeAxiosJSONRequest<IAnalysis[]>("GET", `${this.route}/${entityId}/analyses`)
  }

  public async saveAnalysis(analysis: IAnalysis): Promise<APIResult<IAnalysis>> {
    return this.makeAxiosJSONRequest<IAnalysis>("POST", `${this.route}/analysis`, {}, analysis)
  }

  public async updateAnalysis(id: string, analysis: IAnalysis): Promise<APIResult<IAnalysis>> {
    return this.makeAxiosJSONRequest<IAnalysis>("PUT", `${this.route}/analysis/${id}`, {}, analysis)
  }

  public async deleteAnalysis(id: string): Promise<APIResult<IAnalysis>> {
    return this.makeAxiosJSONRequest<IAnalysis>("DELETE", `${this.route}/analysis/${id}`)
  }

  public async fetchExportTable(entityId: string, sector: string, subsector: string | undefined, frequency: 'month' | 'year', startTime: number, endTime: number, cities: string[]): Promise<APIResult<any>> {
    return this.makeAxiosJSONRequest<any>("GET", `${this.route}/${entityId}/export`, { sector, subsector, frequency, startTime, endTime, cities: cities.join(',') })
  }

  public async saveAdditionalLayer(conf: IAdditionalLayer): Promise<APIResult<IAdditionalLayer>> {
    return this.makeAxiosJSONRequest<IAdditionalLayer>("POST", `${this.route}/additional-layer`, {}, conf)
  }

  public async updateAdditionalLayer(id: string, conf: IAdditionalLayer): Promise<APIResult<IAdditionalLayer>> {
    return this.makeAxiosJSONRequest<IAdditionalLayer>("PUT", `${this.route}/additional-layer/${id}`, {}, conf)
  }

  public async deleteAdditionalLayer(id: string): Promise<APIResult<IAdditionalLayer>> {
    return this.makeAxiosJSONRequest<IAdditionalLayer>("DELETE", `${this.route}/additional-layer/${id}`)
  }

  public async fetchQueryResult(aggType: string, table: string, field: string, cities: string[]): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest<number>("GET", `${this.route}/query-result`, { aggType, table, field, cities })
  }

  public async fetchExportByCityCode(
    entitydId: string, 
    cityCode: string, 
    sector: string, 
    subsector: string,
    startDate: number,
    endDate: number,
    freq: string,
    numericFilters: Record<string, any>, 
    otherFilters: Record<string, any>,
    cities: string[],
  ): Promise<any> {
    return this.makeAxiosJSONRequest<any>("POST", `${this.route}/${entitydId}/export/city-code/${cityCode}`, { startDate, endDate, sector, subsector, freq, cities }, { numericFilters, otherFilters })
  }

  public async fetchExportByZone(
    entityId: string,
    sector: string,
    subsector: string,
    startDate: number,
    endDate: number,
    freq: string,
    numericFilters: Record<string, any>,
    otherFilters: Record<string, any>,
    cities: string[],
  ): Promise<any> {
    return this.makeAxiosJSONRequest<any>("POST", `${this.route}/${entityId}/export/zone`, { startDate, endDate, sector, subsector, freq, cities }, { numericFilters, otherFilters })
  }

  public async fetchExportByPolygon(
    entityId: string,
    sector: string,
    subsector: string,
    startDate: number,
    endDate: number,
    freq: string,
    numericFilters: Record<string, any>,
    otherFilters: Record<string, any>,
    cities: string[],
    polygon: string,
  ): Promise<any> {
    return this.makeAxiosJSONRequest<any>("POST", `${this.route}/${entityId}/export/zone`, { startDate, endDate, sector, subsector, freq, cities }, { numericFilters, otherFilters, polygon })
  }

  public async fetchAllSectorsStatistics(
    fieldSum: string,
    fieldMean: string,
    cities: string[],
    startDate: string,
    endDate: string,
    numericFilters: Record<string, any>,
    otherFilters: Record<string, any>,
  ): Promise<any> {
    return this.makeAxiosJSONRequest<any>("GET", `${this.route}/statistics/all-sectors`, { fieldSum, fieldMean, cities, startDate, endDate }, { numericFilters, otherFilters })
  }
}