import { Typography } from "antd";
import { useTimeSeries, useTimeSeriesRoadName } from "../../../../../../services/dataApi/hooks";
import AreaChartV2, { AreaChartElement } from "../../../../../../components/graphs/AreaChart/AreaChartV2";
import { getDataRendering } from "./utils";
import moment from "moment-timezone";
import { useContext } from "react";
import { LocalizationContext } from "../../../../../../contexts/LocalizationContext";
import { EntityContext } from "../../../../../../contexts/EntityContext";

interface RoadtransportimeSeriesTier3Props {
  sector: string;
  subsector: string;
  idShape: string;
  cityCode: string;
  endDate: string;
};

const RoadtransportimeSeriesTier3: React.FC<RoadtransportimeSeriesTier3Props> = (props) => {
  const { sector, idShape, cityCode, subsector, endDate } = props;
  const { timezone } = useContext(LocalizationContext);
  const { activeEntity } = useContext(EntityContext);

  const field = "vkm";

  const { data } = useTimeSeriesRoadName({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: sector,
    subsector: subsector,
    startDate: "2019-01-01 00:00:00+01:00",
    endDate: endDate,
    cityCode,
    roadName: idShape,
  });
 
  if (!data || data.length === 0) return <></>;

  const dataRendering = [{
    label: "Véhicules-kilomètres",
    key: field,
    strokeColor: "#FF0000",
    fillColor: "#FF0000"
  }];
  const keys = [field];

  const areaChartData = data.map((d) => {
    const res: Record<string, any> = {
      x: moment.tz(d.date_time, 'Etc/UTC').tz(timezone).format("MMM YYYY"),
    };

    keys.forEach(k => {
      res[k] = d[k];
    });

    return res;
  });

  const areaChartProps = {
    data: areaChartData as unknown as AreaChartElement[],
    dataRendering,
    featureKey: field,
    selectedYear: 2023,
    intervalXAxis: true,
  }
  
  return (
    <div className="w-full h-[250px] p-2">
      <Typography.Title level={4}>{`Evolutions du trafic depuis l'année 2019 sur ${idShape} (${cityCode})`}</Typography.Title>
      <AreaChartV2 {...areaChartProps} />
    </div>
  )
}

export default RoadtransportimeSeriesTier3;