import React, { useEffect } from "react";
import { EntityContext } from "./EntityContext";
import { useAllInstances } from "../services/api/globalConfiguration/hooks";

const DEFAULT_LOCALIZATION = {
  locale: "fr",
  timezone: "Europe/Paris",
};

interface ILocalization {
  locale: string;
  timezone: string;
}

const LocalizationContext = React.createContext<ILocalization>(DEFAULT_LOCALIZATION);

const LocalizationProvider = ({ children }: { children: React.ReactElement }) => {
  const { activeEntity } = React.useContext(EntityContext);
  const { data: instances } = useAllInstances();

  const [localization, setLocalization] = React.useState<ILocalization>(DEFAULT_LOCALIZATION);

  useEffect(() => {
    if (activeEntity.instance != undefined) {
      const instance = instances?.find((i) => i.name === activeEntity.instance);
      if (instance) {
        setLocalization({
          locale: instance.locale,
          timezone: instance.timezone,
        });
      }
    }
  }, [activeEntity]);

  return (
    <LocalizationContext.Provider value={localization}>
      {children}
    </LocalizationContext.Provider>
  );
};

export { LocalizationContext, LocalizationProvider };