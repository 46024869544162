import { Divider, Space, Tag, Typography } from "antd";
import React from "react";
import { DictionaryContext } from "../../../contexts/DictionaryContext";
import { readFromCommonDictionary } from "../../../services/utils";
import { LayoutContext } from "../../../contexts/LayoutContext";
import { colors } from "../../../assets/styles/colors";
import { boxShadow } from "../../mapbox/constants";

const Tooltip: React.FC<{
  activeKey: string;
  unit: string;
  decimals?: number;
  payload: any;
  label: string;
}> = ({ activeKey, unit, decimals, payload, label }) => {
  const dictionary = React.useContext(DictionaryContext);
  const { isDarkMode, colorMapping } = React.useContext(LayoutContext);

  if (!payload?.[0]?.payload || activeKey === "background") {
    return <></>;
  }

  const data = payload?.[0];

  return (
    <div
      style={{
        backgroundColor: isDarkMode ? colors.darkMode : colors.lightMode,
        opacity: 1,
        width: 170,
        borderRadius: 10,
        margin: 0,
        padding: 5,
        zIndex: 1000,
        pointerEvents: "none",
      }}
      className="shadow-lg"
    >
      <Space direction="vertical">
        <Tag color="#000000">{data?.payload?.name}</Tag>
        <Space>
          <div
            style={{
              backgroundColor:
                colorMapping[data?.payload?.name]?.gradient[0],
              width: 10,
              height: 10,
              borderRadius: 10,
            }}
          />
          <Typography.Text>
            {readFromCommonDictionary(dictionary, activeKey)
              .charAt(0)
              .toUpperCase() +
              readFromCommonDictionary(dictionary, activeKey).slice(1)}
          </Typography.Text>
        </Space>
      </Space>
      <Divider style={{ marginTop: 10, marginBottom: 5 }}></Divider>
      <Space>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {data?.payload?.[activeKey]
            ? data?.payload?.[activeKey].toFixed(decimals ?? 2)
            : "-"}
        </Typography.Text>
        <Typography.Text>{unit}</Typography.Text>
      </Space>
    </div>
  );
};

export default Tooltip;
