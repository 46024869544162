import MapboxDraw, { DrawCreateEvent, DrawDeleteEvent, DrawModeChangeEvent, DrawUpdateEvent } from "@mapbox/mapbox-gl-draw";
import { forwardRef, useImperativeHandle } from "react";
import { useControl } from "react-map-gl";

import type { MapRef, ControlPosition } from "react-map-gl";

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onModeChange: (evt: DrawModeChangeEvent) => void;
  onDrawCreate: (evt: DrawCreateEvent) => void;
};

const DrawControl = forwardRef((props: DrawControlProps, ref) => {
  var features: any;

  const drawRef = useControl<MapboxDraw>(
    () =>
      new MapboxDraw({
        ...props,
        displayControlsDefault: false, // Set the displayControlsDefault option to false
        defaultMode: "simple_select",
      }),
    ({ map }: any) => {
      map.on("draw.create", (event: any) => {        
        // If there is a previous feature, delete it
        if (features) {
          try {
            drawRef.delete(features);
          } catch {}
        }

        features = event.features[0].id;
        props?.onDrawCreate(event);
      });
      map.on("draw.modechange", (event: any) => {
        props.onModeChange(event);
        features = undefined;
      });
    },
    ({ map }: any) => {
      map.off("draw.modechange", props.onModeChange);
    },
    {
      position: props.position,
    }
  );

  useImperativeHandle(ref, () => drawRef, [drawRef]);

  return null;
});

export default DrawControl;