
import { isArray } from "lodash";
import { IAnalysis } from "../../../../../../models/exploreV2/analysis";
import { useContext, useEffect, useState } from "react";
import { EntityContext } from "../../../../../../contexts/EntityContext";
import { DictionaryContext } from "../../../../../../contexts/DictionaryContext";
import { useAuth } from "react-oidc-context";
import { useForm } from "antd/es/form/Form";
import { useSaveAnalysis, useUpdateAnalysis } from "../../../../../../services/api/exploration/hooks";
import { readFromCommonDictionary, userId } from "../../../../../../services/utils";
import { Checkbox, Form, Input, Modal, Space, Typography } from "antd";
import { colors } from "../../../../../../assets/styles/colors";
import { sectorsIconsMapping } from "../../Sidebar";

interface AnalysisEditorModalProps {
  isCreation: boolean;
  isOpen: boolean;
  onClose: () => void;
  analysis: IAnalysis | undefined;
}

const AnalysisEditorModal: React.FC<AnalysisEditorModalProps> = (props) => {
  const { isCreation, isOpen, onClose, analysis } = props;
  const { activeEntity } = useContext(EntityContext);
  const dictionary = useContext(DictionaryContext);
  const auth = useAuth();

  const title = isCreation ? "Ajout d'une configuraiton d'analyse aux favoris" : "Modification d'une configuration d'analyse";

  const [form] = useForm();
  const [isShared, setIsShared] = useState(form.getFieldValue("isShared"));

  useEffect(() => {
    form.setFieldsValue({
      name: analysis?.name,
      description: analysis?.description,
      isShared: analysis?.isShared,
    });
  }, [analysis]);

  const { mutateAsync: saveAnalysis, isLoading: isLoadingSave } = useSaveAnalysis(activeEntity.id);

  const { mutateAsync: updateAnalysis, isLoading: isLoadingUpdate } = useUpdateAnalysis(
    activeEntity.id,
    analysis?.id ?? ""
  );

  const onOk = async () => {
    try {
      await form.validateFields();

      if (isCreation) {
        await saveAnalysis({
          ...form.getFieldsValue(),
          entityId: activeEntity.id,
          authorId: userId(auth),
          configuration: analysis?.configuration,
        });
      } else {
        await updateAnalysis({
          ...form.getFieldsValue(),
          entityId: activeEntity.id,
          authorId: userId(auth),
          configuration: analysis?.configuration,
        });
      }
      onClose();
    } catch (e) {
      console.log(e)
    }
  }
  
  if (!analysis) return <></>;

  return (
    <Modal
      open={isOpen}
      title={title}
      okText="Enregistrer"
      cancelText="Annuler"
      onCancel={onClose}
      width={"40%"}
      forceRender={true}
      destroyOnClose={true}
      confirmLoading={isLoadingSave || isLoadingUpdate}
      onOk={onOk}
    >
      <Space style={{ marginBottom: 10, width: "100%" }} direction="vertical">
        <Typography.Text style={{ color: colors.greyText }}>
          Sauvegardez la configuration de votre analyse et retrouvez-la dans vos favoris lors de vos prochaines utilisations.
        </Typography.Text>
        <Form
          layout="vertical"
          form={form}
          name="analysis-editor-form"
        >
          <Form.Item
            name={"name"}
            label="Titre"
            hasFeedback={true}
            rules={[{ required: true, min: 3 }]}
          >
            <Input
              style={{ height: 40 }}
              autoFocus={true}
              placeholder="Titre de l'analyse"
              onChange={(e) => form.setFieldsValue({ name: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            name={"description"}
            label="Description"
            hasFeedback={true}
          >
            <Input
              autoFocus={true}
              style={{ height: 40 }}
              placeholder="Description de l'analyse"
              onChange={(e) => form.setFieldsValue({ description: e.target.value })}
            />
          </Form.Item>
          <Space
            style={{
              backgroundColor: "#E6F6ED",
              width: "100%",
              borderRadius: 4,
              padding: 20,
              marginTop: 10,
              marginBottom: 10,
            }}
            direction="vertical"
          >
            <Space>
              <Typography.Text strong>Secteur analysé :</Typography.Text>
              {!!analysis.configuration.sector && (
                <>
                  {sectorsIconsMapping[analysis.configuration.sector] && sectorsIconsMapping[analysis.configuration.sector]()}
                  <Typography.Text style={{ color: colors.secondary }}>
                    {readFromCommonDictionary(
                      dictionary,
                      analysis.configuration.sector
                    ) ?? "Aucun"}
                  </Typography.Text>
                </>
              )}
            </Space>
            <Space>
              <Typography.Text strong>Activité analysée :</Typography.Text>
              {!!analysis.configuration.subsector && (
                <>
                  {sectorsIconsMapping[analysis.configuration.subsector] && sectorsIconsMapping[analysis.configuration.subsector]()}
                  <Typography.Text style={{ color: colors.secondary }}>
                    {readFromCommonDictionary(
                      dictionary,
                      analysis.configuration.subsector
                    ) ?? "Aucun"}
                  </Typography.Text>
                </>
              )}
            </Space>
            <Typography.Text strong>Liste des filtres :</Typography.Text>
            {isArray(analysis.configuration?.filters) && analysis.configuration?.filters?.map((f) => {
              return (
                <Space>
                  <Typography.Text>{f.name}</Typography.Text>
                </Space>
              );
            })}
          </Space>
          <Form.Item
            name="isShared"
            valuePropName="isShared"
            style={{ marginTop: 10 }}
          >
            <Checkbox
              checked={isShared}
              onChange={(e) => {
                form.setFieldsValue({ isShared: e.target.checked });
                setIsShared(e.target.checked);
              }}
            >
              Partager cette analyse avec mon entité
            </Checkbox>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  )
}

export default AnalysisEditorModal;