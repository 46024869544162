import { useContext, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Button, Divider, Image, Modal, Typography } from "antd";
import HEART from "../../../../../../assets/icons/heart.png";
import CHEVRON_RIGHT from "../../../../../../assets/icons/chevron_right.png";
import EDIT from "../../../../../../assets/icons/edit.png";
import TRASH from "../../../../../../assets/icons/trash.png";
import AnalysisEditorModal from "./AnalysisEditorModal";
import { IAnalysis } from "../../../../../../models/exploreV2/analysis";
import { MapStateContext, MapStateDispatch } from "../../../../contexts/mapStateContext";
import { useAnalyses, useDeleteAnalysis } from "../../../../../../services/api/exploration/hooks";
import { colors } from "../../../../../../assets/styles/colors";
import InfoTooltip from "../../../../../../components/common/InfoTooltip";
import { EntityContext } from "../../../../../../contexts/EntityContext";
import { userId } from "../../../../../../services/utils";
import MapContext from "../../../../contexts/mapContext";
import { PlayCircleFilled, PlaySquareFilled } from "@ant-design/icons";
import { BiPlay } from "react-icons/bi";

interface AnalysisProps {
  analysis: IAnalysis;
  entityId: string;
  disableEdition?: boolean;
  zoomThreshold: number;
}

const Analysis: React.FC<AnalysisProps> = ({ analysis, entityId, disableEdition, zoomThreshold }) => {
  const dispatch = useContext(MapStateDispatch);
  const { map } = useContext(MapContext);
  const { selectedFilters } = useContext(MapStateContext);

  const [isSaveModalVisible, setIsSaveModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

  const { mutateAsync: deleteAnalysis } = useDeleteAnalysis(
    entityId,
    analysis?.id ?? ""
  );

  const onApplyAnalysis = () => {
    dispatch({
      type: "APPLY_ANALYSIS",
      sector: analysis.configuration.sector,
      subsector: analysis.configuration.subsector,
      filters: analysis.configuration.filters,
    });
    // Zoom to building if needed
    const zoom = map?.getZoom();
    const shouldZoom = selectedFilters?.length && selectedFilters.length > 0 && selectedFilters.every(f => f.tier === 3);
    if (shouldZoom && zoom < zoomThreshold) {
      map?.easeTo({
        zoom: zoomThreshold
      });
    }
  }

  return (
    <>
      <>
        <div className="flex flex-col justify-between my-2">
          <div className="flex flex-row w-1/2 items-center">
            <div style={{ color: colors.greyText, marginRight: 10 }}>
              {analysis.name}
            </div>
            <InfoTooltip content={analysis.description}></InfoTooltip>
          </div>
          <div className="flex flex-row">
            <Button
              type="primary"
              onClick={onApplyAnalysis}
              style={{ height: 32, width: 32 }}
              className="flex items-center justify-center"
            >
              <BiPlay size={32} />
            </Button>
            {!disableEdition && (
              <Button
                type="text"
                icon={<Image src={EDIT} preview={false} width={19} />}
                onClick={() => setIsSaveModalVisible(true)}
              />
            )}
            {!disableEdition && (
              <Button
                type="text"
                danger
                icon={<Image src={TRASH} preview={false} width={19} />}
                onClick={() => setIsDeleteModalVisible(true)}
              />
            )}
          </div>
          <AnalysisEditorModal
            key="analysis-editor-modal-update"
            analysis={analysis}
            isOpen={isSaveModalVisible}
            isCreation={false}
            onClose={() => setIsSaveModalVisible(false)}
          />
        </div>
        <Divider className="m-0"/>
      </>
      <Modal
        key={analysis.id}
        title="Suppression d’une configuration d’analyse des favoris"
        open={isDeleteModalVisible}
        onOk={() => {
          deleteAnalysis();
          setIsDeleteModalVisible(false);
        }}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <Typography.Text style={{ color: colors.greyText }}>
          En supprimant la configuration d’analyse des favoris vous ne pourrez pas la retrouver lors de vos prochaines utilisations. Idem pour les utilisateurs de votre entité si elle est partagée.
        </Typography.Text>
      </Modal>
    </>
  );
};

interface PersonalSpaceProps {
  zoomThreshold: number;
}

const PersonalSpace: React.FC<PersonalSpaceProps> = (props: PersonalSpaceProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { activeEntity } = useContext(EntityContext);
  const { data: analyses } = useAnalyses(activeEntity.id);
  const auth = useAuth();

  const personnalAnalyses = analyses.filter(
    (a: IAnalysis) => a.authorId === userId(auth)
  );

  const entityAnalyses = analyses.filter(
    (a: IAnalysis) =>
      a.entityId === activeEntity.id && a.authorId != userId(auth) && a.isShared
  );

  return (
    <div>
      <div className="bg-black text-white h-[40px] p-5 flex  items-center justify-between" onClick={() => setIsCollapsed(!isCollapsed)}>
        <div className="flex-row flex">
          <Image src={HEART} style={{ width: 24 }} preview={false} />
          <div className="mx-2">Favoris</div>
        </div>

        <Image
          src={CHEVRON_RIGHT}
          style={{ width: 24, transform: isCollapsed ? "" : "rotate(90deg)" }}
          preview={false}
        // onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </div>
      {!isCollapsed && (
        <div className="px-4 my-3">
          <div className="text-base font-semibold ">Mes analyses</div>
          {personnalAnalyses.length > 0 ? (
            personnalAnalyses.map((analysis: IAnalysis) => (
              <Analysis
                entityId={activeEntity.id}
                analysis={analysis}
                zoomThreshold={props.zoomThreshold}
              />
            ))
          ) : (
            <Typography.Text>Vous n'avez aucune analyse</Typography.Text>
          )}
          <div className="text-base font-semibold my-3">
            Analyses partagées avec moi
          </div>
          {entityAnalyses.length > 0 && activeEntity.isPremium ? (
            entityAnalyses.map((analysis: IAnalysis) => (
              <Analysis
                entityId={activeEntity.id}
                analysis={analysis}
                disableEdition
                zoomThreshold={props.zoomThreshold}
              />
            ))
          ) : (
            <Typography.Text>Aucune analyse disponible</Typography.Text>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonalSpace;
