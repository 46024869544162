import { colorPalette } from "../../../../../../assets/styles/colors";

/**
 * Extract data keys from an element of data
 * e.g. for "energy_consumption_mwh" field and data = { "energy_consumption_mwh_xxx" : 1, "energy_consumption_mwh_yyy": 2 }
 * extracts ["energy_consumption_mwh_xxx", "energy_consumption_mwh_yyy"] from data
 * @param field 
 * @param dataElement
 */
function getDataRendering(field: string, dataElement: Record<string, number | string>) {
  const keys = Object.keys(dataElement ?? {})
    .filter(k => (k !== field) && k.includes(field));

  const usages = keys.map(k => {
    const index = k.indexOf(field);
    const usage = k.slice(index + field.length + 1);

    return usage;
  });

  // TODO : better handling of colors
  const dataRendering = usages.map((usage, index) => {
    return {
      label: usage,
      key: `${field}_${usage}`,
      strokeColor: colorPalette[index % colorPalette.length].gradient[0],
      fillColor: colorPalette[index % colorPalette.length].gradient[1]
    }
  });

  return {
    keys,
    dataRendering
  };
}

export { getDataRendering };