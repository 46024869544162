import { SettingOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Button,
  Divider,
  Image,
  Layout,
  Menu,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { CarouselRef } from "antd/es/carousel";
import Sider from "antd/es/layout/Sider";
import Cookies from "js-cookie";
import React from "react";
import { useAuth } from "react-oidc-context";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as CartoExplorerIcon } from "../assets/icons/carto-explorer.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as Decision2030Icon } from "../assets/icons/decision-2030.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/log-out.svg";
import { ReactComponent as TutorialIcon } from "../assets/icons/tutoriel.svg";
import { ReactComponent as ResetIcon } from "../assets/icons/reset.svg";
import logoSvg from "../assets/images/logo_nexqt.png";
import { colors } from "../assets/styles/colors";

import {
  collapsedMenuWidth,
  localisationSelectorHeight,
  menuWidth,
} from "../assets/styles/commons";
import TutoModal from "../pages/TutoModal";
import SelectDS from "../components/common/designSystem/SelectDS";
import { boxShadow } from "../components/mapbox/constants";
import { EntityContext } from "../contexts/EntityContext";
import { LayoutContext } from "../contexts/LayoutContext";
import { IEntity } from "../models/general/entity";
import { LocalisationSelector } from "../components/common/LocalisationSelector";
import { routes } from "../routes/routes";
import { groupCodesForUser, isAdmin } from "../services/utils";

export const AppLayout = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = React.useState(routes.dashboard);
  const carouselRef: React.RefObject<CarouselRef> = React.createRef();
  const [isTutoModalOpen, setIsTutoModalOpen] = React.useState(false);
  const { isDarkMode } = React.useContext(LayoutContext);

  function fillIcons(key: string) {
    return selectedKey === key
      ? isDarkMode
        ? colors.white
        : colors.secondary
      : isDarkMode
      ? colors.lightMode
      : colors.darkMode;
  }

  const { entities, activeEntity, setActiveEntity } =
    React.useContext(EntityContext);
  const onLogout = () => {
    auth.signoutRedirect();
  };

  React.useEffect(() => {
    navigate(routes.dashboard);
  }, []);

  React.useEffect(() => {
    if (!auth.user?.profile.sub) {
      return;
    }

    const alreadyLoggedUsers = JSON.parse(Cookies.get("alreadyLogged") ?? "{}");
    if (alreadyLoggedUsers[auth.user?.profile.sub] !== "true") {
      const newAlreadyLoggedUsers = {
        ...alreadyLoggedUsers,
      };
      newAlreadyLoggedUsers[auth.user?.profile.sub] = "true";
      Cookies.set("alreadyLogged", JSON.stringify(newAlreadyLoggedUsers), {
        expires: 365,
      });
      setIsTutoModalOpen(true);
    }
  }, []);

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  const menuNavItems: MenuProps["items"] = [
    {
      label: (
        <Link
          style={{ fontWeight: 600, fontSize: 14, fontFamily: "Inter" }}
          to={routes.dashboard}
          onClick={() => setSelectedKey(routes.dashboard)}
        >
          Bulletin Carbone
        </Link>
      ),
      key: routes.dashboard,
      icon: (
        <DashboardIcon
          style={{
            height: "100%",
            fill: fillIcons(routes.dashboard),
          }}
        />
      ),
    },
    {
      label: (
        <Link
          style={{ fontWeight: 600, fontSize: 14, fontFamily: "Inter" }}
          to={routes.exploreAndTarget}
          onClick={() => setSelectedKey(routes.exploreAndTarget)}
        >
          Carto Explorer
        </Link>
      ),
      key: routes.exploreAndTarget,
      icon: (
        <CartoExplorerIcon
          style={{
            height: "100%",
            fill: fillIcons(routes.exploreAndTarget),
          }}
        />
      ),
    },
    {
      label: (
        <Link
          to={routes.whatIf}
          onClick={() => setSelectedKey(routes.whatIf)}
          style={{ fontWeight: 600, fontSize: 14, fontFamily: "Inter" }}
        >
          Décision 2030
          <Tag
            bordered={false}
            color="lime"
            style={{ marginLeft: 5, borderRadius: 10 }}
          >
            proto
          </Tag>
        </Link>
      ),
      key: routes.whatIf,
      icon: (
        <Decision2030Icon
          style={{
            height: "100%",
            fill: fillIcons(routes.whatIf),
          }}
        />
      ),
    },
  ];

  function toggleCollapsed() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={isCollapsed ? collapsedMenuWidth : menuWidth}
        theme="light"
        style={{
          boxShadow: boxShadow,
          overflow: "auto",
          height: "100vh",
          zIndex: 2,
          // position: "fixed",
        }}
        collapsible
        onCollapse={toggleCollapsed}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{
                padding: "16px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: isCollapsed ? collapsedMenuWidth : menuWidth - 100,
                }}
              >
                <Image
                  src={
                    activeEntity.logoUri !== "" ? activeEntity.logoUri : logoSvg
                  }
                  preview={false}
                />
              </div>
            </div>
            {entities.length > 1 && !isCollapsed && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SelectDS
                  value={activeEntity.id}
                  style={{ width: isCollapsed ? 50 : 200 }}
                  placeholder={"Choisissez l'entité à éditer'"}
                  onChange={(value) =>
                    setActiveEntity(
                      entities.find((e: IEntity) => e.id === value) as IEntity
                    )
                  }
                  notFoundContent={
                    <Typography.Text
                      style={{ fontStyle: "italic", color: colors.failure }}
                    >
                      Aucune entité disponible
                    </Typography.Text>
                  }
                >
                  {entities?.map((c: IEntity) => (
                    <Select.Option key={c.id}>{c.name}</Select.Option>
                  ))}
                </SelectDS>
              </div>
            )}
            <Divider style={{ margin: "12px 0" }} />
            <Menu
              mode="inline"
              defaultSelectedKeys={[routes.dashboard]}
              selectedKeys={[selectedKey]}
              style={{ borderRight: "none" }}
              items={menuNavItems}
            ></Menu>
          </div>
          <div>
            <Menu
              mode="inline"
              style={{ borderRight: "none" }}
              selectedKeys={[selectedKey]}
            >
              {isAdmin(auth) && (
                <Menu.Item
                  icon={
                    <SettingOutlined style={{ fontSize: 22, height: "100%" }} />
                  }
                  key={routes.admin}
                >
                  <Link
                    to={routes.admin}
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                      fontFamily: "Inter",
                    }}
                    onClick={() => setSelectedKey(routes.admin)}
                  >
                    Espace administrateur
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item
                key={"mailTo"}
                style={{ fontWeight: 600, fontSize: 14, fontFamily: "Inter" }}
                icon={
                  <ResetIcon style={{ fill: colors.black, height: "100%" }} />
                }
                onClick={() => {
                  // // Vos autres actions ici
                  // setSelectedKey(routes.email);

                  const email = "helpdesk@nexqt.com";
                  const subject = "Retours bêta-testeurs";
                  const body = "Bonjour, voici mes retours...";
                  const mailtoLink = document.createElement("a");
                  mailtoLink.href = `mailto:${email}?subject=${encodeURIComponent(
                    subject
                  )}&body=${encodeURIComponent(body)}`;
                  mailtoLink.style.display = "none";
                  document.body.appendChild(mailtoLink);
                  mailtoLink.click();
                  document.body.removeChild(mailtoLink);
                }}
              >
                Retours bêta-testeurs
              </Menu.Item>
              <Menu.Item
                key={routes.tutorial}
                style={{ fontWeight: 600, fontSize: 14, fontFamily: "Inter" }}
                icon={
                  <TutorialIcon
                    style={{ fill: fillIcons(routes.tutorial), height: "100%" }}
                  />
                }
                onClick={() => {
                  setSelectedKey(routes.tutorial);
                  setIsTutoModalOpen(true);
                  carouselRef.current?.goTo(0);
                }}
              >
                Tutoriel
              </Menu.Item>
              {isCollapsed ? (
                <Button
                  style={{ marginLeft: 30, border: "none" }}
                  onClick={() => onLogout()}
                  icon={
                    <LogoutIcon
                      style={{ fill: fillIcons(routes.login) }}
                      fontSize={14}
                    />
                  }
                />
              ) : (
                <Button
                  onClick={() => onLogout()}
                  type="text"
                  style={{
                    marginLeft: 24,
                    paddingLeft: 20,
                    width: 200,
                    height: 40,
                    borderWidth: 1,
                    borderColor: isDarkMode
                      ? colors.lightMode
                      : colors.darkGrey,
                  }}
                >
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography.Text style={{ fontFamily: "Inter" }}>
                      Se déconnecter
                    </Typography.Text>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <LogoutIcon
                        style={{
                          fill: fillIcons(routes.login),
                          height: "100%",
                        }}
                      />
                    </div>
                  </Space>
                </Button>
              )}
            </Menu>
          </div>
        </div>
      </Sider>
      <Layout
        style={{
          backgroundColor: isDarkMode ? colors.darkMode : colors.lightMode,
          position: "relative",
          height: `100vh`,
        }}
      >
        <div
          style={{
            padding: 40,
            height: localisationSelectorHeight,
            alignItems: "center",
            display: "flex",
            width: "100%",
            backgroundColor: isDarkMode ? colors.darkGrey : colors.lightMode,
            boxShadow: boxShadow,
            zIndex: 1,
          }}
        >
          <LocalisationSelector />
        </div>
        <div
          style={{
            backgroundColor: isDarkMode ? colors.darkMode : colors.darkWhite,
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <Outlet />
        </div>
      </Layout>
      <TutoModal
        carouselRef={carouselRef}
        isOpen={isTutoModalOpen}
        setIsOpen={setIsTutoModalOpen}
      />
    </Layout>
  );
};
