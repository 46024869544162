import React, { Suspense, useContext } from "react";
import { useMean, useSum } from "../../../../services/dataApi/hooks";
import withSuspenseAndFallback from "../../../../components/hoc/withSuspenseAndFallback";
import SkeletonLoader from "../common/SkeletonLoader";
import { smartUnits } from "../../../../services/utils";
import { DictionaryContext } from "../../../../contexts/DictionaryContext";
import { PerimeterContext } from "../../../../contexts/PerimeterContext";
import { MapStateContext } from "../../contexts/mapStateContext";
import ErrorBoundaryWithFallback from "../../../../components/common/ErrorBoundaryWithFallback";
import { Button, Empty } from "antd";
import { IExplorationConfiguration } from "../../../../models/exploreV2/configuration";
import { filtersToAPIFilters, getStatisticsFieldsFromConf } from "./utils";
import { CloseOutlined } from "@ant-design/icons";
import { EntityContext } from "../../../../contexts/EntityContext";

const GREEN = "#00A947";
const LIGHT_GREEN = "#E6F6ED";

const AnalysisStatisticsLoader: React.FC = () => {
  return (
    <SkeletonLoader />
  )
}

interface AnalysisStatisticsProps {
  config: IExplorationConfiguration;
  onClose: () => void;
}

// TODO : add smart units
const AnalysisStatistics: React.FC<AnalysisStatisticsProps> = (props) => {
  const { config } = props;

  const { relevantCityCodes } = useContext(PerimeterContext);
  const dictionary = useContext(DictionaryContext);
  const { selectedSector, selectedSubsector, activeFilters, currentTier: tier } = useContext(MapStateContext);
  const { activeEntity } = useContext(EntityContext);
  // const tier = 3;

  const { numericFilters, otherFilters } = filtersToAPIFilters(activeFilters, selectedSubsector, tier);

  const { fieldMean, fieldSum } = getStatisticsFieldsFromConf(config, selectedSector, tier);

  const sectorArg = selectedSector === 'all' ? undefined : selectedSector;
  const cumulatedSectors = selectedSector === 'all' ? true : false;

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;

  const { data: sumRes } = useSum({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: sectorArg,
    subsector: selectedSubsector !== "all" ? selectedSubsector : undefined,
    startDate: startDate,
    endDate: endDate,
    tier,
    field: fieldSum,
    cumulatedSectors,
    cities: relevantCityCodes,
    numericFilters,
    otherFilters,
  });

  const { data: sumTotalRes } = useSum({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: sectorArg,
    subsector: undefined,
    startDate: startDate,
    endDate: endDate,
    tier,
    field: fieldSum,
    cumulatedSectors,
    cities: relevantCityCodes,
  });

  const { data: meanRes } = useMean({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: sectorArg,
    subsector: selectedSubsector !== "all" ? selectedSubsector : undefined,
    startDate: startDate,
    endDate: endDate,
    tier,
    field: fieldMean,
    cumulatedSectors,
    cities: relevantCityCodes,
    numericFilters: numericFilters,
    otherFilters: otherFilters,
  });


  const sum = sumRes?.[0]?.[fieldSum] ?? sumRes?.[fieldSum];
  const sumTotal = sumTotalRes?.[0]?.[fieldSum] ?? sumTotalRes?.[fieldSum];
  const mean = meanRes?.[0]?.[fieldMean] ?? meanRes?.[fieldMean];

  const sumSmart = smartUnits(sum, fieldSum, dictionary.features).split(' ');
  const sumValue = sumSmart[0];
  const sumUnit = sumSmart[1];

  const meanSmart = smartUnits(mean, fieldMean, dictionary.features).split(' ');
  const meanValue = meanSmart[0];
  const meanUnit = meanSmart[1];

  return (
    <>
      {/* <div className="flex flex-row justify-between items-center">
        <h1 style={{ fontSize: 22, marginBottom: 10, color: 'white' }}>Résultat Analyse</h1>
        <Button type="text" icon={<CloseOutlined />} />
      </div> */}
      <p style={{ color: 'white', fontSize: 16 }}><span style={{ color: 'white', fontWeight: 800, fontSize: 30 }}>{sumValue}</span> {sumUnit}</p>
      <p style={{ color: 'white', fontSize: 16 }}>pour <span style={{ color: 'white', fontWeight: 800, fontSize: 30 }}>{(100 * sum / sumTotal).toFixed(2)}%</span> du secteur</p>
      <p></p>
      {/* <p style={{ color: GREEN, fontSize: 22 }}><span style={{ color: GREEN, fontWeight: 800, fontSize: 48 }}>{sumValue}</span> {sumUnit}/an</p> */}
      <div className="flex items-center">
        <p className="text-[16px] mr-2 text-white">Moyenne :</p>
        <div className="items-center justify-center rounded-lg p-2" style={{ backgroundColor: 'white', color: GREEN }}><span style={{ color: GREEN, fontWeight: 800 }}>{meanValue}</span>{' '}{meanUnit}/an</div>
      </div>
    </>
  );
}

const AnalysisStatisticsWrapper: React.FC<AnalysisStatisticsProps> = (props) => {
  return (
    <div>
      <h1 style={{ fontSize: 22, marginBottom: 10, color: 'white' }}>Résultat Analyse</h1>
      <Button onClick={props.onClose} type="text" icon={<CloseOutlined style={{ color: 'white'}} />} />
      <AnalysisStatistics {...props} />
    </div>
  )
}

const AnalysisStatisticsWithSuspense = (props: AnalysisStatisticsProps) => {
  return (
    <div className={`p-4 w-[310px] h-[200px] flex flex-col justify-between bg-[#00A947] rounded-lg relative`}>
      <div className="flex flex-row justify-between items-center">
        <h1 style={{ fontSize: 22, marginBottom: 10, color: 'white' }}>Résultat Analyse</h1>
      <Button onClick={props.onClose} type="text" style={{ position: "relative", top: 0 }} icon={<CloseOutlined style={{ color: 'white'}} />} />
      </div>
      <Suspense fallback={<AnalysisStatisticsLoader />}>
        <ErrorBoundaryWithFallback fallback={<Empty />}>
          <AnalysisStatistics {...props} />
        </ErrorBoundaryWithFallback>
      </Suspense>
    </div>
  )
};

export default AnalysisStatisticsWithSuspense;