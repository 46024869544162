import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { User } from 'oidc-client-ts';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

global.Buffer = global.Buffer || require("buffer").Buffer;

const oicdConfig = {
  authority: process.env.REACT_APP_KEYCLOAK_AUTH_URL as string,
  redirect_uri: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_REDIRECT_URL as string,
  post_logout_redirect_uri: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_REDIRECT_URL as string,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  onSigninCallback: (user: void | User) => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  },
  automaticSilentRenew: true,
  loadUserInfo: true
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AuthProvider {...oicdConfig}>
    <App />
  </AuthProvider>
);

reportWebVitals();
