import { Space, Typography } from "antd";
import React from "react";
import { colors } from "../../assets/styles/colors";
import { LayoutContext } from "../../contexts/LayoutContext";
import InfoTooltip from "./InfoTooltip";

const EllipsisIconTitle: React.FC<{
  title?: string;
  icon?: React.ReactElement;
  infoTitle?: string;
  infoContent?: string | React.ReactElement;
}> = ({ title, icon, infoTitle, infoContent }) => {
  const { isDarkMode } = React.useContext(LayoutContext);
  return (
    <Space>
      {/* <Tooltip title={title}> */}
      <div
        style={{
          flexDirection: "row",
          display: "flex",
        }}
      >
        {icon && <div style={{ minWidth: 20, marginRight: 10 }}>{icon}</div>}
        <Typography.Text
          strong
          ellipsis
          style={{
            fontWeight: 500,
            fontSize: 14,
            color: isDarkMode ? colors.white : colors.dashboardGrey,
            textWrap: "wrap", // TODO : keep that ?
          }}
        >
          {title}
        </Typography.Text>
      </div>
      {/* </Tooltip> */}
      <InfoTooltip title={infoTitle} content={infoContent} />
    </Space>
  );
};

export default EllipsisIconTitle;
