export const routes = {
    root: '/',
    login: '/login',
    dashboard: '/dashboard',
    admin: '/admin',
    exploreAndTarget: '/explore-and-target',
    whatIf: '/what-if',
    tutorial: '/tutorial',
    myReports: 'my-reports',
    myReport: 'my-reports/:id',
    demoMapbox: '/demo-mapbox',
};
