import { Suspense, useContext, useEffect, useState } from "react";
import LayerFilter from "../../../../../../components/filters/LayerFilterV2";
import { ICheckboxFilter, ICheckboxFilterConfiguration, IDropdownFilter, IDropdownFilterConfiguration, IFilterConfiguration, ILayerFilter, ILayerFilterBase, INumericFilter, INumericFilterConfiguration } from "../../../../../../models/exploreV2/filters";
import { useFilterDistribution, useFilterOptions } from "../../../../../../services/dataApi/hooks";
import { MapStateContext, MapStateDispatch } from "../../../../contexts/mapStateContext";
import { ErrorBoundary } from "react-error-boundary";
import { getDistributionFromQuantiles } from "./utils";
import { PerimeterContext } from "../../../../../../contexts/PerimeterContext";
import { EntityContext } from "../../../../../../contexts/EntityContext";

interface NumericFilterImplementationProps {
  distribution: number[];
  filter: ILayerFilterBase & INumericFilter;
}

const NumericFilterImplementation: React.FC<NumericFilterImplementationProps> = (props) => {
  const dispatch = useContext(MapStateDispatch);

  const { distribution, filter: filterProps } = props;

  const [filter, setFilter] = useState<ILayerFilterBase & INumericFilter>(filterProps);

  useEffect(() => {
    dispatch({ type: 'ADD_FILTER', filter })

    return () => {
      dispatch({ type: 'REMOVE_FILTER', filterName: filter.name })
    };
  }, []);

  const onFilterChange = (filter: ILayerFilter) => {
    setFilter(filter as ILayerFilterBase & INumericFilter);
    dispatch({ type: 'UPDATE_FILTER', filter });
  }

  return <LayerFilter filter={filter} onFilterChange={onFilterChange} distribution={distribution} />
};

const NumericFilter: React.FC<ILayerFilterBase & INumericFilterConfiguration> = (props) => {
  const { selectedSector, currentTier } = useContext(MapStateContext);
  const { relevantCityCodes } = useContext(PerimeterContext);
  const { activeEntity } = useContext(EntityContext);

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;

  const { data } = useFilterDistribution({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: selectedSector,
    startDate: startDate,
    endDate: endDate,
    tier: props.tier ?? currentTier,
    field: props.field,
    freq: "year",
    binsNumber: 10,
    alpha: props.alpha,
    cities: relevantCityCodes,
  });


  const dataRes = data?.[0];
  if (!dataRes || dataRes.min === undefined || dataRes.max === undefined || !dataRes.distribution) return <></>;

  const min = Math.round(dataRes.min);
  const max = Math.round(dataRes.max);
  const distribution = dataRes.distribution;

  const filter: ILayerFilter = {
    ...props,
    value: props.value ? props.value : [min, max],
    min: min,
    max: max,
    type: 'numeric',
  }

  return <NumericFilterImplementation distribution={distribution} filter={filter} />
};


const CheckboxFilterImplementation: React.FC<ILayerFilterBase & ICheckboxFilter> = (props) => {
  const dispatch = useContext(MapStateDispatch);

  const [filter, setFilter] = useState<ILayerFilter>({
    ...props,
    value: props.value ?? [],
  });

  useEffect(() => {
    dispatch({ type: 'ADD_FILTER', filter })

    return () => {
      dispatch({ type: 'REMOVE_FILTER', filterName: filter.name })
    };
  }, []);

  const onFilterChange = (filter: ILayerFilter) => {
    setFilter(filter);
    dispatch({ type: 'UPDATE_FILTER', filter });
  }

  return (
    <LayerFilter filter={filter} onFilterChange={onFilterChange} />
  )
}

const DropdownFilterImplementation: React.FC<ILayerFilterBase & IDropdownFilter> = (props) => {
  const dispatch = useContext(MapStateDispatch);

  const [filter, setFilter] = useState<ILayerFilter>({
    ...props,
    value: props.value ?? props.options[0],
  });

  useEffect(() => {
    dispatch({ type: 'ADD_FILTER', filter })

    return () => {
      dispatch({ type: 'REMOVE_FILTER', filterName: filter.name })
    };
  }, []);

  const onFilterChange = (filter: ILayerFilter) => {
    setFilter(filter);
    dispatch({ type: 'UPDATE_FILTER', filter });
  }

  return (
    <LayerFilter filter={filter} onFilterChange={onFilterChange} />
  )
};

const OptionsFiltersWithAPI: React.FC<ILayerFilterBase & (ICheckboxFilterConfiguration | IDropdownFilterConfiguration)> = (props) => {
  const { type } = props;
  const { selectedSector, selectedSubsector, currentTier } = useContext(MapStateContext);
  const { relevantCityCodes } = useContext(PerimeterContext);
  const { activeEntity } = useContext(EntityContext);

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;

  const { data } = useFilterOptions({
    instance: activeEntity.instance,
    batch: activeEntity.batch,
    sector: selectedSector,
    subsector: selectedSubsector,
    startDate: startDate,
    endDate: endDate,
    colGroupBy: props.field,
    cities: relevantCityCodes,
    tier: props.tier ?? currentTier,
  });

  const options = data?.map((d: any) => d[props.field]).filter((d: any) => d !== 0);

  if (!options) return <></>;

  switch (type) {
    case 'checkbox':
      return <CheckboxFilterImplementation {...props as ILayerFilterBase & ICheckboxFilter} options={options} labels={options} />;
    case 'dropdown':
      return <DropdownFilterImplementation {...props as ILayerFilterBase & IDropdownFilter} options={options} labels={options} />;
  }
};

const OptionsFilter: React.FC<ILayerFilterBase & (ICheckboxFilterConfiguration | IDropdownFilterConfiguration)> = (props) => {
  const { options, type } = props;

  if (!options || options.length === 0) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <ErrorBoundary fallbackRender={() => <div>erreur</div>}>
          <OptionsFiltersWithAPI {...props} />
        </ErrorBoundary>
      </Suspense>
    );
  }

  switch (type) {
    case 'checkbox':
      return <CheckboxFilterImplementation {...props as ILayerFilterBase & ICheckboxFilter} />;
    case 'dropdown':
      return <DropdownFilterImplementation {...props as ILayerFilterBase & IDropdownFilter} />;
  }
};

interface ExploreFilterProps {
  config: IFilterConfiguration;
}

const ExploreFilter: React.FC<ExploreFilterProps> = (props) => {
  const { config } = props;
  
  switch (config.type) {
    case 'checkbox':
    case 'dropdown':
      return <OptionsFilter key={config.name} {...config} />;
    case 'numeric':
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ErrorBoundary fallbackRender={() => <div>erreur</div>}>
            <NumericFilter {...config} />
          </ErrorBoundary>
        </Suspense>
      );
  }
};

export default ExploreFilter;
