import { InputNumber, Slider, Typography } from "antd";
import React, { useState } from "react";

interface BackMarketBarsProps {
  distribution: number[];
  min: number;
  max: number;
  values: [number, number];
}

const BackMarketBars: React.FC<BackMarketBarsProps> = ({
  distribution,
  min,
  max,
  values,
}) => {
  const maxHeight = 60;
  const maxValue = Math.max(...distribution);

  const sliderCourse = max - min;
  const barWidthCoursePercentage = (1 / distribution.length) * 100;

  function computeColor(
    courseLeftPercentage: number,
    courseRightPercentage: number,
    barPositionPercentage: number
  ) {
    return barPositionPercentage < courseLeftPercentage ||
      barPositionPercentage > 100 - courseRightPercentage
      ? "#9BA5B7"
      : "#555555";
  }

  return (
    <div
      className="flex flex-row w-full justify-evenly items-end mt-1"
      style={{ height: maxHeight }}
    >
      {distribution.map((value: number, index: number) => (
        <div
          className={`flex m-1 w-full bg-slate-400 rounded-t`}
          style={{
            height: (value / maxValue) * maxHeight,
            backgroundColor: computeColor(
              ((values[0] - min) / sliderCourse) * 100,
              ((max - values[1]) / sliderCourse) * 100,
              index * barWidthCoursePercentage
            ),
          }}
        />
      ))}
    </div>
  );
};

interface FilterSliderProps {
  min: number;
  max: number;
  step: number;
  onChange: (value: [number, number]) => void;
  value: [number, number];
  distribution?: number[];
}

function countIntegerDigits(num: number): number {
  // will be used to add spacing depending on number size
  const str = Math.abs(num).toString();
  const decimalIndex = str.indexOf(".");
  if (decimalIndex === -1) {
    if (str.length < 4) {
      return 0;
    }
    return str.length;
  } else if (decimalIndex < 4) {
    return 0; // if number of digits <4 no need to add space
  } else {
    return decimalIndex;
  }
}

const FilterSlider: React.FC<FilterSliderProps> = (props) => {
  const [values, setValues] = useState<[number, number]>(props.value);

  const onChange = (value: [number, number]) => {
    setValues(value);
  };

  const onChangeComplete = (value: [number, number]) => {
    props.onChange(value);
  };

  return (
    <div>
      {props.distribution && (
        <BackMarketBars
          distribution={props.distribution}
          min={props.min}
          max={props.max}
          values={values}
        />
      )}
      <Slider
        range
        onChange={onChange as any}
        onChangeComplete={onChangeComplete as any}
        min={props.min}
        max={props.max}
        defaultValue={[props.min, props.max]}
        value={values}
        step={props.step}
        tooltip={{
          placement: "bottom", // Positions the tooltip below the slider handle
          overlayInnerStyle: {
            // Custom styles for the tooltip content
            color: "#fff",
            backgroundColor: "#000",
          },
        }}
        marks={{
          [props.min]: {
            style: { left: "0%" },
            label: props.min,
          },
          [props.max]: {
            style: { left: `${100 - countIntegerDigits(props.max) * 1.5}%` },
            label: props.max,
          },
        }}
      />
      <div className="flex flex-row items-center justify-between space-x-2">
        <InputNumber
          min={props.min}
          max={props.max}
          onChange={(v: null | number) => {
            if (v != null && v > props.min && v < values[1]) {
              setValues([v, values[1]]);
              props.onChange([v, values[1]]);
            }
          }}
          value={values[0]}
          className="w-20"
        />
        <Typography.Text>{` à `}</Typography.Text>
        <InputNumber
          min={props.min}
          max={props.max}
          onChange={(v: null | number) => {
            if (v != null && v < props.max && v > values[0]) {
              setValues([values[0], v]);
              props.onChange([values[0], v]);
            }
          }}
          value={values[1]}
          className="w-20"
        />
      </div>
    </div>
  );
};

export default FilterSlider;
