import React, { useContext, useEffect } from "react";
import { FillExtrusionLayer, FillLayer, LineLayer, Layer as MapboxLayer, Marker, Source, useMap } from "react-map-gl";
import { PerimeterContext } from "../../contexts/PerimeterContext";
import { propsFromLayer } from "./utils";
import { generateStyling } from "../../services/mapbox/styling";
import { MapStateContext, MapStateDispatch } from "../../pages/explore-target/contexts/mapStateContext";
import { EntityContext } from "../../contexts/EntityContext";
import useLegendStops from "../../pages/explore-target/hooks/useLegendStops";
import { LAYER_COLORS } from "../../pages/explore-target/consts";
import { generateLayerFilter } from "./filters";
import { DoubleRightOutlined } from "@ant-design/icons";

interface LayerProps {
  layer: any; // TODO : update
  isVisible: boolean;
  zoomThreshold: number;
}

const LayerV2: React.FC<LayerProps> = (props) => {
  const { layer, isVisible, zoomThreshold } = props;
  const { relevantCityCodes } = useContext(PerimeterContext);

  const { selectedSector, currentTier, activeFilters, selectedFilters, analysisFilters, selectedFeatures } = useContext(MapStateContext);
  const mapStateDispatch = useContext(MapStateDispatch);

  const stops = useLegendStops(layer.emittingSector, layer.legendField, layer.tier, relevantCityCodes);

  const paint = generateStyling(layer.type, layer.field, stops, LAYER_COLORS);

  const relevantFilters = activeFilters.filter(f => f.tier ? f.tier === layer.tier : true);
  const filter = generateLayerFilter(layer.field, relevantCityCodes, relevantFilters, selectedSector);

  const stopsWithColors: [number, string][] = stops.map((s, i) => [s, LAYER_COLORS[i]]);

  const layerProps: FillLayer | LineLayer | FillExtrusionLayer = {
    id: layer.tileset,
    type: layer.type,
    'source-layer': layer.tileset,
    paint,
    filter: filter,
    minzoom: layer.tier === 2 ? 0 : zoomThreshold,
    maxzoom: layer.tier === 2 ? zoomThreshold : 24,
    layout: {
      visibility: isVisible ? 'visible' : 'none',
      // ...layoutProps,
    },
  }

  // Buses line behind other lines
  if (layer.type === "line") {
    layerProps.layout = {
      ...layerProps.layout,
      'line-sort-key': ["case", ["==", ["get", "road_type"], "buses"], -1, 1],
    }
  }

  const layerProps2: FillLayer | LineLayer | FillExtrusionLayer = {
    id: `${layer.tileset}-line`,
    type: 'line',
    'source-layer': layer.tileset,
    paint: {
      'line-color': 'red',
      'line-width': [
        'case',
        ['==', ['feature-state', 'clicked'], true],
        4,
        0
      ],
    },
  }

  // Update legend for LayerLegend
  useEffect(() => {
    if (isVisible && layer.tier === currentTier) {
      mapStateDispatch({
        type: 'SET_ACTIVE_LEGEND',
        activeLegend: {
          field: layer.legendField,
          stops: stopsWithColors,
          tier: layer.tier
        }
      });
    }
  }, [isVisible, currentTier]);

  return (
    <>
      <Source
        id={`${layer.emittingSector}-${layer.tier}`}
        type="vector"
        url={`mapbox://nexqt-ds.${layer.tileset}`}
      >
        <MapboxLayer
          {...layerProps}
        />
        {layer.type === 'fill' &&
          <MapboxLayer
            {...layerProps2}
          />
        }
      </Source>
      {/* <Marker
        latitude={48.8}
        longitude={2.3}
      >
        <DoubleRightOutlined rotate={90} style={{ top: '-100px', fontSize: '30px', color: 'white', animation: 'slideDown 1s forwards infinite' }} />
      </Marker> */}
    </>
  );
};

export default LayerV2;