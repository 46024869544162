import { ILegend } from "../../../components/mapbox/LayerLegendV2";
import { IUploadLayer } from "../../../models/explore/layers/uploadLayer";
import { ILayerFilter } from "../../../models/exploreV2/filters";
import { MapState } from "../contexts/mapStateContext";

type MapStateAction = {
  type: 'SELECT_SECTOR';
  sector: string;
} | {
  type: 'SELECT_SUBSECTOR';
  subsector: string;
} | {
  type: 'SELECT_FEATURES';
  features: Record<string, string | number> | undefined;
  featuresType: 'layer'|'additional';
  featuresSourceLayer?: string;
  latLng?: { lat: number, lng: number };
} | {
  type: "TOGGLE_ADDITIONAL_LAYER_VISIBILITY";
  layerId: string;
} |
{
  type: 'TOGGLE_ADDITIONAL_LAYER';
  layerId: string;
} | {
  type: 'SET_ACTIVE_LEGEND';
  activeLegend: ILegend;
} | {
  type: 'SET_CURRENT_TIER';
  tier: number;
} | {
  type: 'ADD_FILTER';
  filter: ILayerFilter;
} | {
  type: 'REMOVE_FILTER';
  filterName: string;
} | {
  type: 'UPDATE_FILTER';
  filter: ILayerFilter;
} | {
  type: 'APPLY_FILTERS';
} | {
  type: 'RESET_FILTERS';
} | {
  type: 'APPLY_ANALYSIS';
  sector: string;
  subsector: string;
  filters: ILayerFilter[];
} | {
  type: 'RESET_ANALYSIS';
  filters: ILayerFilter[];
} | {
  type: 'ADD_UPLOAD_LAYER';
  layer: IUploadLayer;
} | {
  type: 'TOGGLE_UPLOAD_LAYER';
  layerId: string;
} | {
  type: 'SET_DRAW_CONTROL';
  drawControl: any;
} | {
  type: 'SET_IS_DRAWING';
  isDrawing: boolean;
} | {
  type: 'SET_DRAW_FEATURES';
  drawFeatures: any;
} | {
  type: 'SET_IS_EXPORTING';
  isExporting: boolean;
} | {
  type: 'SET_TRIGGER_EXPORT';
  triggerExport: boolean;
};

const mapStateReducer = (state: MapState, action: MapStateAction): MapState => {
  switch (action.type) {
    case 'SELECT_SECTOR':
      return {
        ...state,
        selectedSector: action.sector,
        selectedSubsector: 'all',
        selectedFeatures: undefined,
        activeFilters: [],
        selectedFilters: [],
        analysisFilters: undefined,
        isAnalysisApplied: false,
      };
    case 'SELECT_SUBSECTOR':
      return {
        ...state,
        selectedSector: state.selectedSector,
        selectedSubsector: action.subsector,
      };
    case 'SELECT_FEATURES':
      return {
        ...state,
        selectedFeatures: action.features,
        selectedFeaturesType: action.featuresType,
        selectedFeaturesSourceLayer: action.featuresSourceLayer,
        selectedLatLng: action.latLng,
      };
    case 'TOGGLE_ADDITIONAL_LAYER':
      if (state.additionalLayers?.includes(action.layerId)) {
        return {
          ...state,
          additionalLayers: state.additionalLayers.filter((id) => id !== action.layerId),
          visibleAdditionalLayers: state.visibleAdditionalLayers?.filter((id) => id !== action.layerId)
        };
      } else {
        return {
          ...state,
          additionalLayers: [...(state.additionalLayers || []), action.layerId],
        };
      }
    case 'TOGGLE_ADDITIONAL_LAYER_VISIBILITY':
      return {
        ...state,
        visibleAdditionalLayers: state.visibleAdditionalLayers?.includes(action.layerId)
          ? state.visibleAdditionalLayers.filter((id) => id !== action.layerId)
          : [...(state.visibleAdditionalLayers || []), action.layerId]
      };
    case 'SET_ACTIVE_LEGEND':
      return {
        ...state,
        activeLegend: action.activeLegend
      };
    case 'SET_CURRENT_TIER':
      return {
        ...state,
        currentTier: action.tier
      };
    case 'ADD_FILTER':
      return {
        ...state,
        selectedFilters: [...state.selectedFilters, action.filter]
      };
    case 'REMOVE_FILTER':
      return {
        ...state,
        selectedFilters: state.selectedFilters.filter((filter) => filter.name !== action.filterName),
        // activeFilters: state.activeFilters.filter((filter) => filter.name !== action.filterName)
      };
    case 'UPDATE_FILTER':
      return {
        ...state,
        selectedFilters: state.selectedFilters.map((filter) => filter.name === action.filter.name ? action.filter : filter)
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        analysisFilters: undefined,
        activeFilters: state.selectedFilters,
        isAnalysisApplied: true,
      };
    case 'RESET_FILTERS':
      return {
        ...state,
        analysisFilters: undefined,
        activeFilters: [],
        isAnalysisApplied: false
      };
    case 'APPLY_ANALYSIS':
      return {
        ...state,
        selectedFeatures: undefined,
        selectedSector: action.sector,
        selectedSubsector: action.subsector,
        analysisFilters: action.filters,
        selectedFilters: [],
        activeFilters: [],
        isAnalysisApplied: true,
      };
    case 'RESET_ANALYSIS':
      return {
        ...state,
        activeFilters: action.filters,
        analysisFilters: undefined,
      };
    case 'ADD_UPLOAD_LAYER':
      return {
        ...state,
        uploadLayers: [...(state.uploadLayers || []), action.layer],
      };
    case 'TOGGLE_UPLOAD_LAYER':
      return {
        ...state,
        visibleUploadLayers: state.visibleUploadLayers?.includes(action.layerId)
          ? state.visibleUploadLayers.filter((id) => id !== action.layerId)
          : [...(state.visibleUploadLayers || []), action.layerId]
      };
    case 'SET_DRAW_CONTROL':
      return {
        ...state,
        drawControl: action.drawControl
      };
    case 'SET_IS_DRAWING':
      return {
        ...state,
        isDrawing: action.isDrawing
      };
    case 'SET_DRAW_FEATURES':
      return {
        ...state,
        drawFeatures: action.drawFeatures
      };
    case 'SET_IS_EXPORTING':
      return {
        ...state,
        isExporting: action.isExporting
      };
    case 'SET_TRIGGER_EXPORT':
      return {
        ...state,
        triggerExport: action.triggerExport
      };
    default:
      return state;
  }
};

export { mapStateReducer };