import { Collapse, Typography } from "antd";
import LegendContainer from "../common/LegendContainer";
import { MapStateContext } from "../../pages/explore-target/contexts/mapStateContext";
import { useContext } from "react";
import Legend from "./Legend";
import useLegendStops from "../../pages/explore-target/hooks/useLegendStops";
import { featureFieldNameToDecimals, featureFieldNameToLabel, featureFieldNameToUnit, smartUnits } from "../../services/utils";
import { DictionaryContext } from "../../contexts/DictionaryContext";
import LegendV2 from "./LegendV2";

export interface ILegend {
  field: string;
  stops: [number, string][];
  tier: number;
}

interface LayerLegendProps {
}

// TODO : handle smart units ?
const LayerLegend: React.FC<LayerLegendProps> = (props) => {
  const dictionary = useContext(DictionaryContext)
  const { activeLegend } = useContext(MapStateContext);

  if (!activeLegend) return <></>

  const unit = "";

  const renderLegendKeys = (stop: [number, string], i: number) => {
    let stopInterval;

    if (i === 0) {
      stopInterval = ` < ${smartUnits(
        activeLegend.stops[i + 1][0],
        activeLegend.field ?? "",
        dictionary.features
      )} ${unit}`;
    } else if (i < activeLegend.stops.length - 1 && i > 0) {
      stopInterval = `${smartUnits(
        activeLegend.stops[i][0],
        activeLegend.field ?? "",
        dictionary.features
      )} - ${smartUnits(
        activeLegend.stops[i + 1][0],
        activeLegend.field ?? "",
        dictionary.features
      )} ${unit}`;
    } else {
      stopInterval = ` > ${smartUnits(
        stop[0],
        activeLegend.field ?? "",
        dictionary.features
      )} ${unit}`;
    }

    return (
      <div key={i}>
        <span
          className="mr6 round-full w12 h12 inline-block align-middle"
          style={{ backgroundColor: stop[1] }}
        ></span>
        <span>{stopInterval}</span>
      </div>
    );
  };

  return (
    <Collapse
      style={{ overflow: "hidden", minWidth: 100, backgroundColor: "white" }}
      onChange={(e) => console.log(e)}
      expandIconPosition="right"
      items={[
        {
          key: "key",
          label:
            <>
              {featureFieldNameToLabel(dictionary, activeLegend.field)}
              <p className="txt-s color-gray">
                {activeLegend.tier === 2 ? "(Maille quartier)" : "(Maille bâtiment)"}
              </p>
            </>,
          children: <div>{activeLegend.stops.map(renderLegendKeys)}</div>
        }
      ]}
      defaultActiveKey={"key"}
    >
    </Collapse>
  )
}

export default LayerLegend;