import { Space, Typography } from "antd";

const ErrorMessage: React.FC<{message:string}> = ({message}) => {
    return (
      <Space
        style={{
          justifyContent: "center",
          display: "flex",
          width:"100%",
          height:"100%",
          alignItems:"center"
        }}
      >
        <Typography.Text strong style={{ textAlign:"center" }}>
          {`🚨 ${message} 🚨`}
        </Typography.Text>
      </Space>
    )
  }
  
  export default ErrorMessage;