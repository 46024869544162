import { TCity } from "../../../models/general/city";
import { TCityGroup, TGroupType } from "../../../models/general/cityGroup";
import { IEntity } from "../../../models/general/entity";
import APIService, { APIResult, apiUrl } from "../common";
import {
  IDashboardConfiguration,
  TComponent,
} from "../../../models/dashboard/common";
import { IMapParameters } from "../../../models/general/map";
import { IDictionary } from "../../../models/general/dictionary";

export class EntityService extends APIService {
  private route = apiUrl + "/entities";

  fetchCityGroups(
    entityId: string,
    type: TGroupType,
    parentCodes?: string[],
    authorisedCodes?: string[]
  ): Promise<APIResult<TCityGroup[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${entityId}/groups`,
      { type, parentCodes, authorisedCodes }
    );
  }

  fetchMapParameters(cityCodes?: string[]): Promise<APIResult<IMapParameters>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/mapParameters`, {
      cityCode: cityCodes,
    });
  }

  fetchCities(
    entityId: string,
    groupCodes?: string[]
  ): Promise<APIResult<TCity[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${entityId}/cities`,
      { groupCodes }
    );
  }

  fetchDictionary(entityId: string): Promise<APIResult<IDictionary>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${entityId}/dictionary`
    );
  }

  updateDictionary(
    entityId: string,
    dictionaryId: string,
    dictionary: IDictionary
  ): Promise<APIResult<IDictionary>> {
    return this.makeAxiosJSONRequest<IDictionary>(
      "PUT",
      `${this.route}/${entityId}/dictionary/${dictionaryId}`,
      {},
      dictionary
    );
  }

  createEntity(entity: IEntity): Promise<APIResult<IEntity>> {
    return this.makeAxiosJSONRequest("POST", `${this.route}`, {}, entity);
  }

  updateEntity(entityId: string, entity: IEntity): Promise<APIResult<IEntity>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${entityId}`,
      {},
      entity
    );
  }

  fetchEntities(): Promise<APIResult<IEntity[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/`);
  }

  deleteEntity(entityId: string, userId: string): Promise<APIResult<IEntity>> {
    return this.makeAxiosJSONRequest(
      "DELETE",
      `${this.route}/${entityId}`,
      {},
      { userId }
    );
  }

  fetchDashboardComponents(
    entityId: string,
    filters?: string[]
  ): Promise<APIResult<TComponent[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${entityId}/dashboard/components`,
      { filters }
    );
  }

  fetchDashboardConfiguration(
    entityId: string
  ): Promise<APIResult<IDashboardConfiguration>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${entityId}/dashboard/configuration`
    );
  }
}
