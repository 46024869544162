import { CloseOutlined } from "@ant-design/icons";
import { Button, Divider, Typography } from "antd";
import React, { useContext } from "react";
import { CellDisplay } from "./id/ID";
import { MapStateContext } from "../../contexts/mapStateContext";
import ConfigContext from "../../contexts/configContext";

interface AdditionalFeaturesProps {}

const AdditionalFeatures: React.FC = (props) => {
  const mapState = useContext(MapStateContext);
  const config = useContext(ConfigContext);

  const fieldsConfig = config.additionalLayers.find(
    (f) => f.tileset === mapState.selectedFeaturesSourceLayer
  )?.fields;

  if (fieldsConfig) {
    return (
      <>
        {fieldsConfig.map((fc) => (
          <>
            <Typography.Title level={5} className="p-2">
              {fc.category}
            </Typography.Title>
            {fc.fields.map((f, i) => (
              <CellDisplay
                key={f}
                label={f}
                value={mapState.selectedFeatures?.[f] != undefined ? (mapState.selectedFeatures?.[f] as string) : ""}
                isGrey={i % 2 === 0}
              />
            ))}
            <Divider className="my-2" />
          </>
        ))}
      </>
    );
  } else {
    return (
      <>
        {Object.keys(mapState.selectedFeatures ?? {}).map((key, i) => (
          <CellDisplay
            key={key}
            label={key}
            value={mapState.selectedFeatures?.[key] != undefined ? (mapState.selectedFeatures?.[key] as string) : ""}
            isGrey={i % 2 === 0}
          />
        ))}
      </>
    );
  }
};

export default AdditionalFeatures;
