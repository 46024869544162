import DetailsPieChart from "./piechart/DetailsPieChart";
import { getAllSectorsPieChartDataFromFeatures, getPieChartDataFromFeatures } from "./utils";

interface AllSectorsDetailsProps {
  features: Record<string, string | number> | undefined;
}

const AllSectorsDetails: React.FC<AllSectorsDetailsProps> = (props) => {
  const { features } = props;

  const data = getAllSectorsPieChartDataFromFeatures(features, 'ghg_emissions_tco2eq');

  return (
    <div className="p-2">
      <p className="font-bold text-lg">Répartition par énergie</p>
      <DetailsPieChart data={data} />
    </div>
  );
}

export default AllSectorsDetails;