import { LeftOutlined } from "@ant-design/icons";
import { Modal, Typography, Carousel, Space, Button } from "antd";
import { colors } from "../assets/styles/colors";
import tuto1 from "../assets/images/tuto1.png";
import tuto2 from "../assets/images/tuto2.png";
import tuto3 from "../assets/images/tuto3.png";
import tuto4 from "../assets/images/tuto4.png";
import tuto5 from "../assets/images/tuto5.png";
import { CarouselRef } from "antd/es/carousel";
import React from "react";

const TutoModal: React.FC<{
  carouselRef: React.RefObject<CarouselRef>;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}> = ({ carouselRef, isOpen, setIsOpen }) => {
  const tutoImages = [tuto1, tuto2, tuto3, tuto4, tuto5];
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handlePrev = () => {
    carouselRef.current?.prev();
  };

  const handleNext = () => {
    carouselRef.current?.next();
  };

  const goTo = (slide: any) => {
    carouselRef.current?.goTo(slide, false);
  };

  const handleBeforeChange = (currentSlide: number, nextSlide: number) => {
    setCurrentIndex(nextSlide); // Mettre à jour l'index de la slide actuelle
  };

  function btnStyle(isSelected: boolean) {
    return {
      width: isSelected ? 10 : 8,
      height: isSelected ? 10 : 8,
      background: isSelected ? colors.amirGreen : colors.lightGrey,
      borderRadius: isSelected ? 10 : 8,
      margin: 3,
    };
  }

  return (
    <Modal
      open={isOpen}
      title={
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: 0,
            }}
          >
            <Typography.Text style={{ color: colors.amirGreen, fontSize: 16 }}>
              Tutoriel d'introduction
            </Typography.Text>
          </div>
          <div
            style={{
              backgroundColor: colors.lightGrey,
              marginTop: 10,
              width: "100%",
              height: 1,
            }}
          ></div>
        </div>
      }
      okText="Enregistrer"
      width={"80%"}
      cancelText="Annuler"
      onCancel={() => setIsOpen(false)}
      footer={[]}
      forceRender={true}
      destroyOnClose={true}
    >
      <Carousel
        ref={carouselRef}
        dots={false}
        beforeChange={handleBeforeChange}
      >
        {tutoImages.map((im: any) => (
          <div>
            <img src={im} />
          </div>
        ))}
      </Carousel>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {tutoImages.map((_, i) => {
          return (
            <button
              onClick={() => goTo(i)}
              style={btnStyle(currentIndex === i)}
            />
          );
        })}
      </div>
      <Space style={{ position: "absolute", right: 30, bottom: 30 }}>
        {currentIndex !== 0 && (
          <Button
            onClick={handlePrev}
            icon={<LeftOutlined />}
            style={{ borderRadius: 40, height: 40, width: 40 }}
          />
        )}
        <Button
          onClick={
            currentIndex === tutoImages.length - 1
              ? () => setIsOpen(false)
              : handleNext
          }
          type="primary"
          style={{
            borderRadius: 20,
            color: colors.black,
            height: 40,
            width: 120,
            padding: 0,
            backgroundColor: colors.amirGreen,
          }}
        >
          {currentIndex === tutoImages.length - 1 ? "Commencer" : "Suivant"}
        </Button>
      </Space>
    </Modal>
  );
};

export default TutoModal;
