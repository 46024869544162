/**
 * This context is used to provide access to mapbox map instance for imperative operations on map
 * e.g. zoom, pan, fitBounds etc.
 */

import React from "react";

const mapInitialContext = {
  map: null,
  setMap: () => {},
};

const MapContext = React.createContext<{map: any, setMap: (m: any) => void}>(mapInitialContext);

export { mapInitialContext };
export default MapContext;