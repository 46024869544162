import { Button, Tooltip, Typography } from "antd";
import { MapStateContext, MapStateDispatch } from "../../../contexts/mapStateContext";
import { useContext, useEffect } from "react";

interface TargetZoneProps {
  exportsLeft: number | undefined;
  isDisabled: boolean;
  isLoading: boolean;
  handleExport: (polygon: string) => Promise<void>;
}

const TargetZone: React.FC<TargetZoneProps> = (props) => {
  const { isDisabled, handleExport, isLoading, exportsLeft } = props;

  const { drawControl, drawFeatures, triggerExport } = useContext(MapStateContext);
  const dispatch = useContext(MapStateDispatch);

  const onCreateZone = () => {
    dispatch({ type: "SET_IS_EXPORTING", isExporting: true });
    dispatch({ type: "SET_IS_DRAWING", isDrawing: true });
    drawControl?.changeMode("draw_polygon");
  };


  // Clean component state on unmount
  useEffect(() => {
    // dispatch({ type: "SET_IS_DRAWING", isDrawing: true });

    return () => {
      dispatch({ type: "SET_IS_DRAWING", isDrawing: false });
      dispatch({ type: "SET_IS_EXPORTING", isExporting: false });
      // drawControl?.deleteAll();
    }
  }, []);

  // Export is triggered through ExploreTarget
  useEffect(() => {
    if (triggerExport) {
      if (drawFeatures?.[0]?.geometry) {
        dispatch({ type: "SET_TRIGGER_EXPORT", triggerExport: false });
        handleExport(drawFeatures?.[0]?.geometry).finally(() => {
          dispatch({ type: "SET_IS_EXPORTING", isExporting: false });
          dispatch({ type: "SET_IS_DRAWING", isDrawing: false });
          drawControl?.deleteAll();
          drawControl?.changeMode("simple_select");
        })
      }
    }
  }, [triggerExport]);

  const isButtonDisabled = isDisabled || isLoading || (exportsLeft !== undefined && exportsLeft <= 0);


  return (
    <>
      <div className="font-extrabold text-lg mb-2">Zone ciblée</div>
      <div className="flex space-y-2 flex-col">
        {exportsLeft !== undefined ?
          <Tooltip title={`Il vous reste ${exportsLeft} téléchargements`}>
            <Button type="primary" className="w-full" onClick={onCreateZone} disabled={isButtonDisabled} loading={isLoading}>Créer une zone</Button>
          </Tooltip> :
          <Button type="primary" className="w-full" onClick={onCreateZone} disabled={isButtonDisabled} loading={isLoading}>Créer une zone</Button>
        }
      </div>
    </>
  );
}

export default TargetZone;