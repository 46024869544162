const CITY_SELECTION = "city_selection";

// Carbon tracker events
const CARBON_TRACKER_DATE_CHANGE = "ct_date_change";
const CARBON_TRACKER_COMPARE_CHANGE = "ct_compare_change";
const CARTBON_TRACKER_EXPORT = "ct_export";

// Explore target events
const EXPLORE_TARGET_TILE_CLICK = "et_tile_click";
const EXPLORE_TARGET_SECTOR_CHANGE = "et_sector_change";
const EXPLORE_TARGET_SUBSECTOR_CHANGE = "et_subsector_change";
const EXPLORE_TARGET_ANALYSIS = "et_analysis";
const EXPLORE_TARGET_EXPORT = "et_export";

export {
  CITY_SELECTION,
  CARBON_TRACKER_DATE_CHANGE,
  CARBON_TRACKER_COMPARE_CHANGE,
  CARTBON_TRACKER_EXPORT,
  EXPLORE_TARGET_TILE_CLICK,
  EXPLORE_TARGET_SECTOR_CHANGE,
  EXPLORE_TARGET_SUBSECTOR_CHANGE,
  EXPLORE_TARGET_ANALYSIS,
  EXPLORE_TARGET_EXPORT,
};
