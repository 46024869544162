import React from "react";
import { useDictionary } from "../services/api/entity/hooks";
import { EntityContext } from "../contexts/EntityContext";
import { IDictionary } from "../models/general/dictionary";

export const DictionaryContext = React.createContext({} as IDictionary);

const DictionaryProvider = ({ children }: { children: React.ReactElement }) => {
  const { activeEntity } = React.useContext(EntityContext);
  const { data: dictionary } = useDictionary(activeEntity.id);
  return (
    <DictionaryContext.Provider value={dictionary}>
      {children}
    </DictionaryContext.Provider>
  );
};

export default DictionaryProvider;
