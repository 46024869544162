import React from "react";
import FilterSlider from "./FilterSlider";
import FilterCheckbox from "./FilterCheckbox";
import FilterDropdown from "./FilterDropdown";
import { ILayerFilter } from "../../models/exploreV2/filters";

interface LayerFiltersProps {
  filter: ILayerFilter;
  onFilterChange: (filter: ILayerFilter) => void;
  distribution?: number[]; // For numeric filters
}

const LayerFilter: React.FC<LayerFiltersProps> = (props) => {
  const { filter, onFilterChange } = props;

  switch (filter.type) {
    case "numeric":
      return (
        <FilterSlider
          min={filter.min}
          max={filter.max}
          step={filter.step ?? 1}
          onChange={(values) => onFilterChange({ ...filter, value: values })}
          value={filter.value}
          distribution={props.distribution}
        />
      );
    case "checkbox":
      return (
        <FilterCheckbox
          value={filter.value as string[]}
          options={filter.options}
          onChange={(values) => onFilterChange({ ...filter, value: values })}
        />
      );
    case "dropdown":
      return (
        <FilterDropdown
          value={typeof filter.value === "string" ? filter.value : filter.value.toString()}
          options={filter.options}
          onChange={(value) => onFilterChange({ ...filter, value: value })}
        />
      );
  }
  return <></>;
};

export default LayerFilter;
