import { ILayerFilter } from "../../models/exploreV2/filters";
import { mapboxFilterExpressionFromFilters } from "../../services/mapbox/filteringV2";

function generateCitiesFilter(
  cities: string[],
): any[] | undefined {
  if (cities.length > 0) {
    return ["in", "city_code", ...cities];
  } else {
    return undefined
  }
}

function generateLayerFilter(
  field: string,
  cities: string[],
  activeFilters: ILayerFilter[],
  sector: string,
): any[] {
  const citiesFilter = generateCitiesFilter(cities);
  const filters = mapboxFilterExpressionFromFilters(activeFilters, sector);
  if (citiesFilter) {
    if (filters.length > 0) {
      return ["all", ...filters, ["has", field], citiesFilter];
    }
    
    return ["all", ["has", field], citiesFilter];
  } else {
    if (filters.length > 0) {
      return ["all", ["has", field], ...filters];
    }

    return ["has", field];
  }
}

export { generateLayerFilter };