import { Button, Divider, List, Skeleton, Typography } from "antd";
import { TEmittingSector } from "../../../../../models/general/common";
import { CellDisplay, CellDisplayLoader } from "./ID";
import { getPrimaryEnergy } from "./utils";
import { useDistrictBarchart } from "../../../../../services/dataApi/hooks";
import { Suspense, useContext } from "react";
import { DictionaryContext } from "../../../../../contexts/DictionaryContext";
import { featureFieldNameToLabel, readFromCommonDictionary } from "../../../../../services/utils";
import SkeletonLoader from "../../common/SkeletonLoader";
import { MapStateDispatch } from "../../../contexts/mapStateContext";
import { EntityContext } from "../../../../../contexts/EntityContext";

interface AllSectorsDataProps {
  features: Record<string, string | number> | undefined;
}

// TODO : finish code on sector click
export const AllSectorsData: React.FC<AllSectorsDataProps> = (props) => {
  const { features } = props;
  const { activeEntity } = useContext(EntityContext);
  const dictionary = useContext(DictionaryContext);
  const dispatch = useContext(MapStateDispatch);

  if (!features) return <></>;

  const fields = activeEntity.emittingSectors.map((s) => s.sector);

  const onClickSector = (sector: string) => {
    dispatch({ type: "SELECT_SECTOR", sector });
  }

  return (
    <>
      <Typography.Title level={5} className="p-2">Secteurs d'émission présents</Typography.Title>
      <List
        className="px-2"
        dataSource={fields}
        renderItem={(item, index) => (
          <List.Item>
            <Button onClick={() => onClickSector(fields[index])} className="text-[#00A947]" type="link" key={`${item}`}>{readFromCommonDictionary(dictionary, item)}</Button>
          </List.Item>
        )}
      />
    </>
  )
};

const AgricultureData: React.FC = () => {
  return <></>
}

const RoadsData: React.FC = () => {
  return (
    <>
    </>
  )
}

interface PrimaryEnergyProps {
  sector: string;
  field: string;
  districtName: string;
}

const PrimaryEnergy: React.FC<PrimaryEnergyProps> = (props) => {
  const { sector, field, districtName } = props;
  const dictionary = useContext(DictionaryContext);
  const { activeEntity } = useContext(EntityContext);

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;

  const primaryEnergyAPI = useDistrictBarchart(
    activeEntity.instance,
    activeEntity.batch,
    sector,
    startDate,
    endDate,
    3,
    "heating_energy",
    field,
    "unique_count",
    districtName
  );

  const primaryEnergyRes = primaryEnergyAPI.data as unknown as Record<string, any>[];
  const primaryEnergy = getPrimaryEnergy(primaryEnergyRes, "heating_energy", field);

  if (!primaryEnergy) return <></>;

  return <CellDisplay label="Première énergie de chauffage" value={featureFieldNameToLabel(dictionary, primaryEnergy)} isGrey />;
}

interface EnergeticGridDataProps {
  sector: TEmittingSector;
  features: Record<string, string | number> | undefined;
  displayPrimaryEnergy?: boolean;
  primaryEnergyField?: string;
}

const EnergeticGridData: React.FC<EnergeticGridDataProps> = (props) => {
  const { sector, features, displayPrimaryEnergy, primaryEnergyField } = props;

  const districtName = features?.district_name as string;
  const naturalGas = features?.[`${sector}_energy_consumption_mwh_naturalgas`] as number > 0 ? "Oui" : "Non";
  const districtHeating = features?.[`${sector}_energy_consumption_mwh_districtheating`] as number > 0 ? "Oui" : "Non";

  return (
    <>
      <Divider className="m-0" />
      <Typography.Title level={5} className="p-2">Données sur le tissu énergétique</Typography.Title>
      <CellDisplay label="Présence du réseau de chaleur" value={districtHeating} isGrey />
      <CellDisplay label="Présence du gaz naturel" value={naturalGas} />
      {!!displayPrimaryEnergy && !!primaryEnergyField &&
        <Suspense fallback={<CellDisplayLoader isGrey />}>
          <PrimaryEnergy sector={sector} field={primaryEnergyField} districtName={districtName} />
        </Suspense>
      }
    </>
  )
}

interface Tier2SpecificSectionProps {
  sector: TEmittingSector;
  features: Record<string, string | number> | undefined;
  displayPrimaryEnergy?: boolean;
  primaryEnergyField?: string;
}

const Tier2SpecificSection: React.FC<Tier2SpecificSectionProps> = (props) => {
  const { sector, features, displayPrimaryEnergy, primaryEnergyField } = props;

  switch (sector) {
    case 'all':
      return <AllSectorsData features={features} />
    case 'residential':
    case 'services':
    case 'industry':
      return <EnergeticGridData sector={sector} features={features} displayPrimaryEnergy={displayPrimaryEnergy} primaryEnergyField={primaryEnergyField} />;
    case 'roadtransport':
      return <RoadsData />;
    case 'agriculture':
      return <AgricultureData />;
    default:
      return <></>;
  }
}

export default Tier2SpecificSection;