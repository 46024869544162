import React from "react";
import { LayoutContext } from "../../../contexts/LayoutContext";
import { Bar } from "recharts";

const width = 10;
const radius = 5;

const BackgroundBarChart: React.FC<{ props: any; stacked: boolean }> = ({
  props,
  stacked,
}) => {
  const { x, fill, name, dataKey } = props;
  const colorKey = stacked ? dataKey : name;
  const { colorMapping } = React.useContext(LayoutContext);

  const colordId = "back-gradient-" + colorKey.trim().replaceAll(" ", "-");

  return (
    <>
      <defs>
        <linearGradient
          id={colordId}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          style={{ pointerEvents: "none" }}
        >
          <stop
            offset="0%"
            style={{
              stopColor: colorMapping[colorKey]?.gradient[1],
              stopOpacity: 0.2,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: colorMapping?.[colorKey]?.gradient[0],
              stopOpacity: 0.3,
            }}
          />
        </linearGradient>
      </defs>
      <rect
        x={x}
        y={radius}
        width={width}
        height={props.background.height}
        style={{ pointerEvents: "none" }}
        fill={`url(#${colordId})`}
        rx={5}
        ry={5}
      />
    </>
  );
};

const PlainBarChart: React.FC<{
  props: any;
  stacked: boolean;
  setActiveKey: (s: string) => void;
  currentDataKey: string;
}> = ({ props, stacked, setActiveKey, currentDataKey }) => {
  const { x, y, height, fill, name, dataKey } = props;
  const { colorMapping } = React.useContext(LayoutContext);
  const colorKey = stacked ? dataKey : name;

  const colordId = "gradient-" + colorKey.trim().replaceAll(" ", "-");

  const shouldRoundTopCorner = true;
  const shouldRoundBottomCorner = true;

  // Handling too small display
  const barHeight = Math.max(height, radius);
  const displayedHeight = barHeight;
  const displayedY = y - (barHeight - height);

  return (
    <>
      <defs>
        <linearGradient
          id={colordId}
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop
            offset="0%"
            style={{
              stopColor: colorMapping[colorKey]?.gradient[1],
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: colorMapping[colorKey]?.gradient[0],
              stopOpacity: 1,
            }}
          />
        </linearGradient>
      </defs>
      <path
        d={`M${x}, ${displayedY + (shouldRoundTopCorner ? radius : 0)} 
        ${
          shouldRoundTopCorner
            ? `q0,-${radius}, ${radius},-${radius} h${
                width - 2 * radius
              }  q${radius},0 ${radius},${radius}`
            : `h${width}`
        }
        v${
          displayedHeight -
          (shouldRoundTopCorner ? radius : 0) -
          (shouldRoundBottomCorner ? radius : 0)
        } ${
          shouldRoundBottomCorner
            ? `q0,${radius} -${radius},${radius} q-${radius},0 -${radius}-${radius}`
            : `h${-width}`
        } v${displayedHeight - 2 * radius}`}
        fill={`url(#${colordId})`}
        onMouseOver={() => {
          setActiveKey(dataKey);
        }}
      ></path>
    </>
  );
};

const CustomBarShape: React.FC<{
  stacked: boolean;
  props: any;
  setActiveKey: (s: string) => void;
  currentDataKey: string;
}> = ({ stacked, props, setActiveKey, currentDataKey }) => {
  return stacked ? (
    <>
      {props.dataKey == "background" ? (
        <BackgroundBarChart props={props} stacked />
      ) : (
        <PlainBarChart
          props={props}
          currentDataKey={currentDataKey}
          stacked
          setActiveKey={(s: string) => setActiveKey(s)}
        />
      )}
    </>
  ) : props.dataKey != "background" ? (
    <>
      <BackgroundBarChart props={props} stacked={false} />
      <PlainBarChart
        props={props}
        currentDataKey={currentDataKey}
        setActiveKey={setActiveKey}
        stacked={false}
      />
    </>
  ) : (
    <></>
  );
};

export default CustomBarShape;
