import { useMemo } from "react";
import { QueryKey, useMutation, useQuery, useQueryClient } from "react-query";
import { TCity } from "../../../models/general/city";
import { TCityGroup, TGroupType } from "../../../models/general/cityGroup";
import { IEntity } from "../../../models/general/entity";
import {
  APIError,
  APISuccess,
  adaptUseQueryFetcher,
  adaptUseQuerySuspenseResult,
} from "../common";
import { EntityService } from "./api";
import { useAuth } from "react-oidc-context";
import { queryKeyUserEntities } from "../user/hooks";
import {
  IDashboardConfiguration,
  TComponent,
} from "../../../models/dashboard/common";
import { IMapParameters } from "../../../models/general/map";
import { IDictionary } from "../../../models/general/dictionary";

export function queryKeyCityGroups(
  entityId: string,
  type: TGroupType,
  parentIds?: string[],
  authorisedIds?: string[]
): QueryKey {
  return [`/db-data/groups`, entityId, type, parentIds, authorisedIds];
}

export function useCityGroups(
  entityId: string,
  type: TGroupType,
  parentCodes?: string[],
  authorisedCodes?: string[]
): APISuccess<TCityGroup[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<TCityGroup[]>, APIError>(
    queryKeyCityGroups(entityId, type, parentCodes, authorisedCodes),
    () =>
      adaptUseQueryFetcher(
        service.fetchCityGroups(entityId, type, parentCodes, authorisedCodes)
      )
  );

  return adaptUseQuerySuspenseResult<TCityGroup[], false>(res);
}

export function queryKeyCities(
  entityId: string,
  groupCodes?: string[]
): QueryKey {
  return [`/db-data/cities`, entityId, groupCodes];
}

export function useCities(
  entityId: string,
  groupCodes?: string[]
): APISuccess<TCity[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<TCity[]>, APIError>(
    queryKeyCities(entityId, groupCodes),
    () => adaptUseQueryFetcher(service.fetchCities(entityId, groupCodes))
  );

  return adaptUseQuerySuspenseResult<TCity[], false>(res);
}

export function queryKeyMapParameters(cityCodes?: string[]): QueryKey {
  return [`/cities/mapParameters`, cityCodes];
}

export function useMapParameters(
  cityCodes?: string[]
): APISuccess<IMapParameters> {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    []
  );

  const res = useQuery<APISuccess<IMapParameters>, APIError>(
    queryKeyMapParameters(cityCodes),
    () => adaptUseQueryFetcher(service.fetchMapParameters(cityCodes))
  );

  return adaptUseQuerySuspenseResult<IMapParameters, false>(res);
}

export function queryKeyEntities(): QueryKey {
  return [`/api/entities`];
}

export function useEntities(): APISuccess<IEntity[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<IEntity[]>, APIError>(
    queryKeyEntities(),
    () => adaptUseQueryFetcher(service.fetchEntities())
  );

  return adaptUseQuerySuspenseResult<IEntity[], false>(res);
}

export function useCreateEntity(userId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (entity: IEntity) => adaptUseQueryFetcher(service.createEntity(entity)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyEntities());
        await queryClient.invalidateQueries(queryKeyUserEntities(userId));
      },
    }
  );
}

export function useUpdateEntity(entityId: string, userId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (entity: IEntity) =>
      adaptUseQueryFetcher(service.updateEntity(entityId, entity)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyEntities());
        await queryClient.invalidateQueries(queryKeyUserEntities(userId));
      },
    }
  );
}
export function useDeleteEntity(userId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (entityId: string) =>
      adaptUseQueryFetcher(service.deleteEntity(entityId, userId)),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeyEntities());
        await queryClient.invalidateQueries(queryKeyUserEntities(userId));
      },
    }
  );
}

export function queryKeyEntityDashboardConfiguration(
  entityId: string
): QueryKey {
  return [`/api/configuration`, entityId];
}

export function useEntityDashboardConfiguration(
  entityId: string
): APISuccess<IDashboardConfiguration> {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<IDashboardConfiguration>, APIError>(
    queryKeyEntityDashboardConfiguration(entityId),
    () => adaptUseQueryFetcher(service.fetchDashboardConfiguration(entityId))
  );

  return adaptUseQuerySuspenseResult<IDashboardConfiguration, false>(res);
}

export function queryKeyEntityDashboardComponents(entityId: string): QueryKey {
  return [`/api/component`, entityId];
}

export function useEntityDashboardComponents(
  entityId: string,
  sectionFilters?: string[]
): APISuccess<TComponent[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<TComponent[]>, APIError>(
    queryKeyEntityDashboardComponents(entityId),
    () =>
      adaptUseQueryFetcher(
        service.fetchDashboardComponents(entityId, sectionFilters)
      )
  );

  return adaptUseQuerySuspenseResult<TComponent[], false>(res);
}

export function queryKeyDictionary(entityId: string): QueryKey {
  return [`/entities/dictionary`, entityId];
}

export function useDictionary(entityId: string): APISuccess<IDictionary> {
  const auth = useAuth();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<IDictionary>, APIError>(
    queryKeyDictionary(entityId),
    () => adaptUseQueryFetcher(service.fetchDictionary(entityId))
  );

  return adaptUseQuerySuspenseResult<IDictionary, false>(res);
}

export function useUpdateDictionary(entityId: string) {
  const auth = useAuth();
  const queryClient = useQueryClient();

  const service = useMemo(
    () => new EntityService(auth.user?.access_token ?? ""),
    [auth]
  );

  return useMutation(
    (d: IDictionary) =>
      adaptUseQueryFetcher(service.updateDictionary(entityId, d.id, d)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyDictionary(entityId));
      },
    }
  );
}
