import axios from "axios";
import qs from "qs";
import { UseQueryResult } from "react-query";

export enum Errors {
  Unauthorized = "unauthorized",
  ServerError = "server error",
  ClientError = "client error",
  NotFound = "not found",
  BadRequest = "bad request",
  RequestCancelled = "request cancelled",
  InvalidInputData = "invalid input data",
}

export enum ContentType {
  JSON = "application/json",
  PDF = "application/pdf;charset=utf8",
  CSV = "text/csv",
  MULTIPART = "multipart/form-data",
}

export interface APIError {
  status: "error";
  message: string;
  error: Errors;
}

export interface APISuccess<T> {
  status: "success";
  data: T;
}

export interface APIResponse<A> {
  success: boolean;
  message: string;
  results: A;
}

export enum RequestTimeout {
  defaultTimeout = 60000,
  exportTimeout = 60000,
}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export type APIResult<T> = APISuccess<T> | APIError;

const axiosBasic = axios.create({
  baseURL: "/",
  timeout: RequestTimeout.defaultTimeout,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

const axiosForExport = axios.create({
  baseURL: "/",
  timeout: RequestTimeout.exportTimeout,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

abstract class APIService {
  constructor(protected readonly authToken: string) {}

  private static authHeader(token: string) {
    return { Authorization: `Bearer ${token}` };
  }

  private static errorFromHttpStatus(status: number) {
    switch (status) {
      case 400:
        return Errors.BadRequest;
      case 401:
      case 403:
        return Errors.Unauthorized;
      case 404:
        return Errors.NotFound;
      case 422:
        return Errors.InvalidInputData;
      default:
        return Errors.ServerError;
    }
  }

  private async makeAxiosRequest<T>(
    method: HttpMethod,
    url: string,
    accept: ContentType = ContentType.JSON,
    params: object = {},
    data: object | FormData = {},
    exportRequest: boolean = false,
    respTypeArray: boolean = false,
    contentType: ContentType = ContentType.JSON
  ): Promise<APIResult<T>> {
    try {
      const axiosConfig = exportRequest ? axiosForExport : axiosBasic;
      const respType = respTypeArray ? "arraybuffer" : "json";

      const response = await axiosConfig.request<T>({
        method,
        url,
        headers: {
          ...APIService.authHeader(this.authToken),
          accept,
          ...{ "Content-type": contentType },
        },
        responseType: respType,
        data,
        params,
      });

      return { status: "success", data: response.data };
    } catch (e: any) {
      if (axios.isCancel(e)) {
        return {
          status: "error",
          message: "",
          error: Errors.RequestCancelled,
        };
      } else {
        return {
          status: "error",
          error: APIService.errorFromHttpStatus(e.response.status),
          message: e.response?.data,
        };
      }
    }
  }

  async makeAxiosJSONRequest<T>(
    method: HttpMethod,
    url: string,
    params: object = {},
    data: object | undefined = undefined
  ): Promise<APIResult<T>> {
    const res = await this.makeAxiosRequest<APIResponse<T>>(
      method,
      url,
      ContentType.JSON,
      params,
      data
    );
    
    if (res.status === "success")
      return { status: "success", data: res.data.results };
    else return res;
  }
}

export function adaptUseQueryFetcher<T>(
  fetcher: Promise<APIResult<T>>
): Promise<APISuccess<T>> {
  return fetcher.then((r) => {
    switch (r.status) {
      case "success":
        return r;
      case "error":
        return Promise.reject(r);
    }
  });
}

export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

export type UseAPIResult<T> =
  | UseAPIResultLoading
  | UseAPIResultError
  | UseAPIResultSuccess<T>;

export type UseDeferrableAPIResult<T> =
  | UseAPIResultSuccess<T>
  | UseAPIResultPending;

export interface UseAPIResultLoading {
  status: "loading";
}

export interface UseAPIResultError {
  status: "error";
  message: string;
  error: Errors;
}

export interface UseAPIResultSuccess<T> {
  status: "success";
  data: T;
}

export interface UseAPIResultPending {
  status: "pending";
}

export class UnreachableCaseError extends Error {
  constructor(val: any) {
    super(`Unreachable case: ${JSON.stringify(val)}`);
  }
}

export function adaptUseQuerySuspenseResult<T, S extends boolean>(
  res: UseQueryResult<APISuccess<T>, APIError>
): S extends false ? UseAPIResultSuccess<T> : UseDeferrableAPIResult<T> {
  switch (res.status) {
    case "loading":
      return { status: "pending" } as S extends false
        ? UseAPIResultSuccess<T>
        : UseDeferrableAPIResult<T>;
    case "error":
      throw res.error;
    case "success":
      return { status: "success", data: res.data!.data } as S extends false
        ? UseAPIResultSuccess<T>
        : UseDeferrableAPIResult<T>;
    default:
      throw new UnreachableCaseError(res);
  }
}

export default APIService;
