export const colors = {
  white: '#FFFFFF',
  lightMode: '#FFFFFF',
  darkWhite: '#F9F9F9',
  grey: "#A4A4A4",
  amirGreen: "#1ED672",
  lightGrey: "#E4E5E7",
  success: '#34C924',
  failure: '#EE1010',
  neutral: '#FDEE00',
  primary: '#01185d',
  secondary: '#00b050',
  secondaryLight: '#d9f0df',
  lightBlue: "#ACBCFF",
  black: 'black',
  background: "#f5f5f6",
  greyText: "#838383",
  dashboardGrey: "#555555",
  darkGrey: "#2F2F2F",
  darkMode: "#212121",
}

export const colorPalette = [
  {
    gradient: ["#A73737", "#7A2828"],
    colors: ["#3E1314", "#842D30", "#BB4643", "#E0958C", "#F4DBD7", "#FCF6E6"],
  },
  {
    gradient: ["#FF5029", "#D93E30"],
    colors: ["#44060A", "#9D0F11", "#EF1907", "#FF5029", "#FFC0A8", "#FFF2ED"],
  },
  {
    gradient: ["#FFBF1A", "#FF4080"],
    colors: ["#461A02", "#943F0C", "#DD7702", "#FFBF1A", "#FFE788", "#FFFBEB"],
  },
  {
    gradient: ["#71B280", "#134E5E"],
    colors: ["#0D2114", "#1E472A", "#2C6F3F", "#71B280", "#BBDFBF", "#F1F8F2"],
  },
  {
    gradient: ["#93F9B9", "#1D976C"],
    colors: ["#023116", "#0F6C33", "#0AAF49", "#3EEA7F", "#B6FCCF", "#EFFEF4"],
  },
  {
    gradient: ["#2FEA9B", "#7FDD53"],
    colors: ["#01321F", "#0F6C46", "#09B06B", "#2FEA9B", "#B6FCDD", "#EFFEF7"],
  },
  {
    gradient: ["#1FA2FF", "#12D8FA"],
    colors: ["#11365A", "#0F68BE", "#1FA2FF", "#50D3FF", "#B9EDFF", "#EDFBFF"],
  },
  {
    gradient: ["#1B59F8", "#0B328F"],
    colors: ["#172654", "#1E42AF", "#2666EA", "#60A8FA", "#BFDCFE", "#EFF6FF"],
  },
  {
    gradient: ["#2B5876", "#4E4376"],
    colors: ["#192B38", "#284C64", "#357094", "#6AA6C6", "#CBDFEC", "#F4F8FB"],
  },
  {
    gradient: ["#8E2DE2", "#4A00E0"],
    colors: ["#320B6A", "#4E0FA0", "#731CE2", "9153FF", "#C6B1FF", "#EDE8FF"],
  },
  // Additional gradients for energies
  {
    gradient: ["#FFBF1A", "#FFE788"],
    colors: [],
  },
  // Additional gradients for energies
  {
    gradient: ["#0F6C33", "#0AAF49"],
    colors: [],
  }
];