import { Tooltip } from "antd";
import React, { useContext } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { colorPalette } from "../../../../../../assets/styles/colors";
import { DictionaryContext } from "../../../../../../contexts/DictionaryContext";
import { EntityContext } from "../../../../../../contexts/EntityContext";
import { featureFieldNameToLabel, readFromCommonDictionary } from "../../../../../../services/utils";
import "./DetailsPieChart.css";

const RADIAN = Math.PI / 180;
const displayThreshold = 1;

const CustomizedLabelLine: React.FC<{
  props: any;
}> = ({ props }) => {
  if (props.payload.value > displayThreshold) {
    return (
      <line
        x1={props.points[0].x}
        y1={props.points[0].y}
        x2={props.points[props.points.length - 1].x}
        y2={props.points[props.points.length - 1].y}
        stroke={props.stroke}
        strokeWidth="1"
      />
    );
  }
  return null;
};

const CustomizedLabel: React.FC<{
  props: any;
  dictionary: any;
}> = ({ props, dictionary }) => {
  const { cx, cy, midAngle, payload } = props;

  const content = (() => {
    if (featureFieldNameToLabel(dictionary,payload.label) !== payload.label) {
      return `${featureFieldNameToLabel(
        dictionary,
        payload.label
      )} ${payload.value.toFixed(0)}%`;
    } else if (readFromCommonDictionary(dictionary, payload.label)) {
      return `${readFromCommonDictionary(dictionary, payload.label)} ${payload.value.toFixed(0)}%`;
    } else {
      return `${payload.label} ${payload.value.toFixed(0)}%`;
    }
  })();

  // const content = `${featureFieldNameToLabel(
  //   dictionary,
  //   payload.label
  // )} ${payload.value.toFixed(0)}%`;
  const textLength = 8 * content.length;
  const [zIndex, setZIndex] = React.useState(1);
  const [width, setWidth] = React.useState(textLength);
  const [height, setHeight] = React.useState(20);

  // console.log('Props are : ', payload)
  // // const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  // const radius = 100;
  // const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // const y = cy + radius * Math.sin(-midAngle * RADIAN);

  // const height = 60;
  // const width = 120;

  const radius = 120;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const cornerRadius = 10;

  const handleMouseEnter = () => {
    setWidth(textLength * 1.1);
    setHeight(22);
    setZIndex(10);
  };

  const handleMouseLeave = () => {
    setWidth(textLength);
    setHeight(20);
    setZIndex(1);
  };

  if (payload.value < displayThreshold) {
    return <></>;
  }

  return (
    <svg>
      <defs>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow
            dx="2"
            dy="2"
            stdDeviation="2"
            floodColor="black"
            floodOpacity="0.2"
          />
        </filter>
      </defs>
      <g
        transform={`translate(${
          x - (midAngle < 90 || midAngle > 270 ? width / 1.5 : -width / 1.5)
        }, ${y})`}
        style={{ zIndex }}
      >
        <rect
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          x={midAngle < 90 || midAngle > 270 ? 0 : -width}
          y={-height / 2}
          width={width}
          height={height}
          rx={cornerRadius}
          ry={cornerRadius}
          fill="white"
          filter="url(#shadow)"
          style={{ zIndex: zIndex }}
        />
        <text
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          x={midAngle < 90 || midAngle > 270 ? width / 2 : -width / 2}
          y={0}
          fill="black"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {content}
        </text>
      </g>
    </svg>
  );

  // return (
  //   <foreignObject className="absolute overflow-visible" x={x - width/2} y={y - height/2} width={width} height={height}>
  //     <PieChartLabel value={payload.value} height={height} width={width} label={payload.label} color={payload.color} unit={"%"} />
  //     {/* <div className="h-[10px] w-[10px] bg-red-500" /> */}
  //   </foreignObject>
  //   // <circle x={x} y={y} r={10} fill={COLORS[index]} />
  //   // <rect x={cx} y={cy} width={100} height={100} fill="red" />
  //   // <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
  //   //   {`${(percent * 100).toFixed(0)}%`}
  //   // </text>
  // );
};

interface DetailsPieChartProps {
  data: { value: number; label: string }[];
}

const DetailsPieChart: React.FC<DetailsPieChartProps> = (props) => {
  const { data } = props;
  const { activeEntity } = React.useContext(EntityContext);
  const dictionary = useContext(DictionaryContext);
  return (
    <ResponsiveContainer width={"100%"} height={270}>
      <PieChart className="piechart">
        <defs>
          {data.map((entry, index) => (
            <linearGradient id={`myGradient${index}`}>
              <stop
                offset="0%"
                stopColor={
                  colorPalette[
                    activeEntity.energies.find(
                      (e: any) => e.energyId === entry.label
                    )?.colorId ?? index
                  ]?.gradient[0]
                }
              />
              <stop
                offset="100%"
                stopColor={
                  colorPalette[
                    activeEntity.energies.find(
                      (e: any) => e.energyId === entry.label
                    )?.colorId ?? index
                  ]?.gradient[1]
                }
              />
            </linearGradient>
          ))}
        </defs>
        <Tooltip />
        <Pie
          dataKey="value"
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={100}
          label={(props) => (
            <CustomizedLabel props={props} dictionary={dictionary} />
          )}
          labelLine={(props) => <CustomizedLabelLine props={props} />}
          innerRadius={60}
          animationDuration={500}
          animationBegin={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={`url(#myGradient${index})`} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DetailsPieChart;
