import React from "react";
import { Select } from "antd";

interface FilterDropdownProps {
  value: string|undefined;
  options: number[] | String[];
  onChange: (value: string) => void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = (props) => {
  const { options, onChange, value } = props;

  const handleChange = (newValue: string) => {
    onChange?.(newValue);
  };

  return (
    <Select
      placeholder="Choisir une valeur"
      onChange={handleChange}
      options={options.map((value) => ({
        value: value,
        label: value,
      }))}
      showSearch
      style={{ width: "100%" }}
      value={value ?? undefined}
    />
  );
};

export default FilterDropdown;
