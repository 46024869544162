import React from "react";
import { IUploadLayer } from "../../../models/explore/layers/uploadLayer";
import { IAdditionalLayer } from "../../../models/explore/layers/additionnalLayer";
import { ILayer } from "../../../models/explore/layers/layerV2";
import { mapStateReducer } from "../reducers/mapStateReducer";
import { ILegend } from "../../../components/mapbox/LayerLegendV2";
import { ILayerFilter } from "../../../models/exploreV2/filters";
import { IExplorationConfiguration } from "../../../models/exploreV2/configuration";

export interface MapState {
  selectedSector: string;
  selectedSubsector: string;
  selectedFeatures: Record<string, string|number> | undefined;
  selectedLatLng: { lat: number, lng: number } | undefined;
  selectedFeaturesType: 'layer'|'additional';
  selectedFeaturesSourceLayer?: string;
  currentTier: number;
  additionalLayers?: string[]; // Additional layers ids
  visibleAdditionalLayers?: string[]; // ids of the additional layers that are visible
  uploadLayers?: IUploadLayer[]; // Layers imported by the user
  visibleUploadLayers?: string[]; // ids of the uploaded layers that are visible
  activeLegend?: ILegend;
  analysisFilters: ILayerFilter[]|undefined; // When loading analysis, the filters are stored here
  selectedFilters: ILayerFilter[]; // Filters being configured
  activeFilters: ILayerFilter[]; // Filters applied on layer
  isAnalysisApplied: boolean,
  drawControl?: any;
  isDrawing?: boolean;
  drawFeatures?: any;
  isExporting?: boolean;
  triggerExport?: boolean;
}

function generateInitialMapState(config: IExplorationConfiguration): MapState {
  const visibleAdditionalLayers = config.additionalLayers.filter((l) => l.isVisible).map((l) => l.id);

  return {
    selectedSector: "all",
    selectedSubsector: "all",
    selectedFeatures: undefined,
    selectedLatLng: undefined,
    selectedFeaturesType: 'layer' as 'layer'|'additional',
    currentTier: 2,
    analysisFilters: undefined,
    selectedFilters: [],
    activeFilters: [],
    isAnalysisApplied: false,
    additionalLayers: visibleAdditionalLayers,
    visibleAdditionalLayers,
  }
} 

const initialMapState = {
  selectedSector: "all",
  selectedSubsector: "all",
  selectedFeatures: undefined,
  selectedLatLng: undefined,
  selectedFeaturesType: 'layer' as 'layer'|'additional',
  currentTier: 2,
  analysisFilters: undefined,
  selectedFilters: [],
  activeFilters: [],
  isAnalysisApplied: false,
};

const MapStateContext= React.createContext<MapState>(initialMapState);
const MapStateDispatch = React.createContext<React.Dispatch<any>>(() => {});

export { initialMapState, generateInitialMapState, MapStateContext, MapStateDispatch };