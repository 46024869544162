import { useMemo } from "react";
import { DashboardService } from "../api/dashboard/api";
import { useQueries, useQuery } from "react-query";
import { useAuth } from "react-oidc-context";
import { DataAPIService } from "./api";
import { APIError, APISuccess, adaptUseQueryFetcher, adaptUseQuerySuspenseResult } from "./common";
import { ISpatioTemporalParameters, ISpatioTemporalResponse, IStatisticsParameters, IStatisticsResponse, ITemporalParameters, ITemporalResponse, TFreq } from "./types";
import _ from "lodash";

const environment = process.env.REACT_APP_ENV === 'dev' ? "dev" : "prod";
console.log('Data env is : ', environment);

function queryKeyLegend(
  instance: string,
  batch: string,
  sector: string,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  numberOfBins: number,
  cumulatedSectors: boolean,
  cities: string[],
) {
  return [`/legend/${instance}/${batch}/${sector}/${startDate}/${endDate}/${tier}/${field}/${numberOfBins}/${cumulatedSectors}/${cities}`];
}

export function useLegend(
  instance: string,
  batch: string,
  sector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  numberOfBins: number,
  cumulatedSectors: boolean,
  cities: string[],
): APISuccess<IStatisticsResponse> {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const params: IStatisticsParameters = {
    instance,
    batch,
    sector,
    date_min: startDate,
    date_max: endDate,
    tier,
    freq: "year",
    environment,
    fieldStat: field,
    funcStat: "percentiles",
    numberOfBins,
    cumulated_sectors: cumulatedSectors,
    city_code: cities,
    alpha: 0.025,
  };

  const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
    queryKeyLegend(instance, batch, sector ?? "na", startDate, endDate, tier, field, numberOfBins, cumulatedSectors, cities),
    () => adaptUseQueryFetcher(service.getStatistics(params))
  );

  return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
}

interface SumParams {
  instance: string,
  batch: string,
  sector: string | undefined,
  subsector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  cumulatedSectors: boolean,
  cities: string[],
  otherFilters?: Record<string, any>|undefined,
  numericFilters?: Record<string, any>|undefined,
}

function queryKeySum(
  instance: string,
  batch: string,
  sector: string | undefined,
  subsector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  cumulatedSectors: boolean,
  cities: string[],
  filters: Record<string, any>|undefined,
  numericFilters: Record<string, any>|undefined,
) {
  return [`/sum/${instance}/${batch}/${sector}/${subsector}/${startDate}/${endDate}/${tier}/${field}/${cumulatedSectors}/${cities}/${JSON.stringify(filters)}/${JSON.stringify(numericFilters)}`];
}

export function useSum({
  instance,
  batch,
  sector,
  subsector,
  startDate,
  endDate,
  tier,
  field,
  cumulatedSectors,
  cities,
  numericFilters,
  otherFilters,
}: SumParams) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const params: IStatisticsParameters = {
    instance,
    batch,
    sector,
    subsector,
    date_min: startDate,
    date_max: endDate,
    tier,
    freq: "year",
    environment,
    fieldStat: field,
    funcStat: "sum",
    numberOfBins: 1,
    cumulated_sectors: cumulatedSectors,
    city_code: cities,
    filters: _.isEmpty(otherFilters) ? undefined : otherFilters,
    filter_numeric: _.isEmpty(numericFilters) ? undefined : numericFilters,
  };

  const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
    queryKeySum(instance, batch, sector ?? "na", subsector ?? "na", startDate, endDate, tier, field, cumulatedSectors, cities, otherFilters, numericFilters),
    () => adaptUseQueryFetcher(service.getStatistics(params))
  );

  return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
}

interface MeanParams {
  instance: string,
  batch: string,
  sector: string | undefined,
  subsector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  cumulatedSectors?: boolean,
  cities: string[],
  numericFilters?: Record<string, any>,
  otherFilters?: Record<string, any>,
}

function queryKeyMean(
  instance: string,
  batch: string,
  sector: string | undefined,
  subsector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  cumulatedSectors: boolean,
  cities: string[],
  numericFilters: Record<string, any>|undefined,
  otherFilters: Record<string, any>|undefined,
) {
  return [`/mean/${instance}/${batch}/${sector}/${subsector}/${startDate}/${endDate}/${tier}/${field}/${cumulatedSectors}/${cities}/${JSON.stringify(otherFilters)}/${JSON.stringify(numericFilters)}`];
}

export function useMean({
  instance,
  batch,
  sector,
  subsector,
  startDate,
  endDate,
  tier,
  field,
  cumulatedSectors = false,
  cities,
  numericFilters,
  otherFilters,
}: MeanParams) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const params: IStatisticsParameters = {
    instance,
    batch,
    sector,
    subsector,
    date_min: startDate,
    date_max: endDate,
    tier,
    freq: "year",
    environment,
    fieldStat: field,
    funcStat: "avg",
    numberOfBins: 1,
    cumulated_sectors: cumulatedSectors,
    city_code: cities,
    filters: _.isEmpty(otherFilters) ? undefined : otherFilters,
    filter_numeric: _.isEmpty(numericFilters) ? undefined : numericFilters,
  };

  const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
    queryKeyMean(instance, batch, sector ?? "na", subsector ?? "na", startDate, endDate, tier, field, cumulatedSectors, cities, numericFilters, otherFilters),
    () => adaptUseQueryFetcher(service.getStatistics(params))
  );

  return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
}

function queryKeyDistrictBarChart(
  instance: string,
  batch: string,
  sector: string,
  startDate: string,
  endDate: string,
  tier: number,
  fieldGroupBy: string,
  fieldStat: string,
  funcStat: string,
  districtName?: string
) {
  return [`/district-bar-chart/${instance}/${batch}/${sector}/${startDate}/${endDate}/${tier}/${fieldGroupBy}/${fieldStat}/${funcStat}/${districtName}`];
}

export function useDistrictBarchart(
  instance: string,
  batch: string,
  sector: string,
  startDate: string,
  endDate: string,
  tier: number,
  fieldGroupBy: string,
  fieldStat: string,
  funcStat: string,
  districtName?: string
): APISuccess<IStatisticsResponse> {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const filters = districtName ? {
    district_name: [districtName],
  } : undefined;

  const params: IStatisticsParameters = {
    instance,
    batch,
    sector,
    date_min: startDate,
    date_max: endDate,
    tier,
    freq: "year",
    environment,
    fieldStat,
    funcStat,
    filters,
    cols_total_group_by: districtName ? ["district_name", fieldGroupBy] : [fieldGroupBy],
    "detail_mode":"emissions_energy",
  };

  const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
    queryKeyDistrictBarChart(instance, batch, sector, startDate, endDate, tier, fieldGroupBy, fieldStat, funcStat, districtName),
    () => adaptUseQueryFetcher(service.getStatistics(params))
  );

  return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
}

function queryKeyScoreParameters(
  instance: string,
  batch: string,
  sector: string | undefined,
  subsector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  cities: string[],
  sumSectors?: string[],
  cumulatedSectors = false,
) {
  if (!sector && !sumSectors) {
    throw new Error("Either sector or sumSectors must be provided");
  }

  return [`/score-parameters/${instance}/${batch}/${sector}/${subsector}/${startDate}/${endDate}/${tier}/${field}/${sumSectors}/${cumulatedSectors}/${cities}`];
}

export function useScoreParameters(
  instance: string,
  batch: string,
  subsector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  cities: string[],
  sector?: string,
  sumSectors?: string[],
  cumulatedSectors = false,
) {
  if (!sector && !sumSectors) {
    throw new Error("Either sector or sumSectors must be provided");
  }

  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const params: IStatisticsParameters = {
    instance,
    batch,
    sector,
    sum_sectors: sumSectors,
    subsector,
    date_min: startDate,
    date_max: endDate,
    tier,
    freq: "year",
    environment,
    fieldStat: field,
    funcStat: "percentiles",
    numberOfBins: 10,
    alpha: 0.1,
    cumulated_sectors: cumulatedSectors,
    city_code: cities
  };

  const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
    queryKeyScoreParameters(instance, batch, sector ?? "na", subsector ?? "na", startDate, endDate, tier, field, cities, sumSectors, cumulatedSectors),
    () => adaptUseQueryFetcher(service.getStatistics(params))
  );

  return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
}

function queryKeyTimeSeries(
  instance: string,
  batch: string,
  sector: string,
  subsector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  detailMode: string,
  idShape: string,
  freq: string,
  cityCode?: string,
  groupBy = true
) {
  return [`/time-series/${instance}/${batch}/${sector}/${subsector}/${startDate}/${endDate}/${tier}/${detailMode}/${idShape}/${freq}/${cityCode}/${groupBy}`];
}

export function useTimeSeries(
  instance: string,
  batch: string,
  sector: string,
  subsector: string | undefined,
  startDate: string,
  endDate: string,
  tier: number,
  detailMode: string,
  idShape: string,
  freq: 'week'|'month',
  cityCode?: string,
  groupBy = true
) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const params: ITemporalParameters = {
    instance,
    batch,
    sector,
    subsector,
    tier,
    date_min: startDate,
    date_max: endDate,
    freq,
    environment,
    cols_total_group_by: groupBy ? ["district_name"] : undefined,
    id_shape: idShape,
    precisionMode: 0,
    detail_mode: detailMode,
    city_code: cityCode,
  }

  const res = useQuery<APISuccess<ITemporalResponse>, APIError>(
    queryKeyTimeSeries(instance, batch, sector, subsector, startDate, endDate, tier, detailMode, idShape, freq),
    () => adaptUseQueryFetcher(service.getTemporal(params))
  );

  return adaptUseQuerySuspenseResult<ITemporalResponse, false>(res);
}

function queryKeyTimeSeriesAllSectors(
  instance: string,
  batch: string,
  sectors: string[],
  startDate: string,
  endDate: string,
  tier: number,
  detailMode: string,
  districtName: string,
  freq: string,
) {
  return [`/time-series-all-sectors/${instance}/${batch}/${sectors}/${startDate}/${endDate}/${tier}/${detailMode}/${districtName}/${freq}`];
}

export function useTimeSeriesAllSectors(
  instance: string,
  batch: string,
  sectors: string[],
  startDate: string,
  endDate: string,  
  tier: number,
  detailMode: string,
  districtName: string,
  freq: 'week'|'month',
) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const params: ITemporalParameters = {
    instance,
    batch,
    tier,
    date_min: startDate,
    date_max: endDate,
    freq,
    environment,
    precisionMode: 0,
    detail_mode: detailMode,
    filters: { district_name: districtName },
    sum_sectors: sectors,
  }

  const res = useQuery<APISuccess<ITemporalResponse>, APIError>(
    queryKeyTimeSeriesAllSectors(instance, batch, sectors, startDate, endDate, tier, detailMode, districtName, freq),
    () => adaptUseQueryFetcher(service.getTemporal(params))
  );

  return adaptUseQuerySuspenseResult<ITemporalResponse, false>(res);
}

interface ITimeSeriesRoadNameParameters {
  instance: string;
  batch: string;
  sector: string;
  subsector: string;
  startDate: string;
  endDate: string;
  roadName: string;
  cityCode: string;
}

function queryKeyTimeSeriesRoadName(
  instance: string,
  batch: string,
  sector: string,
  subsector: string,
  startDate: string,
  endDate: string,
  roadName: string,
  cityCode: string,
) {
  return [`/time-series-road-name/${instance}/${batch}/${sector}/${subsector}/${startDate}/${endDate}/${roadName}/${cityCode}`];
}

export function useTimeSeriesRoadName(params: ITimeSeriesRoadNameParameters) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const paramsReq: ITemporalParameters = {
    batch: params.batch,
    sector: params.sector !== "all" ? params.sector : undefined,
    subsector: params.subsector !== "all" ? params.subsector : undefined,
    detail_mode: "emissions_energy",
    tier: 3,
    date_min: params.startDate,
    date_max: params.endDate,
    freq: "week",
    cols_total_group_by: ["road_name"],
    environment,
    road_name: params.roadName,
    city_code: params.cityCode,
    instance: params.instance,
    precisionMode: 0,
  }

  const res = useQuery<APISuccess<ITemporalResponse>, APIError>(
    queryKeyTimeSeriesRoadName(params.instance, params.batch, params.sector, params.subsector, params.startDate, params.endDate, params.roadName, params.cityCode),
    () => adaptUseQueryFetcher(service.getTemporal(paramsReq))
  );

  return adaptUseQuerySuspenseResult<ITemporalResponse, false>(res);
}

interface IFilterDistributionParameters {
  instance: string,
  batch: string,
  sector: string|undefined,
  startDate: string,
  endDate: string,
  tier: number,
  freq: TFreq,
  field: string,
  binsNumber: number,
  alpha: number,
  cities: string[],
};

function queryKeyFilterDistribution(
  instance: string,
  batch: string,
  sector: string|undefined,
  startDate: string,
  endDate: string,
  tier: number,
  field: string,
  binsNumber: number,
  funcStat: string,
  alpha: number,
) {
  return [`/filter-distribution/${instance}/${batch}/${sector}/${startDate}/${endDate}/${tier}/${field}/${binsNumber}/${funcStat}/${alpha}`];
}

export function useFilterDistribution(params: IFilterDistributionParameters) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const reqParams: IStatisticsParameters = {
    batch: params.batch,
    sector: params.sector === "all" ? undefined : params.sector,
    date_min: params.startDate,
    date_max: params.endDate,
    tier: params.tier,
    freq: params.freq,
    environment,
    fieldStat: params.field,
    funcStat: "distribution",
    numberOfBins: params.binsNumber,
    alpha: params.alpha ? params.alpha : undefined,
    instance: params.instance,
    filters: {},
    precisionMode: 0,
    cumulated_sectors: params.sector === "all" ? true : false,
    city_code: params.cities,
  };

  const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
    queryKeyFilterDistribution(params.instance, params.batch, params.sector, params.startDate, params.endDate, params.tier, params.field, params.binsNumber, "percentiles", params.alpha),
    () => adaptUseQueryFetcher(service.getStatistics(reqParams))
  );

  return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
}

interface IFilterOptionsParameters {
  instance: string;
  batch: string;
  sector: string;
  subsector: string;
  startDate: string;
  endDate: string;
  colGroupBy: string;
  cities: string[];
  tier: number;
}

function queryKeyFilterOptions(
  instance: string,
  batch: string,
  sector: string,
  subsector: string,
  detailMode: string,
  startDate: string,
  endDate: string,
  colGroupBy: string,
  cities: string[],
  tier: number,
) {
  return [`/filter-options/${instance}/${batch}/${sector}/${subsector}/${startDate}/${endDate}/${tier}/${colGroupBy}/${detailMode}/${cities}`];
}

export function useFilterOptions(params: IFilterOptionsParameters) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const reqParams: IStatisticsParameters = {
    batch: params.batch,
    sector: params.sector === "all" ? undefined : params.sector,
    subsector: params.subsector === "all" ? undefined : params.subsector,
    detail_mode: "emissions_energy",
    date_min: params.startDate,
    date_max: params.endDate,
    tier: params.tier,
    freq: "year",
    environment,
    cols_total_group_by: [params.colGroupBy],
    city_code: params.cities,
    instance: params.instance,
    precisionMode: 0,
    cumulated_sectors: params.sector === "all" ? true : false,
    funcStat: "sum",
    fieldStat: "ghg_emissions_tco2eq",
  };

  const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
    queryKeyFilterOptions(params.instance, params.batch, params.sector, params.subsector, "", params.startDate, params.endDate, params.colGroupBy, params.cities, params.tier),
    () => adaptUseQueryFetcher(service.getStatistics(reqParams))
  );

  return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
}

interface IExportParameters {
  instance: string,
  batch: string,
  sector: string|undefined,
  subsector: string|undefined,
  startDate: string,
  endDate: string,
  freq: "month" | "year",
  tier: number,
  idShape: string,
  cities: string[],
}

function queryKeyExport(
  instance: string,
  batch: string,
  sector: string|undefined,
  subsector: string|undefined,
  startDate: string,
  endDate: string,
  tier: number,
  freq: "month" | "year",
  idShape: string,
  cities: string[],
) {
  return [`/export/${instance}/${batch}/${sector}/${subsector}/${startDate}/${endDate}/${tier}/${freq}/${idShape}/${cities}`];
}

export function useLazyExport(params: IExportParameters) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const reqParams: ITemporalParameters = {
    batch: params.batch,
    sector: params.sector === "all" ? undefined : params.sector,
    subsector: params.subsector === "all" ? undefined : params.subsector,
    detail_mode: "conso_energy",
    tier: params.tier,
    date_min: params.startDate,
    date_max: params.endDate,
    freq: params.freq,
    environment,
    cols_total_group_by: [params.sector === "roadtransport" ? "road_name" : "iris_code"],
    iris_code: params.sector === "roadtransport" ? undefined : params.idShape,
    road_name: params.sector === "roadtransport" ? params.idShape : undefined,
    instance: params.instance,
    precisionMode: 0,
    city_code: params.cities,
  };

  const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
    queryKeyExport(params.instance, params.batch, params.sector, params.subsector, params.startDate, params.endDate, params.tier, params.freq, params.idShape, params.cities),
    () => adaptUseQueryFetcher(service.getTemporal(reqParams)),
    {
      enabled: false,
      suspense: false,
    }
  );

  return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
}

export type IFeaturesParameters = {
  batch: string,
  sector: string,
  subsector?: string,
  dateMin: string,
  dateMax: string,
  tier: number,
  instance: string,
  selectedFeatures: string[],
  idShape?: string,
  districtName?: string,
};

function queryKeyFeatures(
  batch: string,
  sector: string,
  subsector: string,
  startDate: string,
  endDate: string,
  tier: number,
  instance: string,
  selectedFeatures: string[],
  idShape: string,
  districtName: string,
) {
  return [`/features/${batch}/${sector}/${subsector}/${startDate}/${endDate}/${tier}/${instance}/${selectedFeatures}/${idShape}/${districtName}`];
}

export function useFeatures(params: IFeaturesParameters) {
  const auth = useAuth();

  const service = useMemo(
    () => new DataAPIService(auth.user?.access_token ?? ""),
    [auth]
  );

  const reqParams: ISpatioTemporalParameters = {
    batch: params.batch,
    sector: params.sector === "all" ? undefined : params.sector,
    subsector: params.subsector === "all" ? undefined : params.subsector,
    detail_mode: "conso_energy",
    date_min: params.dateMin,
    date_max: params.dateMax,
    tier: params.tier,
    freq: "year",
    environment,
    id_shape: params?.idShape ?? undefined,
    filters: params?.districtName ? { district_name: params.districtName } : undefined,
    instance: params.instance,
    additional_features_to_select: params.selectedFeatures,
  };

  const res = useQuery<APISuccess<ISpatioTemporalResponse>, APIError>(
    queryKeyFeatures(params.batch, params.sector, params.subsector ?? "na", params.dateMin, params.dateMax, params.tier, params.instance, params.selectedFeatures, params.idShape ?? "na", params.districtName ?? "na"),
    () => adaptUseQueryFetcher(service.getSpatioTemporal(reqParams))
  );

  return adaptUseQuerySuspenseResult<ISpatioTemporalResponse, false>(res);
}

//   batch: string,
//   sector: string|undefined,
//   subsector: string|undefined,
//   startDate: string,
//   endDate: string,
//   tier: number,
//   field: string,
//   binsNumber: number,
//   alpha: number,
//   cities: string[],
// }

// export function useFilterOptions(params: IFilterOptionsParameters) {
//   const auth = useAuth();

//   const service = useMemo(
//     () => new DataAPIService(auth.user?.access_token ?? ""),
//     [auth]
//   );

//   const reqParams: IStatisticsParameters = {

//   };

//   const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
//     () => adaptUseQueryFetcher(service.getStatistics(reqParams))
//   );

//   return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);
// }

// export function useFiltersDistribution(params: IFilterDistributionParameters[]) {
//   const auth = useAuth();

//   const service = useMemo(
//     () => new DataAPIService(auth.user?.access_token ?? ""),
//     [auth]
//   );

//   const reqsParams: IStatisticsParameters[] = params.map((p) => ({
//     batch: p.batch,
//     sector: p.sector,
//     date_min: p.startDate,
//     date_max: p.endDate,
//     tier: p.tier,
//     freq: p.freq,
//     environment: "dev", // TODO: update this
//     fieldStat: p.field,
//     funcStat: "percentiles",
//     numberOfBins: p.binsNumber,
//     alpha: p.alpha,
//     instance: "france", // TODO: update this
//     filters: {},
//     precisionMode: 0,
//   }));

//   const res = useQueries<APISuccess<IStatisticsResponse>, APIError>()

//   const res = useQuery<APISuccess<IStatisticsResponse>, APIError>(
//     queryKeyFilterDistribution(params.batch, params.sector, params.startDate, params.endDate, params.tier, params.field, params.binsNumber, "percentiles", params.alpha),
//     () => adaptUseQueryFetcher(service.getStatistics(reqParams))
//   );

//   return adaptUseQuerySuspenseResult<IStatisticsResponse, false>(res);

// };