import MapboxDraw from "@mapbox/mapbox-gl-draw";
import React from "react";

const DrawControlContext = React.createContext<{ 
    drawControl: MapboxDraw|null, 
    setDrawControl: (draw: MapboxDraw) => void,
    isDrawing: boolean,
    setIsDrawing: (isDrawing: boolean) => void,
}>(
    {
        drawControl: null,
        setDrawControl: (draw: MapboxDraw) => {},
        isDrawing: false,
        setIsDrawing: (isDrawing: boolean) => {},
    },
);

export default DrawControlContext;