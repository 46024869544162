import { useContext, useEffect, useState } from "react";
import { MapStateContext } from "../../../contexts/mapStateContext";
import SelectDS from "../../../../../components/common/designSystem/SelectDS";
import { Divider, Select, Typography } from "antd";
import { Pie, PieChart, ResponsiveContainer } from "recharts";
import { EntityContext } from "../../../../../contexts/EntityContext";
import { getAvailableAnalysisFromFeatures, getPieChartDataFromFeatures, getTotalFromFeatures } from "./utils";
import AllSectorsDetails from "./AllSectorsDetails";
import { featureFieldNameToUnit, smartUnits } from "../../../../../services/utils";
import { DictionaryContext } from "../../../../../contexts/DictionaryContext";
import DetailsPieChart from "./piechart/DetailsPieChart";
import DetailsTimeSeries from "./time-series/DetailsTimeSeries";
import withSuspenseAndFallback from "../../../../../components/hoc/withSuspenseAndFallback";
import SkeletonLoader from "../../common/SkeletonLoader";

const GREY = "#555555";
const GREEN = "#00A947";

// TODO : hardcode based on sector what to display
// TODO : hardcode timeseries based on sector and tier

interface ValueDisplayProps {
  label: string;
  value: number;
  field: string;
  color: string;
}

const ValueDisplay: React.FC<ValueDisplayProps> = (props) => {
  const { label, value, field, color } = props;
  const dictionary = useContext(DictionaryContext);

  return (
    <div className="w-full flex justify-between p-2">
      <Typography.Text strong className="text-base">{label}: </Typography.Text>
      <Typography.Text strong className="text-base" style={{ color: color }}>{smartUnits(value, field, dictionary.features)}</Typography.Text>
    </div>
  )
};

interface Tier2DetailsProps {
  sector: string;
  subsector: string;
  features: Record<string, string | number> | undefined;
  avaibableAnalysis: string[];
}

const Tier2Details: React.FC<Tier2DetailsProps> = (props) => {
  const { features, sector, subsector } = props;

  const emissionsData = getPieChartDataFromFeatures(features, 'ghg_emissions_tco2eq', sector, subsector);
  const energyData = getPieChartDataFromFeatures(features, 'energy_consumption_mwh', sector, subsector);

  const avaibableAnalysis = (() => {
    const res = [];

    if (emissionsData.length > 0) {
      res.push('emissions');
    }

    if (energyData.length > 0) {
      res.push('energy');
    }

    return res;
  })();

  const [selectedAnalysis, setSelectedAnalysis] = useState<string>(avaibableAnalysis[0]);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    if (selectedAnalysis === 'emissions') {
      setData(emissionsData);
    } else if (selectedAnalysis === 'energy') {
      setData(energyData);
    }
  }, [selectedAnalysis, features, sector, subsector])

  const onChange = (value: string) => {
    if (value === 'emissions') {
      setData(emissionsData);
      setSelectedAnalysis('emissions');
    } else if (value === 'energy') {
      setData(energyData);
      setSelectedAnalysis('energy');
    } else {
      setData([]);
    }
  }

  if (avaibableAnalysis.length === 0) return <></>

  return (
    <>
      <div className="flex flex-row items-center justify-between p-2">
        <p className="font-bold text-lg">Répartition par énergie</p>
        <SelectDS defaultValue={selectedAnalysis} value={selectedAnalysis} onChange={onChange} width={100}>
          {avaibableAnalysis.map((analysis) => (
            <Select.Option value={analysis}>{
              analysis === 'emissions' ? 'CO2' : 'MWh'
            }</Select.Option>
          ))}
        </SelectDS>
      </div>
      <div className="overflow-hidden">
        <DetailsPieChart data={data} key={selectedAnalysis} />
      </div>
    </>
  )
};

const Tier3Details: React.FC = () => {
  return <></>
}

const DetailsSection: React.FC = () => {
  const { currentTier, selectedSector, selectedSubsector, selectedFeatures } = useContext(MapStateContext);

  const totalEmissions = getTotalFromFeatures(selectedFeatures, 'ghg_emissions_tco2eq', selectedSector, selectedSubsector);
  const totalEnergy = getTotalFromFeatures(selectedFeatures, 'energy_consumption_mwh', selectedSector, selectedSubsector);

  const avaibableAnalysis = getAvailableAnalysisFromFeatures(selectedFeatures);

  // Used for time series
  const idShape = (() => {
    if (currentTier === 2) {
      return selectedFeatures?.district_name as string;
    } else {
      if (selectedSector === 'roadtransport') {
        return selectedFeatures?.road_name as string;
      } 
      return selectedFeatures?.id_building as string;
    }
  })();

  const cityCode = selectedFeatures?.city_code as string;

  return (
    <div className="p-4">
      {/* {mapState.currentTier === 2 && <Tier2Details features={mapState.selectedFeatures} sector={mapState.selectedSector} subsector={mapState.selectedSubsector} />}
      {mapState.currentTier === 3 && <Tier3Details />}
      <div className="flex flex-col">
        <Typography.Text strong>Emissions totales de GES:</Typography.Text>
        <Typography.Text strong>Consommations énergétiques:</Typography.Text>
      </div> */}

      {/* <DetailsPieChart /> */}

      {selectedSector !== "all" && <Tier2Details features={selectedFeatures} sector={selectedSector} subsector={selectedSubsector} avaibableAnalysis={avaibableAnalysis} />}
      {currentTier === 2 && selectedSector === 'all' && <AllSectorsDetails features={selectedFeatures} />}
      {(!!totalEmissions || !!totalEnergy) && <Divider className="m-2" />}
      {!!totalEmissions &&
        <ValueDisplay
          label="Emissions totales de GES"
          value={totalEmissions}
          field="ghg_emissions_tco2eq"
          color={GREY}
        />
      }
      {!!totalEnergy &&
        <ValueDisplay
          label="Consommations énergétiques"
          value={totalEnergy}
          field={'energy_consumption_mwh'}
          color={GREEN}
        />
      }
      {!!idShape &&
        <>
          <Divider className="m-2" />
          <DetailsTimeSeries
            tier={currentTier}
            sector={selectedSector}
            subsector={selectedSubsector}
            features={selectedFeatures}
            idShape={idShape}
            cityCode={cityCode}
          />
        </>
      }
    </div>
  )
};

export default withSuspenseAndFallback(DetailsSection, <SkeletonLoader />);