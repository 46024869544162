import React, { useContext } from 'react';
import { useTimeSeriesAllSectors } from '../../../../../../services/dataApi/hooks';
import { EntityContext } from '../../../../../../contexts/EntityContext';
import { getDataRendering } from './utils';
import moment from 'moment';
import AreaChartV2, { AreaChartElement } from '../../../../../../components/graphs/AreaChart/AreaChartV2';
import { Typography } from 'antd';

interface AllSectorsTimeSeriesProps {
  districtName: string;
  startDate: string;
  endDate: string;
}

const AllSectorsTimeSeries: React.FC<AllSectorsTimeSeriesProps> = (props) => {
  const { activeEntity } = useContext(EntityContext);
  const { districtName, startDate, endDate } = props;

  const { data } = useTimeSeriesAllSectors(
    activeEntity.instance,
    activeEntity.batch,
    activeEntity.emittingSectors.map(s => s.sector), 
    startDate, 
    endDate,
    2,
    "conso_energy",
    districtName,
    "month"
  );
  
  if (!data || data.length === 0) return <></>;

  const field = "ghg_emissions_tco2eq";
  const { keys, dataRendering } = getDataRendering(field, data[0]);

  const areaChartData = data.map((d) => {
    const res: Record<string, any> = {
      x: moment(d.date_time).format("MMM YYYY"),
    };

    keys.forEach(k => {
      res[k] = d[k];
    });

    return res;
  });

  const areaChartProps = {
    data: areaChartData as unknown as AreaChartElement[],
    dataRendering,
    featureKey: field,
    selectedYear: 2023
  };
  
  return (
    <div className="w-full h-[250px] p-2">
      <Typography.Title level={4}>Evolution des émissions par secteur</Typography.Title>
      <AreaChartV2 {...areaChartProps} />
    </div>
  )
};

export default AllSectorsTimeSeries;