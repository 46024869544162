import { Typography } from "antd";
import React from "react";
import { boxShadow } from "../../../components/mapbox/constants";
import { LayoutContext } from "../../../contexts/LayoutContext";
import { colors } from "../../../assets/styles/colors";

const BottomInfo: React.FC<{ content: string | null }> = ({ content }) => {
  const { isDarkMode } = React.useContext(LayoutContext);
  if (!content || content === null) {
    return <></>;
  }

  const parts = content.split(/(\d+%)/g);
  return (
    <div
      style={{
        height: 64,
        width: "100%",
        backgroundColor: isDarkMode ? colors.darkGrey : colors.lightMode,
        boxShadow: boxShadow,
        borderRadius: 7,
        padding: 10,
        marginTop: 15,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography.Text>
        {parts.map((part, index) => {
          const isPercentage = /(\d+%)/.test(part);

          const style = isPercentage
            ? {
                color: isPercentage
                  ? "#4679F9"
                  : isDarkMode
                  ? colors.lightMode
                  : colors.darkMode,
              }
            : {};

          return (
            <Typography.Text key={index} style={style} strong={isPercentage}>
              {part}
            </Typography.Text>
          );
        })}
      </Typography.Text>
    </div>
  );
};

export default BottomInfo;
