import { ILayerFilter } from "../../models/exploreV2/filters";

function mapboxFilterExpressionFromFilters(filters: ILayerFilter[], sector: string) {
  const filterExpression: (
    | string
    | (number | string | (string | number)[])[]
  )[] = [];

  filters.forEach((filter) => {
    const field = (() => {
      if (sector !== "all" && filter.isPrefixed) return `${sector}_${filter.field}`;
      return filter.field;
    })();

    switch (filter.type) {
      case 'numeric':
        // Special case for numeric filters : if min or max is reached we do not filter
        if (filter.value[0] !== filter.min) filterExpression.push(['>=', field, filter.value[0]]);
        if (filter.value[1] !== filter.max) filterExpression.push(['<=', field, filter.value[1]]);
        break;
      case 'dropdown':
        filter.value
          ? filterExpression.push(['==', field, filter.value])
          : filterExpression.push(['==', field, ""])
        break;
      case 'checkbox':
        filter.value
          ? filterExpression.push(['in', field, ...filter.value ?? ""])
          : filterExpression.push(['in', field, ""])
        break;
    }
  });
  return filterExpression;
}

export { mapboxFilterExpressionFromFilters }
export default {};