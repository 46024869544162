import { IExplorationConfiguration } from "../../models/exploreV2/configuration";
import { TEmittingSector } from "../../models/general/common";
import { SectorConfiguration } from "../../models/general/entity";
import { MapState } from "./contexts/mapStateContext";

// TODO : from global config
const TILSET_NAME_MAPPING: Record<TEmittingSector, string> = {
  'all': 'sum',
  'industry': 'iny',
  'residential': 'rel',
  'services': 'ses',
  'roadtransport': 'rot',
  'agriculture': 'age',
  'waste': 'wae',
  'maritime': 'mae',
};

function layersFromConfig(
  sectors: SectorConfiguration[],
  config: IExplorationConfiguration,
  mapState: MapState,
  batch: string,
) {
  if (!config || !config.sectors) {
    return [];
  }

  const allSectorsConfig = config.sectors.find((sector) => sector.sector === 'all');
  const allSectorsField = allSectorsConfig?.tier2.fieldStat || 'ghg_emissions_tco2eq';

  const prefix = process.env.REACT_APP_ENV === 'dev' ? `t_dev_${batch}_` : `t_prd_${batch}_`;

  // 2D Layers
  const layersFill = sectors
    .map((elt) => {
      const sectorConfig = config.sectors.find((sector) => sector.sector === elt.sector);
      const field = sectorConfig?.tier2.fieldStat || 'ghg_emissions_tco2eq';

      return ({
        tileset: `${prefix}${TILSET_NAME_MAPPING[elt.sector]}_2023_tier2`,
        tier: 2,
        emittingSector: elt.sector,
        isVisible: mapState.selectedSector === elt.sector,
        type: 'fill',
        field: mapState.selectedSubsector === 'all' ? `${elt.sector}_${field}` : `${mapState.selectedSubsector}_${field}`,
        legendField: field,
      });
    })
    .concat({
      tileset: `${prefix}sum_2023_tier2`,
      tier: 2,
      emittingSector: 'all',
      isVisible: mapState.selectedSector === 'all',
      type: 'fill',
      field: allSectorsField || 'ghg_emissions_tco2eq',
      legendField: allSectorsField,
    });

  // 3D Layers
  const layersFillExtrusion = sectors
    .map((elt) => {
      const sectorConfig = config.sectors.find((sector) => sector.sector === elt.sector);
      const field = sectorConfig?.tier3?.fieldStat || 'ghg_emissions_tco2eq';

      return ({
        tileset: `${prefix}${TILSET_NAME_MAPPING[elt.sector]}_2023_tier3`,
        tier: 3,
        emittingSector: elt.sector,
        isVisible: mapState.selectedSector === elt.sector,
        type: elt.sector === 'roadtransport' ? 'line' : 'fill-extrusion',
        field: mapState.selectedSubsector === 'all' ? `${elt.sector}_${field}` : `${mapState.selectedSubsector}_${field}`,
        legendField: field,
      });
    });

  return layersFill.concat(layersFillExtrusion);
}

export { layersFromConfig };