import { useMemo } from "react";
import {
  APIError,
  APISuccess,
  adaptUseQueryFetcher,
  adaptUseQuerySuspenseResult,
} from "../common";
import { useQuery, useQueryClient, useMutation, QueryKey } from "react-query";
import { ExplorationService } from "./api";
import { useAuth } from "react-oidc-context";
import { IExplorationConfiguration } from "../../../models/exploreV2/configuration";
import { IAdditionalLayer } from "../../../models/explore/layers/additionnalLayer";
import { ILayer } from "../../../models/explore/layers/layer";
import { IAnalysis } from "../../../models/exploreV2/analysis";

export function queryKeyEntityExplorationConfig(entityId: string): QueryKey {
  return [`/explore/configuration`, entityId];
}

export function useExplorationConfig(
  entityId: string
): APISuccess<IExplorationConfiguration> {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<IExplorationConfiguration>, APIError>(
    queryKeyEntityExplorationConfig(entityId),
    () => adaptUseQueryFetcher(service.fetchExplorationConfig(entityId))
  );

  return adaptUseQuerySuspenseResult<IExplorationConfiguration, false>(res);
}

export function useUpdateExplorationConfig(entityId: string, id: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (conf: IExplorationConfiguration) =>
      adaptUseQueryFetcher(service.updateExplorationConfig(id, conf)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeyEntityExplorationConfig(entityId)
        );
      },
    }
  );
}

export function useSaveLayer(entityId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (conf: ILayer) => adaptUseQueryFetcher(service.saveLayer(conf)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeyEntityExplorationConfig(entityId)
        );
      },
    }
  );
}

export function useUpdateLayer(entityId: string, id: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (conf: ILayer) => adaptUseQueryFetcher(service.updateLayer(id, conf)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeyEntityExplorationConfig(entityId)
        );
      },
    }
  );
}

export function useDeleteLayer(entityId: string, id: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(() => adaptUseQueryFetcher(service.deleteLayer(id)), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyEntityExplorationConfig(entityId));
    },
  });
}

export function queryKeyEntityAnalyses(entityId: string): QueryKey {
  return [`/explore/analyses`, entityId];
}

export function useAnalyses(entityId: string): APISuccess<IAnalysis[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth?.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<IAnalysis[]>, APIError>(
    queryKeyEntityAnalyses(entityId),
    () => adaptUseQueryFetcher(service.fetchAnalyses(entityId))
  );

  return adaptUseQuerySuspenseResult<IAnalysis[], false>(res);
}

export function useUpdateAnalysis(entityId: string, id: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth?.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (analysis: IAnalysis) =>
      adaptUseQueryFetcher(service.updateAnalysis(id, analysis)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyEntityAnalyses(entityId));
      },
    }
  );
}

export function useDeleteAnalysis(entityId: string, id: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth?.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(() => adaptUseQueryFetcher(service.deleteAnalysis(id)), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeyEntityAnalyses(entityId));
    },
  });
}

export function useSaveAnalysis(entityId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth?.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (analysis: IAnalysis) =>
      adaptUseQueryFetcher(service.saveAnalysis(analysis)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyEntityAnalyses(entityId));
      },
    }
  );
}

export function useDeleteAdditionalLayer(entityId: string, id: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    () => adaptUseQueryFetcher(service.deleteAdditionalLayer(id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeyEntityExplorationConfig(entityId)
        );
      },
    }
  );
}

export function useSaveAdditionalLayer(entityId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (conf: IAdditionalLayer) =>
      adaptUseQueryFetcher(service.saveAdditionalLayer(conf)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeyEntityExplorationConfig(entityId)
        );
      },
    }
  );
}

export function useUpdateAdditionalLayer(entityId: string, id: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (conf: IAdditionalLayer) =>
      adaptUseQueryFetcher(service.updateAdditionalLayer(id, conf)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeyEntityExplorationConfig(entityId)
        );
      },
    }
  );
}

export function queryKeyQueryResult(
  aggType: string,
  table: string,
  field: string,
  cities: string[]
): QueryKey {
  return ["exploration", "queryResult", aggType, table, field, cities];
}

export function useQueryResult(
  aggType: string,
  table: string,
  field: string,
  cities: string[]
) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<number>, APIError>(
    queryKeyQueryResult(aggType, table, field, cities),
    () =>
      adaptUseQueryFetcher(
        service.fetchQueryResult(aggType, table, field, cities)
      )
  );

  return adaptUseQuerySuspenseResult<number, false>(res);
}

export function useAllSectorsStatistics(
  fieldSum: string,
  fieldMean: string,
  cities: string[],
  startDate: string,
  endDate: string,
  numericFilters: Record<string, any>,
  otherFilters: Record<string, any>
) {
  const auth = useAuth();

  const service = useMemo(
    () => new ExplorationService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<any>, APIError>(
    ["exploration", "allSectorsStatistics", fieldSum, fieldMean, cities],
    () =>
      adaptUseQueryFetcher(
        service.fetchAllSectorsStatistics(
          fieldSum,
          fieldMean,
          cities,
          startDate,
          endDate,
          numericFilters,
          otherFilters
        )
      )
  );

  return adaptUseQuerySuspenseResult<any, false>(res);
}