const BoatIcon = ({ fill = "#9BA5B7" }: { fill?: string }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2192_82292)">
        <path d="M27.6258 25.1881C26.732 25.1068 25.9195 24.7818 25.1883 24.2131C24.782 23.8068 24.2133 23.5631 23.5633 23.5631C22.9945 23.5631 22.3445 23.8068 21.9383 24.2131C21.207 24.8631 20.3133 25.1068 19.4195 25.1068C18.4445 25.1068 17.4695 24.7818 16.7383 24.2131C15.7633 23.3193 14.3008 23.3193 13.407 24.2131C12.6758 24.8631 11.7008 25.1881 10.6445 25.1881C9.66953 25.1881 8.77578 24.8631 8.04453 24.2131C7.55703 23.8068 6.98828 23.5631 6.41953 23.5631C5.76953 23.5631 5.03828 23.8068 4.55078 24.2131C3.73828 24.8631 2.68203 25.1881 1.70703 25.1068V23.5631C2.35703 23.5631 3.08828 23.3193 3.57578 22.8318C4.38828 22.1818 5.36328 21.8568 6.33828 21.9381C7.31328 21.9381 8.28828 22.2631 9.01953 22.9131C9.99453 23.8068 11.457 23.8068 12.432 22.9131C13.9758 21.6131 16.2508 21.6131 17.7133 22.9131C18.6883 23.8068 20.1508 23.8068 21.0445 22.9131C21.7758 22.2631 22.7508 21.9381 23.7258 21.9381C24.6195 21.9381 25.5133 22.2631 26.1633 22.9131C26.6508 23.3193 26.8133 23.5631 27.6258 23.5631V25.1881Z" fill={fill} />
        <path d="M3.33125 21.2875C3.81875 20.8813 4.30625 20.6375 4.875 20.475V18.6875C4.875 18.2 5.2 17.7937 5.6875 17.7937H26L23.075 20.3125H23.2375C23.8875 20.3125 24.5375 20.475 25.025 20.7187L27.0562 18.9312L27.2187 18.7688C27.7875 18.0375 27.625 17.0625 26.8937 16.4937C26.7312 16.3312 26.325 16.25 26 16.25H5.6875C4.30625 16.25 3.25 17.3062 3.25 18.6875L3.33125 21.2875Z" fill={fill} />
        <path d="M12.1055 15.3568H7.23047C6.33672 15.3568 5.60547 14.6255 5.60547 13.7318C5.60547 13.4068 5.68672 13.0818 5.93047 12.7568L9.26172 8.04429C9.74922 7.31304 10.8055 7.15054 11.5367 7.63804C11.943 7.96304 12.1867 8.45054 12.1867 8.93804V15.3568H12.1055Z" fill={fill} />
        <path d="M19.7438 15.3564H13V5.2002C13 4.30645 13.7313 3.5752 14.625 3.5752C15.1938 3.5752 15.6813 3.9002 16.0063 4.3877L21.125 12.8377C21.6125 13.6502 21.2875 14.6252 20.5563 15.0314C20.3125 15.1939 19.9875 15.2752 19.7438 15.3564Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_2192_82292">
          <rect width="29.25" height="29.25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};

export default BoatIcon;