import { useContext } from "react";
import { useLegend } from "../../../services/dataApi/hooks";
import { EntityContext } from "../../../contexts/EntityContext";

const BINS_NUMBER = 4;

// TODO : bins from conf, batch from conf and automatic date generation
const useLegendStops = (
  sector: string,
  field: string,
  tier: number,
  cities: string[],
): number[] => {
  const sectorArg = sector === "all" ? undefined : sector;
  const cumulatedSectors = sector === "all";
  const { activeEntity } = useContext(EntityContext);

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;

  const { data: legend } = useLegend(
    activeEntity.instance,
    activeEntity.batch,
    sectorArg,
    startDate,
    endDate,
    tier,
    field,
    BINS_NUMBER,
    cumulatedSectors,
    cities
  );

  const stops = legend?.[0]?.[field] ?? [];

  // Handle duplicates
  if (stops.length !== new Set(stops).size) {
    return stops.filter((value: number, index: number, self: any) => self.indexOf(value) === index);
  }

  return stops;
}

export default useLegendStops;