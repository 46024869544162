import { useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { useQuery } from "react-query";
import { TCityGroup } from "../../../models/general/cityGroup";
import {
  APIError,
  APISuccess,
  adaptUseQueryFetcher,
  adaptUseQuerySuspenseResult,
} from "../common";
import { GlobalConfigurationService } from "./api";
import { TLegend } from "../../../models/general/legend";

function queryKeyAllCityGroup(): string[] {
  return [`/city-groups`];
}

export function useAllCityGroups(): APISuccess<TCityGroup[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new GlobalConfigurationService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<TCityGroup[]>, APIError>(
    queryKeyAllCityGroup(),
    () => adaptUseQueryFetcher(service.fetchAllGroups())
  );

  return adaptUseQuerySuspenseResult<TCityGroup[], false>(res);
}

function queryKeyLegend(
  colorType: string,
  cityCodes: string[],
  table?: string,
  field?: string,
  quantileCount?: number
): string[] {
  return [
    `/legend`,
    colorType,
    table ?? "",
    field ?? "",
    quantileCount?.toString() ?? "",
    ...cityCodes,
  ];
}

export function useLegend(
  colorType: "linear" | "quantile",
  cityCodes: string[],
  table?: string,
  field?: string,
  quantileCount?: number
): APISuccess<TLegend> {
  const auth = useAuth();

  const service = useMemo(
    () => new GlobalConfigurationService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<TLegend>, APIError>(
    queryKeyLegend(colorType, cityCodes, table, field, quantileCount),
    () =>
      adaptUseQueryFetcher(
        service.fetchLegend(colorType, cityCodes, table, field, quantileCount)
      )
  );

  return adaptUseQuerySuspenseResult<TLegend, false>(res);
}

export function useAllInstances(): APISuccess<any[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new GlobalConfigurationService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<any[]>, APIError>(
    [`/instances`],
    () => adaptUseQueryFetcher(service.fetchAllInstances())
  );

  return adaptUseQuerySuspenseResult<any[], false>(res);
}

export function useBatches(instance: string): APISuccess<string[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new GlobalConfigurationService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery< APISuccess<string[]>, APIError>(
    ['batches', instance],
    () => adaptUseQueryFetcher(service.fetchBatches(instance))
  );
  
  return adaptUseQuerySuspenseResult<string[], false>(res);
}