import React from "react";
import { TCity, TCityDict } from "../models/general/city";
import { GroupFilter } from "../components/common/LocalisationSelector";
import { useCities } from "../services/api/entity/hooks";
import { EntityContext } from "./EntityContext";

export const PerimeterContext = React.createContext<{
  filters: GroupFilter;
  setFilters: (f: GroupFilter) => void;
  cities: string[];
  setCities: (cs: string[]) => void;
  maxPerimeter: {
    groups: string[];
    cities: string[];
  };
  relevantCityCodes: string[];
  cityDict: TCityDict;
}>({} as any);

const PerimeterProvider = ({ children }: { children: React.ReactElement }) => {
  const [cities, setCities] = React.useState<string[]>([]);
  const [filters, setFilters] = React.useState<GroupFilter>({} as GroupFilter);
  // Relevant city codes are either selected city codes either all cities available in entity
  const [relevantCityCodes, setRelevantCityCodes] = React.useState<string[]>(
    []
  );
  const { activeEntity } = React.useContext(EntityContext);
  const { data: allEntityCities } = useCities(
    activeEntity.id,
    activeEntity.groupCodes
  );

  const initialPerimeter = {
    filters,
    setFilters,
    cities,
    setCities,
    maxPerimeter: {
      groups: activeEntity.groupCodes,
      cities: allEntityCities.map((c: TCity) => c.cityCode),
    },
    relevantCityCodes,
    cityDict: allEntityCities.reduce((acc: any, item: any) => {
      acc[item.cityCode] = {
        name: item?.name,
        irisCodes: item?.irisCodes,
      };

      return acc;
    }, {}),
  };

  React.useEffect(() => {
    const newRelevantCityCodes =
      cities.length > 0
        ? cities
        : allEntityCities.map((c: TCity) => c.cityCode);

    setRelevantCityCodes(newRelevantCityCodes);
  }, [cities, allEntityCities]);

  return (
    <PerimeterContext.Provider value={initialPerimeter}>
      {children}
    </PerimeterContext.Provider>
  );
};

export default PerimeterProvider;
