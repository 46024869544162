import { IFilterConfiguration, ILayerFilter } from "../../../../../../models/exploreV2/filters";

function getRelevantFilters(
  filters: IFilterConfiguration[] | undefined,
  sector: string,
  subsector: string,
): IFilterConfiguration[] {
  if (!filters) return [];
  const res =  filters.filter((f) => {
    let sectorFlag = true;
    let subsectorFlag = true;
    let tierFlag = true;

    if (f.sectors && f.sectors.length > 0) {
      sectorFlag = f.sectors.includes(sector);
    }

    if (f.subsectors && f.subsectors.length > 0) {
      subsectorFlag = f.subsectors.includes(subsector);
    }
    return sectorFlag && subsectorFlag && tierFlag;
  });
  return res;
}

// TODO : change this (not the right way to compute)
function getDistributionFromQuantiles(
  quantiles: number[],
  step: number,
): number[] {
  const min = quantiles[0];
  const max = quantiles[quantiles.length - 1];

  return quantiles.map((q, i) => {
    if (i === 0) return q;
    return q - quantiles[i - 1];
  }).slice(1);
}

export { getRelevantFilters, getDistributionFromQuantiles };