const AgricultureIcon = ({ fill = "#9BA5B7" }: { fill?: string }) => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3743 5.16667C19.3743 4.45302 17.4446 3.875 15.4993 3.875C13.5541 3.875 11.6243 4.45302 11.6243 5.16667L11.0741 7.00148C11.3021 7.03635 11.5533 7.07317 11.8187 7.10998C12.9787 7.27144 14.3956 7.42708 15.4993 7.42708C16.6037 7.42708 18.02 7.27079 19.18 7.11063C19.4454 7.07317 19.6966 7.03635 19.9246 7.00148L19.3743 5.16667ZM20.3993 8.23502C20.097 8.28281 19.7425 8.33642 19.3569 8.39002C18.186 8.55148 16.6961 8.71875 15.4993 8.71875C14.302 8.71875 12.8127 8.55212 11.6418 8.38938C11.2939 8.34118 10.9464 8.28973 10.5994 8.23502C8.48237 8.70454 7.10352 9.46921 7.10352 10.3333C7.10352 11.7606 10.8623 12.9167 15.4993 12.9167C20.1364 12.9167 23.8952 11.7606 23.8952 10.3333C23.8952 9.46921 22.5163 8.70454 20.3993 8.23502Z" fill={fill} />
      <path d="M10.334 12.3701C10.3602 13.7228 10.9159 15.0113 11.8818 15.9587C12.8477 16.906 14.1467 17.4367 15.4997 17.4367C16.8526 17.4367 18.1516 16.906 19.1175 15.9587C20.0834 15.0113 20.6392 13.7228 20.6654 12.3701C20.2332 12.4731 19.7971 12.5592 19.3582 12.6285C19.2701 13.591 18.8254 14.4858 18.1115 15.1373C17.3975 15.7889 16.4659 16.1501 15.4994 16.1501C14.5328 16.1501 13.6012 15.7889 12.8872 15.1373C12.1733 14.4858 11.7286 13.591 11.6405 12.6285C11.2018 12.5592 10.766 12.473 10.334 12.3701Z" fill={fill} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1062 22.6045C10.6437 23.404 9.9118 24.0128 9.04143 24.3218V26.4795C9.04143 26.7055 9.00268 26.9232 8.93164 27.1253H27.1248V23.2503C27.1248 19.7518 19.3799 18.7295 15.4998 18.7295C14.2037 18.7362 12.9094 18.8255 11.6248 18.9969V22.6045H11.1062ZM15.4998 23.2503C14.6313 23.2493 13.7653 23.1563 12.9164 22.9726V20.1503C13.8639 20.0612 14.7558 20.0212 15.4998 20.0212C16.5757 20.0212 17.9597 20.1045 19.3748 20.3002V22.6045C19.3664 22.6077 17.7537 23.2503 15.4998 23.2503ZM22.4121 20.9202C22.0556 20.8181 21.6875 20.7264 21.3123 20.6431V22.6045H22.6039C22.7752 22.6045 22.9395 22.6725 23.0606 22.7937C23.1817 22.9148 23.2498 23.079 23.2498 23.2503V25.8337H25.8331V23.2503C25.8331 22.9235 25.6729 22.5451 25.0807 22.1027C24.4736 21.6493 23.5546 21.2469 22.4121 20.9202Z" fill={fill} />
      <path d="M5.16667 16.1458C5.16667 15.9745 5.09862 15.8103 4.97751 15.6892C4.85639 15.568 4.69212 15.5 4.52083 15.5C4.34955 15.5 4.18528 15.568 4.06416 15.6892C3.94304 15.8103 3.875 15.9745 3.875 16.1458V20.6667C3.875 21.3518 4.14717 22.0089 4.63164 22.4934C5.11611 22.9778 5.77319 23.25 6.45833 23.25V26.4792C6.45833 26.6505 6.52638 26.8147 6.64749 26.9358C6.76861 27.057 6.93288 27.125 7.10417 27.125C7.27545 27.125 7.43972 27.057 7.56084 26.9358C7.68196 26.8147 7.75 26.6505 7.75 26.4792V23.25C8.43514 23.25 9.09222 22.9778 9.57669 22.4934C10.0612 22.0089 10.3333 21.3518 10.3333 20.6667V16.1458C10.3333 15.9745 10.2653 15.8103 10.1442 15.6892C10.0231 15.568 9.85879 15.5 9.6875 15.5C9.51621 15.5 9.35194 15.568 9.23083 15.6892C9.10971 15.8103 9.04167 15.9745 9.04167 16.1458V20.6667C9.04167 21.0092 8.90558 21.3378 8.66335 21.58C8.42111 21.8222 8.09257 21.9583 7.75 21.9583V16.1458C7.75 15.9745 7.68196 15.8103 7.56084 15.6892C7.43972 15.568 7.27545 15.5 7.10417 15.5C6.93288 15.5 6.76861 15.568 6.64749 15.6892C6.52638 15.8103 6.45833 15.9745 6.45833 16.1458V21.9583C6.11576 21.9583 5.78722 21.8222 5.54499 21.58C5.30275 21.3378 5.16667 21.0092 5.16667 20.6667V16.1458Z" fill={fill} />
    </svg>

  )
};

export default AgricultureIcon;