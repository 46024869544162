import { useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { QueryKey, useMutation, useQuery, useQueryClient } from "react-query";
import { TGroupType } from "../../../models/general/cityGroup";
import { IEntity } from "../../../models/general/entity";
import {
  APIError,
  APISuccess,
  adaptUseQueryFetcher,
  adaptUseQuerySuspenseResult,
} from "../common";
import { UserService } from "./api";
import { IUserInput } from "../../../models/user";

export function queryKeyCityGroups(
  entityId: string,
  type: TGroupType,
  parentIds?: string[],
  authorisedIds?: string[]
): QueryKey {
  return [`/db-data/groups`, entityId, type, parentIds, authorisedIds];
}

export function queryKeyUserEntities(userId: string): QueryKey {
  return [`/api/users/entities`, userId];
}

export function useUserEntities(userId: string): APISuccess<IEntity[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new UserService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<IEntity[]>, APIError>(
    queryKeyUserEntities(userId),
    () => adaptUseQueryFetcher(service.fetchEntities(userId))
  );

  return adaptUseQuerySuspenseResult<IEntity[], false>(res);
}

export function queryKeyEntityUsers(entityId: string): QueryKey {
  return [`/api/entity/users`, entityId];
}

export function useEntityUsers(entityId: string): APISuccess<IUserInput[]> {
  const auth = useAuth();

  const service = useMemo(
    () => new UserService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<IUserInput[]>, APIError>(
    queryKeyEntityUsers(entityId),
    () => adaptUseQueryFetcher(service.fetchUsersByEntity(entityId))
  );

  return adaptUseQuerySuspenseResult<IUserInput[], false>(res);
}

export function useSaveUser(entityId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new UserService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (user: Omit<IUserInput, "id">) =>
      adaptUseQueryFetcher(service.saveUser(entityId, user)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyEntityUsers(entityId));
      },
    }
  );
}
export function useUpdateUser(entityId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new UserService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (user: IUserInput) => adaptUseQueryFetcher(service.updateUser(user)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyEntityUsers(entityId));
      },
    }
  );
}

export function useDeleteUser(entityId: string) {
  const auth = useAuth();

  const service = useMemo(
    () => new UserService(auth.user?.access_token ?? ""),
    [auth]
  );
  const queryClient = useQueryClient();

  return useMutation(
    (userId: string) => adaptUseQueryFetcher(service.deleteUser(userId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeyEntityUsers(entityId));
      },
    }
  );
}

export function useUserExportsLeft(userId: string): APISuccess<number|undefined> {
  const auth = useAuth();

  const service = useMemo(
    () => new UserService(auth.user?.access_token ?? ""),
    [auth]
  );

  const res = useQuery<APISuccess<number|undefined>, APIError>(
    ["exports-left", userId],
    () => adaptUseQueryFetcher(service.fetchExportsLeft(userId))
  );

  return adaptUseQuerySuspenseResult<number|undefined, false>(res);
}