import { Button, Typography } from "antd";
import { ILayerFilter } from "../../../../../../models/general/filters";
import _ from 'lodash';
import { CloseOutlined, MinusOutlined, PlusCircleTwoTone, PlusOutlined } from "@ant-design/icons";
import { IAdditionalLayer } from "../../../../../../models/exploreV2/configuration";
import { IAdditionalLayerSelected } from "./LayerSelection";

const SELECTED_COLOR = "#00A947";
const UNSELECTED_COLOR = "#919EAB";

interface DropdownRenderProps {
  layers: IAdditionalLayerSelected[];
  onSelectLayer: (f: any) => void;
  onClose: () => void;
}

const DropdownRender: React.FC<DropdownRenderProps> = (props) => {
  const { layers, onSelectLayer, onClose } = props;

  const filtersByCat = _.groupBy(layers, 'category')

  return (
    <div className="rounded-lg bg-white shadow-lg p-4 max-h-[400px] overflow-y-auto flex flex-col">
      <Button className="self-end" type="text" icon={<CloseOutlined style={{ color: UNSELECTED_COLOR }} />} onClick={onClose} />
      {Object.keys(filtersByCat).map((cat) => {
        return (
          <>
            <div key={cat} className="mb-2">
              <Typography.Title level={4}>{cat}</Typography.Title>
              {filtersByCat[cat].map((layer) => (
                <div
                  key={layer.name}
                  className="flex flex-row items-center justify-between"
                  style={{ color: layer.isSelected ? SELECTED_COLOR : UNSELECTED_COLOR }}
                >
                  <p>{layer.name}</p>
                  <Button
                    type="text"
                    icon={layer.isSelected ? <MinusOutlined style={{ color: SELECTED_COLOR }} /> : <PlusOutlined style={{ color: UNSELECTED_COLOR }} />}
                    onClick={() => {
                      onSelectLayer(layer);
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )
      })}
    </div>
  );
};

export default DropdownRender;