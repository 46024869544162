import { SectorConfiguration } from "../../../../../models/exploreV2/sector";
import { IDictionary } from "../../../../../models/general/dictionary";
import { smartUnits } from "../../../../../services/utils";

type Feature = Record<string, string | number> | undefined;

function isFeatureAvailable(feature: string, sector: string, subsector: string, features: Feature): boolean {
  if (features?.[feature] !== undefined && features?.[feature] !== null) return true;

  if (subsector === "all") {
    return features?.[`${sector}_${feature}`] !== undefined;
  } else {
    return features?.[`${subsector}_${feature}`] !== undefined;
  }

  return false;
}

function getFeaturesByCategory(
  features: string[],
  dictionary: IDictionary
): { category: string, features: string[] }[] {
  const res: { category: string, features: string[] }[] = [];
  features.forEach((feature) => {
    const category = dictionary.features[feature]?.category ?? "other";
    const index = res.findIndex((r) => r.category === category);

    if (index === -1) {
      res.push({ category, features: [feature] });
    } else {
      res[index].features.push(feature);
    }
  });

  return res;
};

function getFeatureValue(sector: string, subsector: string, feature: string, features: Feature, dictionary: IDictionary) {
  const value = (() => {
    if (features?.[feature] !== undefined) return features[feature];

    if (subsector === "all") {
      return features?.[`${sector}_${feature}`];
    } else {
      return features?.[`${subsector}_${feature}`];
    }
  })();

  const featureConfig = dictionary.features[feature];
  if (!featureConfig || !featureConfig.type) return value;
  switch (featureConfig.type) {
    case "number":
      return smartUnits(value as number, feature, dictionary.features);
    case "boolean":
      return value ? "Oui" : "Non";
    case "string":
      if (featureConfig.translation && value) {
        return featureConfig.translation?.[value as any] ?? value;
      } else {
        return value;
      }
    default:
      return value;
  }
};

/**
 * Return principal activity from tier3 features
 * Principal activity is the subsector in prefix of most heated surface
 * @param sector
 * @param features 
 */
function getPrincipalActivity(sector: string, features: Feature) {
  if (!features) return null;

  // Find subsectors fields
  const fields = Object.keys(features).filter((feature) => feature.endsWith("_heated_surface")).filter((f) => f !== `${sector}_heated_surface`);

  const biggestField = fields.reduce((acc, field) => {
    if (features[field] as number > acc.value) {
      return { value: features[field] as number, field };
    }
    return acc;
  }, { value: 0, field: "" });

  // Extract prefix
  const index = biggestField.field.indexOf("_heated_surface");
  if (index === -1) return "";
  return biggestField.field.slice(0, index);
};

// TODO : type this somewhere
function getPrimaryEnergy(data: Record<string, any>[], key: string, valueKey: string) {
  // Get element with biggest sum
  const biggest = data.reduce((acc, d) => {
    if (d[valueKey] > acc[valueKey]) {
      return d;
    }
    return acc;
  }, { [valueKey]: 0 });
  return biggest[key];
}

function getDisplayedFeatures(
  tier: number,
  config: SectorConfiguration|undefined,
): string[] {
  if (!config) return [];

  if (tier === 2) {
    return config.tier2.idConf.displayedFeatures;
  } else if (tier === 3) {
    return config.tier3?.idConf.displayedFeatures ?? [];
  } else {
    return [];
  }
}

export { getFeaturesByCategory, isFeatureAvailable, getFeatureValue, getPrincipalActivity, getPrimaryEnergy, getDisplayedFeatures }