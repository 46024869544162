import React from "react";
import {
  Bar,
  LabelList,
  BarChart as RBarChart,
  ResponsiveContainer,
  Tooltip as RTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors } from "../../../assets/styles/colors";
import {
  IBarChartData,
  TBarChartComponent,
} from "../../../models/dashboard/bar-chart";
import { DataContainer } from "../../common/DataContainer";
import BottomInfo from "./BottomInfo";
import CustomBarShape from "./Bars";
import {
  convertToUnit,
  mainUnit,
  readFromCommonDictionary,
} from "../../../services/utils";
import { DictionaryContext } from "../../../contexts/DictionaryContext";
import "./BarChart.css";
import Tooltip from "./Tooltip";
import { CaretDownOutlined } from "@ant-design/icons";

// Render down arrow indicating current value
const renderDownArrow = (props: any) => {
  const { x, y, width, name, highlightedName } = props;

  if (name === highlightedName) {
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 20}
          fill="red"
          fontSize={16}
          fontWeight="bold"
          textAnchor="middle"
        >
          ↓
        </text>
      </g>
    );
  }

  return null;
};

const CustomYAxisTick = ({ x, y, payload, feature, graphUnit }: any) => {
  const dictionary = React.useContext(DictionaryContext);
  return (
    <text x={x} y={y} dy={4} fill={colors.greyText} textAnchor="end">
      {convertToUnit(
        payload.value,
        feature,
        dictionary.features,
        graphUnit
      ).toString()}
    </text>
  );
};

const CustomXAxisTick = ({ x, y, payload, dictionary, rotateXAxis }: any) => {
  return (
    <text
      x={x}
      y={y}
      dy={6}
      fill={colors.greyText}
      textAnchor={rotateXAxis ? "end" : "middle"}
      fontSize={12}
      transform={rotateXAxis ? `rotate(-25, ${x}, ${y})` : ``}
    >
      {readFromCommonDictionary(dictionary, payload.value)}
    </text>
  );
};

interface BarChartProps {
  title: string;
  subtitle?: string;
  data: IBarChartData[];
  unit: string;
  decimals?: number;
  featureKey: string;
  stacked: boolean;
  tooltip?: { title: string; content: string };
  bottomInfo?: string | null;
  rotateXAxis?: boolean;
  highlightedXValue?: string;
}

const BarChart: React.FC<BarChartProps> = (props) => {
  const {
    title,
    subtitle,
    data,
    unit,
    decimals,
    featureKey,
    stacked,
    tooltip,
    bottomInfo,
    rotateXAxis,
    highlightedXValue,
  } = props;

  const [tooltipData, setTooltipData] = React.useState<any>(null);
  const allKeys: string[] = data.reduce(
    (acc: string[], cr: IBarChartData) => acc.concat(...Object?.keys(cr)),
    []
  );
  const dictionary = React.useContext(DictionaryContext);
  const [activeKey, setActiveKey] = React.useState("");

  const uniqueKeys: string[] = Array.from(new Set(allKeys)).filter(
    (k: string) => k !== "name"
  );

  const allValues = data
    .reduce((acc: any, cur: IBarChartData) => {
      const curCopy: any = { ...cur };
      delete curCopy.name;
      delete curCopy.background;
      return [...acc, ...Object.values(curCopy)];
    }, [])
    .map((v: number) => parseFloat(v.toFixed(decimals ?? 2)));

  const graphUnit = mainUnit(allValues, featureKey, dictionary.features);

  return (
    <>
      <DataContainer
        title={title}
        infoTitle={tooltip?.title}
        infoContent={tooltip?.content}
        subtitle={subtitle}
        height={300}
      >
        <>
          <ResponsiveContainer height={230} style={{ marginTop: 20 }}>
            <RBarChart
              className="barchart"
              data={data}
              onMouseLeave={() => setTooltipData(null)}
            >
              <XAxis
                type="category"
                dataKey="name"
                axisLine={{ display: "none" }}
                tickLine={{ stroke: "transparent" }}
                tick={
                  <CustomXAxisTick
                    dictionary={dictionary}
                    rotateXAxis={rotateXAxis}
                  />
                }
                interval={0}
              />
              <YAxis
                type="number"
                axisLine={{ display: "none" }}
                tickLine={{ stroke: "transparent" }}
                allowDataOverflow={true}
                tick={
                  <CustomYAxisTick feature={featureKey} graphUnit={graphUnit} />
                }
                label={{
                  value: "(Unité: " + unit + ")",
                  angle: -90,
                  dx: -40,
                  fill: colors.grey,
                  fontStyle: "italic",
                }}
                width={60}
              />
              {uniqueKeys.map((key: string) => {
                return (
                  <Bar
                    key={key}
                    dataKey={key}
                    barSize={10}
                    onMouseOver={(props) => setTooltipData(props)}
                    shape={(props: any) => (
                      <CustomBarShape
                        stacked={stacked}
                        currentDataKey={key}
                        props={props}
                        setActiveKey={setActiveKey}
                      />
                    )}
                    stackId={stacked ? "single-key" : key}
                    activeBar={false}
                  >
                    {
                      <LabelList
                        dataKey={key}
                        content={(props: any) =>
                          renderDownArrow({
                            ...props,
                            highlightedName: highlightedXValue,
                          })
                        }
                        position="top"
                      />
                    }
                  </Bar>
                );
              })}
              <RTooltip
                cursor={false}
                content={({ active, payload, label }) => (
                  <Tooltip
                    payload={payload}
                    label={label}
                    activeKey={stacked ? activeKey : featureKey}
                    unit={unit}
                    decimals={decimals}
                  />
                )}
              />
            </RBarChart>
          </ResponsiveContainer>
          {/* <Tooltip
            tooltipData={tooltipData}
            activeKey={activeKey}
            unit={unit}
            decimals={decimals}
          /> */}
        </>
      </DataContainer>
      {bottomInfo && <BottomInfo content={bottomInfo} />}
    </>
  );
};

export default BarChart;
