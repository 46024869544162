import React from "react";
import SelectMultiple from "../common/SelectMultiple";

interface FilterCheckboxProps {
  value?: string[];
  options: (string | number)[];
  onChange: (values: string[]) => void;
}

const FilterCheckbox = (props: FilterCheckboxProps) => {
  const { options, onChange } = props;
  const checkboxOptions = options.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <SelectMultiple
      options={checkboxOptions}
      onChange={(values: string[]) => {
        onChange(values);
      }}
      selectedValues={props.value ?? []}
      setSelectedValues={() => null}
    />
  );
};

export default FilterCheckbox;
