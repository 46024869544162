import { useContext } from "react";
import { EntityContext } from "../../../../../contexts/EntityContext";
import { useScoreParameters } from "../../../../../services/dataApi/hooks";
import { Typography } from "antd";
import Title from "antd/es/skeleton/Title";
import ProgressBar from "./ProgressBar";
import { DictionaryContext } from "../../../../../contexts/DictionaryContext";
import { PerimeterContext } from "../../../../../contexts/PerimeterContext";
import { colors } from "../../../../../assets/styles/colors";

interface GaugeDataProps {
  title: string;
  description: string;
  sector: string;
  subsector: string;
  tier: number;
  fieldStat: string;
  value: number;
};

/**
 * Gauge data showing progress bar with value relative to distrbution
 * @param props 
 * @returns 
 */
const GaugeData: React.FC<GaugeDataProps> = (props) => {
  const dictionary = useContext(DictionaryContext)
  const { activeEntity } = useContext(EntityContext);
  const { relevantCityCodes } = useContext(PerimeterContext);
  const { sector, subsector, tier, fieldStat, title, description, value } = props;

  const sectors = activeEntity.emittingSectors.map((s) => s.sector);

  const apiSector = sector === 'all' ? undefined : sector;
  const apiSubsector = subsector === 'all' ? undefined : subsector;
  const apiSectors = sector === 'all' ? sectors : undefined;

  const cumulatedSectors = sector === 'all';

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;  

  const { data: data } = useScoreParameters(
    activeEntity.instance,
    activeEntity.batch,
    apiSubsector,
    startDate,
    endDate,
    tier,
    fieldStat,
    relevantCityCodes,
    apiSector,
    apiSectors,
    cumulatedSectors
  );

  const bins = data?.[0]?.[fieldStat];

  // TODO : smarter way of handling units
  const unit = dictionary.features?.[fieldStat]?.unit ?? "";
  const unitPrefix = dictionary.features?.[fieldStat]?.unitsLinker?.["1"] ?? "";

  // There are 10 bins
  const index = (() => {
    if (!bins) return 0;
    if (value <= bins[0]) return 1;

    const idx = bins.findIndex((b: number) => b >= value);
    if (idx === -1) {
      return 10;
    }

    return idx;
  })();

  const percentage = (index / (bins.length - 1)) * 100;

  const displayExtremeties = value >= bins[0] && value <= bins[bins.length - 1];

  return (
    <div className="p-2">
      <div className="flex justify-between my-2">
        <Typography.Text style={{ fontSize: 16, fontWeight: 600, color: '#000' }}>{title}</Typography.Text>
        <Typography.Text style={{ color: "#00A947" }}>Top {Math.round(percentage)}%</Typography.Text>
      </div>
      <Typography.Text>{description}</Typography.Text>
      <ProgressBar min={bins[0]} max={bins[bins.length - 1]} value={value} unit={`${unitPrefix}${unit}`} displayExtremeties={false} />
    </div>
  )
};

export default GaugeData;
