import { Suspense, useContext, useEffect, useReducer, useState } from "react";
import Sidebar from "./components/map-analysis/Sidebar";
import MapboxMap from "../../components/mapbox/MapboxMapV2";
import React from "react";
import { EntityContext } from "../../contexts/EntityContext";
import TerritoryStatistics from "./components/statistics/TerritoryStatistics";
import { useExplorationConfig } from "../../services/api/exploration/hooks";
import { mapStateReducer } from "./reducers/mapStateReducer";
import {
  MapStateContext,
  MapStateDispatch,
  generateInitialMapState,
  initialMapState,
} from "./contexts/mapStateContext";
import LayerLegend from "../../components/mapbox/LayerLegendV2";
import { MapProvider } from "react-map-gl";
import MainLoader from "../../components/common/mainLoader";
import { layersFromConfig } from "./utils";
import StreetView from "./components/map-analysis/StreetView";
import ConfigContext from "./contexts/configContext";
import DrawControlContext from "../../components/mapbox/DrawControlContext";
import { TileInfos } from "./components/tile-infos";
import AnalysisStatistics from "./components/statistics/AnalysisStatistics";
import { Typography } from "antd";
import ReactGA from "react-ga4";
import MapContext, { mapInitialContext } from "./contexts/mapContext";
import { track } from "../../services/analytics/analytics";
import {
  EXPLORE_TARGET_SECTOR_CHANGE,
  EXPLORE_TARGET_SUBSECTOR_CHANGE,
} from "../../services/analytics/constants";

const ExploreTargetPageV2: React.FC = () => {
  const { activeEntity } = React.useContext(EntityContext);

  const { data: config } = useExplorationConfig(activeEntity.id);

  const [mapState, dispatch] = useReducer(
    mapStateReducer,
    generateInitialMapState(config)
  );
  const [map, setMap] = useState<any>(null); // Mapbox map instance

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const sectors = config?.sectors ?? [];

  const layers = layersFromConfig(
    activeEntity.emittingSectors,
    config,
    mapState,
    activeEntity.batch
  );

  const onCloseAnalysis = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  const onExport = () => {
    dispatch({ type: "SET_TRIGGER_EXPORT", triggerExport: true });
    ReactGA.event({
      category: "Explorer",
      action: "Export généré",
      label: "",
      value: new Date().getTime(),
    });
  };

  const onDeletePolygon = () => {
    mapState.drawControl?.deleteAll();
    mapState.drawControl?.changeMode("draw_polygon");
  };

  const onCancelExport = () => {
    mapState.drawControl?.deleteAll();
    mapState.drawControl?.changeMode("simple_select");
    dispatch({ type: "SET_IS_EXPORTING", isExporting: false });
  };

  return (
    <ConfigContext.Provider value={config}>
      <MapContext.Provider
        value={{
          map,
          setMap: (m: any) => setMap(m),
        }}
      >
        <MapStateContext.Provider value={mapState}>
          <MapStateDispatch.Provider value={dispatch}>
            <div
              className="relative h-full w-full overflow-hidden"
              key={activeEntity.id}
            >
              <Suspense
                fallback={
                  <div
                    className="w-full h-full"
                    style={{ paddingRight: sidebarCollapsed ? 0 : 250 }}
                  >
                    <MainLoader content="Chargement des données ..." />
                  </div>
                }
              >
                {/* Map container */}
                <div
                  className="h-full relative"
                  style={{
                    width: sidebarCollapsed ? "100%" : `calc(100% - 250px)`,
                    transition: "width 0.3s ease",
                  }}
                >
                  {/* Left side layout */}
                  {!mapState.isExporting && (
                    <div className="absolute flex flex-col p-2 z-10 h-full pointer-events-none">
                      {/* Top left details card */}
                      <div className="flex-1 flex flex-col overflow-hidden">
                        {mapState.selectedFeatures && (
                          <div className="rounded-lg overflow-hidden pointer-events-auto flex flex-col">
                            <TileInfos
                              onClose={() =>
                                dispatch({
                                  type: "SELECT_FEATURES",
                                  features: undefined,
                                  featuresType: "layer",
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="h-[20px]" />
                      {/* Bottom left statistics */}
                      <div className="pointer-events-auto flex flex-row">
                        <TerritoryStatistics config={config} />
                        <div className="w-2" />

                        {mapState.isAnalysisApplied && (
                          <AnalysisStatistics
                            config={config}
                            onClose={onCloseAnalysis}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {mapState.selectedFeatures && (
                    <div className="absolute top-2 right-12 z-10 w-[300px] h-[300px] rounded-lg overflow-hidden">
                      <StreetView />
                    </div>
                  )}
                  {/* Bottom right legend */}
                  {!mapState.isExporting && (
                    <div className="absolute bottom-6 right-2 z-10">
                      <LayerLegend />
                    </div>
                  )}
                  {/* Main map */}
                  <MapboxMap
                    mapId="mainMap"
                    layers={layers}
                    zoomThreshold={config.zoomThreshold}
                    additionalLayers={config.additionalLayers}
                  />
                  {/* Export zone */}
                  {mapState.isExporting && (
                    <div className="absolute bottom-0 h-[150px] w-full bg-black flex">
                      <div
                        className="flex-1 flex items-center justify-center bg-[#00A947]"
                        onClick={onExport}
                      >
                        <Typography.Text
                          style={{
                            color: "white",
                            pointerEvents: "none",
                            fontSize: 18,
                          }}
                        >
                          Exporter
                        </Typography.Text>
                      </div>
                      <div
                        className="flex-1 flex items-center justify-center bg-[#2121]"
                        onClick={onDeletePolygon}
                      >
                        <Typography.Text
                          style={{
                            color: "white",
                            pointerEvents: "none",
                            fontSize: 18,
                          }}
                        >
                          Supprimer polygone
                        </Typography.Text>
                      </div>
                      <div
                        className="flex-1 flex items-center justify-center bg-[#9BA5B7]"
                        onClick={onCancelExport}
                      >
                        <Typography.Text
                          style={{
                            color: "white",
                            pointerEvents: "none",
                            fontSize: 18,
                          }}
                        >
                          Annuler
                        </Typography.Text>
                      </div>
                    </div>
                  )}
                </div>
              </Suspense>
              {/* Sidebar */}
              <Sidebar
                isCollapsed={sidebarCollapsed}
                onToggleCollapse={toggleSidebar}
                sectors={sectors.map((s) => s.sector)}
                subsectors={activeEntity.subsectors}
                selectedSector={mapState.selectedSector}
                selectedSubsector={mapState.selectedSubsector}
                onSelectSector={(sector) => {
                  dispatch({ type: "SELECT_SECTOR", sector });
                  track(window, EXPLORE_TARGET_SECTOR_CHANGE);
                }}
                onSelectSubsector={(subsector) => {
                  dispatch({ type: "SELECT_SUBSECTOR", subsector });
                  track(window, EXPLORE_TARGET_SUBSECTOR_CHANGE);
                }}
                additionalLayers={config.additionalLayers}
                onToggleAddictionalLayer={() => {}}
                filters={config.filters}
                zoomThreshold={config.zoomThreshold}
              />
            </div>
          </MapStateDispatch.Provider>
        </MapStateContext.Provider>
      </MapContext.Provider>
    </ConfigContext.Provider>
  );
};

const ExploreTargetPageWithMapProvider: React.FC = () => {
  // Adding draw control
  const [drawControl, setDrawControl] = React.useState<any>(null);
  const [isDrawing, setIsDrawing] = React.useState(false);

  return (
    <MapProvider>
      <DrawControlContext.Provider
        value={{ drawControl, setDrawControl, isDrawing, setIsDrawing }}
      >
        <ExploreTargetPageV2 />
      </DrawControlContext.Provider>
    </MapProvider>
  );
};

export default ExploreTargetPageWithMapProvider;
