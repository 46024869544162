import { Bar } from "recharts";
import BarChart from "../../../../components/graphs/BarChartV2/BarChart";
import { featureFieldNameToLabel, featureFieldNameToUnit } from "../../../../services/utils";
import { useContext } from "react";
import { DictionaryContext } from "../../../../contexts/DictionaryContext";
import withSuspenseAndFallback from "../../../../components/hoc/withSuspenseAndFallback";
import Loader from "../../../../components/common/Loader";
import { useDistrictBarchart } from "../../../../services/dataApi/hooks";
import { LayoutContext } from "../../../../contexts/LayoutContext";
import { EntityContext } from "../../../../contexts/EntityContext";

interface BarchartProps {
  title: string;
  sector: string;
  fieldStat: string;
  fieldGroupBy: string;
  funcStat: string;
  districtName: string;
  includeDistrict: boolean;
  rotateXAxis?: boolean;
  xLabels?: string[];
  value?: number|string; // Used to display arrow on top of graph
}

const ExploreTargetBarchart: React.FC<BarchartProps> = (props) => {
  const { sector, fieldStat, districtName, fieldGroupBy, funcStat, title, includeDistrict, rotateXAxis , xLabels, value } = props;

  const dictionary = useContext(DictionaryContext);
  const { indexMapping } = useContext(LayoutContext);
  const { activeEntity } = useContext(EntityContext);

  const startDate = `${activeEntity.availableYears[0] ?? 2023}-01-01 00:00:00`;
  const endDate = `${activeEntity.availableYears[0] ?? 2023}-12-31 23:00:00`;

  // TODO : dates from conf
  const res = useDistrictBarchart(activeEntity.instance, activeEntity.batch, sector, startDate, endDate, 3, fieldGroupBy, fieldStat, funcStat, includeDistrict ? districtName : undefined);
  const data = res.data;

  const barChartData = (() => {
    if (xLabels) {
      return xLabels.map((name: string) => {
        const featureConfig = dictionary.features[fieldGroupBy];
        const tName = featureConfig?.translation?.[name] ?? name;
        return {
          name: tName,
          [fieldStat]: data.find((d: any) => d[fieldGroupBy] === name)?.[fieldStat] || 0,
        }
      });
    } else {
      return data.filter((d: any) => !!d[fieldGroupBy])
        .map((d: any) => ({
          name: d[fieldGroupBy],
          [fieldStat]: d[fieldStat],
        }))
        // We sort indexes if there is an indexMapping
        .sort((a: any, b: any) => {
          return indexMapping[a.name] - indexMapping[b.name];
        });
    }
  })();

  if (barChartData.length === 0) return null;

  const unit = (() => {
    if (dictionary.features?.[fieldStat]?.unit) {
      if (dictionary.features?.[fieldStat].unitsLinker && dictionary.features[fieldStat].unitsLinker?.[1]) return`${dictionary.features[fieldStat].unitsLinker?.[1]}${dictionary.features[fieldStat].unit}`;
      return dictionary.features[fieldStat].unit as string;
    }

    return '';
  })();

  return (
    <BarChart
      title={title}
      data={barChartData}
      unit={unit}
      featureKey={fieldStat}
      stacked={false}
      rotateXAxis={rotateXAxis}
      decimals={dictionary.features[fieldStat]?.decimal}
      highlightedXValue={value as string}
    />
  )
}

export default withSuspenseAndFallback(ExploreTargetBarchart, <Loader />);