import React from "react";
import { useAuth } from "react-oidc-context";
import { useUserEntities } from "../services/api/user/hooks";
import { userId } from "../services/utils";
import { IEntity } from "../models/general/entity";

export const EntityContext = React.createContext<{
  entities: IEntity[];
  activeEntity: IEntity;
  setActiveEntity: (e: IEntity) => void;
}>({} as any);

const EntityProvider = ({ children }: { children: React.ReactElement }) => {
  const auth = useAuth();
  const { data: entities } = useUserEntities(userId(auth));

  React.useEffect(() => {
    const newActiveEntity =
      entities.find((e: IEntity) => e.id === activeEntity.id) ?? activeEntity;

    setActiveEntity({ ...newActiveEntity });
  }, [entities]);

  // TODO : find a better way to set desired entity
  // By default we display first entity when user has many
  const [activeEntity, setActiveEntity] = React.useState<IEntity>(
    entities.find((e) => e.id === "abb284c6-7e0d-487d-b358-663875bf8bc0") ??
      entities[0]
  );

  const initialPerimeter = {
    entities: entities.sort((a: IEntity, b: IEntity) =>
      a.name?.localeCompare(b.name)
    ),
    activeEntity,
    setActiveEntity,
  };

  return (
    <EntityContext.Provider value={initialPerimeter}>
      {children}
    </EntityContext.Provider>
  );
};

export default EntityProvider;
