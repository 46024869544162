import { TCityGroup } from "../../../models/general/cityGroup";
import { TLegend } from "../../../models/general/legend";
import APIService, { APIResult, apiUrl } from "../common";

export class GlobalConfigurationService extends APIService {
  private route = `${apiUrl}/global-configuration`;

  public async fetchAllGroups(): Promise<APIResult<TCityGroup[]>> {
    return this.makeAxiosJSONRequest<TCityGroup[]>("GET", `${this.route}/groups`)
  }

  public async fetchLegend(colorType: string, cityCodes: string[], table?: string, field?: string, quantileCount?: number): Promise<APIResult<TLegend>> {
    return this.makeAxiosJSONRequest<TLegend>("GET", `${this.route}/legend`, { cityCodes, colorType, table, field, quantileCount })
  }

  public async fetchAllInstances(): Promise<APIResult<string[]>> {
    return this.makeAxiosJSONRequest<string[]>("GET", `${this.route}/instances`)
  }

  public async fetchBatches(instance: string): Promise<APIResult<string[]>> {
    return this.makeAxiosJSONRequest<string[]>("GET", `${this.route}/batches`, { instance })
  }
}
