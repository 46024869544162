import React from "react";
import { Moment } from "moment";
import 'moment/locale/fr';
import { AuthContextProps } from "react-oidc-context";
import * as XLSX from 'xlsx';
import { TLayerLevel } from "../models/general/common";
import { IDictionary, TFeaturesDictionary } from "../models/general/dictionary";

export function readFromCommonDictionary(dictionary: IDictionary, key: string) {
  return dictionary?.common?.[key] ?? key
}

export function readSankeyNameFromCommonDictionary(dictionary: IDictionary, key?: string) {
  const nameParts = key?.split(" - ")
  const renamedParts = nameParts?.map((n: string) => readFromCommonDictionary(dictionary, n))
  return renamedParts?.join(" - ")
}

export function featureFieldNameToLabel(dictionary: IDictionary, fieldName: string) {
  return dictionary.features[fieldName]?.label ?? fieldName;
}

export function featureCategoryFieldNameToLabel(dictionary: IDictionary, fieldName: string) {
  return dictionary.featuresCategories[fieldName] ?? fieldName;
}

export function featureFieldNameToDecimals(dictionary: IDictionary, fieldName: string) {
  return dictionary.features?.[fieldName]?.decimal ?? 2;
}

export function featureFieldNameToUnit(dictionary: IDictionary, fieldName: string) {
  return dictionary.features?.[fieldName]?.unit ?? "";
}

// Taking distance in km as param
export function getZoomLevel(distance: number) {
  if (distance > 1000) {
    return 7;
  }
  else if (distance > 500) {
    return 8;
  }
  else if (distance > 100) {
    return 9;
  }
  else if (distance > 25) {
    return 10
  }
  else if (distance > 15) {
    return 10.5
  }
  else if (distance > 10) {
    return 11;
  }
  else if (distance > 5) {
    return 11.5;
  }
  else if (distance > 1) {
    return 12;
  }
  else if (distance > 0) {
    return 13;
  }
  else {
    return 11;
  }
}

export function getMonthName(monthNumber: number) {
  const months = [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ];

  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "";
  }
}

export function groupCodesForUser(auth: AuthContextProps) {
  return JSON.parse(auth.user?.profile.groupCodes as string ?? "[]")
}

export function userId(auth: AuthContextProps) {
  return auth.user?.profile?.sub ?? ""
}

export function isAdmin(auth: AuthContextProps) {
  const roles = [auth.user?.profile.realm_access as any][0][0].roles
  return roles?.includes('admin') || isSuperAdmin(auth)
}

export function isSuperAdmin(auth: AuthContextProps) {
  const roles = [auth.user?.profile.realm_access as any][0][0].roles
  return roles?.includes('super-admin')
}

export function exportToExcel(data: any, fileName: string) {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Données');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function exportToExcelMultiple(dataObject: any, fileName: string, skipHeader?: any) {
  const workbook = XLSX.utils.book_new();
  Object.keys(dataObject).map((k: string) => {
    const worksheet = XLSX.utils.json_to_sheet(dataObject[k], { skipHeader: skipHeader?.[k] ?? false });
    XLSX.utils.book_append_sheet(workbook, worksheet, k.slice(0, 30));
  })

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function getYearOptions() {
  const yearOptions = Array.from(
    { length: new Date().getFullYear() - 2019 },
    (_, index) => 2019 + index
  );

  return yearOptions
}

export function sortByName<T extends { name: string }>(data: T[]) {
  return data
    .sort((a: T, b: T) => {
      function extractParts(str: string) {
        var match = str.match(/([^\d]+)(\d*)/) as any;
        return {
          alpha: match[1],
          numeric: match[2] !== "" ? parseInt(match[2], 10) : 0
        };
      }

      // Extract non-numeric and numeric parts from both strings
      var partsA = extractParts(a.name);
      var partsB = extractParts(b.name);

      // Compare non-numeric parts using localeCompare
      var alphaComparison = partsA.alpha.localeCompare(partsB.alpha);

      // If non-numeric parts are different, return the comparison
      if (alphaComparison !== 0) {
        return alphaComparison;
      }

      // Compare numeric parts
      return partsA.numeric - partsB.numeric;
    })
}

export function smartUnits(value: number, fieldName: string, features: TFeaturesDictionary) {
  const decimals = features?.[fieldName]?.decimal ?? 2
  const unit = features?.[fieldName]?.unit ?? ''
  const unitsLinker = features?.[fieldName]?.unitsLinker
  try {
    if (!unitsLinker) {
      return value?.toFixed(decimals) + " " + unit
    }

    const steps = Object.keys(unitsLinker)
    for (let i = 0; i < (steps.length - 1); i++) {
      if (value < parseFloat(steps[i + 1])) {
        return (value / parseFloat(steps[i]))?.toLocaleString('fr-FR', {
          style: 'decimal',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals
        }) + " " + unitsLinker[steps[i]] + unit
      }
    }
    return (value / parseFloat(steps[steps.length - 1]))?.toLocaleString('fr-FR', {
      style: 'decimal',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }) + " " + unitsLinker[steps[steps.length - 1]] + unit
  } catch (e) {
    return value?.toFixed(decimals) + " " + unit
  }
}

// Converts a value to a specific unit level (ex 30t -> 0.03kt)
export function convertToUnit(value: number, fieldName: string, features: TFeaturesDictionary, forcedUnit: string) {
  const unitsLinker = features?.[fieldName]?.unitsLinker
  try {
    if (!unitsLinker) {
      return value
    }

    const steps = Object.keys(unitsLinker)
    const units = Object.values(unitsLinker)
    const forcedUnitIndex = units.indexOf(forcedUnit);

    return value * parseFloat(steps[0]) / parseFloat(steps[forcedUnitIndex])
  } catch (e) {
    return "-"
  }
}

export function formatDateToMonth(date: Moment) {
  return date.locale('fr').format('MMM YY');
}

export function formatDateToYear(date: Moment) {
  return date.locale('fr').format('YYYY');
}

export function formatNowDate() {
  const now = new Date();

  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getNameFromLayerLevel(s: number|undefined) {
  switch (s) {
    case 2:
      return "Couches quartier (tier 2)";
    case 3:
      return "Couches batiment ou rue (tier 3)";
    default:
      return "";
  }
}

// Extracts the unit prefix in a complex unit (ex: kg -> k, MtCO2 -> Mt)
function getUnitFromSmartUnit(value: string) {
  if (!value) {
    return ""
  }
  return value.substring(value.indexOf(" ") + 1)
}

// Returns the main relevant unit in an array of values
export function mainUnit(values: number[], fieldName: string, features: TFeaturesDictionary) {
  const allUnits = values.map((val: number) => {
    return getUnitFromSmartUnit(smartUnits(val, fieldName, features));
  });

  const unitRepeats = new Map();

  allUnits.forEach((valeur: any) => {
    unitRepeats.set(valeur, (unitRepeats.get(valeur) || 0) + 1);
  });

  const [maxUnitRepeats] = [...unitRepeats.entries()].reduce(
    (max, entry) => (entry[1] > max[1] ? entry : max),
    [null, 0]
  );

  return maxUnitRepeats?.replace(
    features?.[fieldName]?.unit ?? "",
    ""
  )
}

export function useWindowWidth() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return { width, isWideScreen: width > 1000 };
}

export const hexToRgb = (hex: any) => {
  if (!hex) {
    return `1,1,1`
  }
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r},${g},${b}`;
};
