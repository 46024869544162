import { Select as AntSelect, SelectProps, Typography } from "antd";
import { selectorHeight, selectorWidth } from "../../../assets/styles/commons";

interface SelectDSProps extends SelectProps<any> {
  width?: number;
  height?: number;
}

const SelectDS: React.FC<SelectDSProps> = ({ ...props }) => {
  return (
    <AntSelect
      {...props}
      style={{
        boxShadow: "0px 3px 17px rgba(124, 141, 181, 0.122)",
        width: props.width ?? selectorWidth,
        height: props.height ?? selectorHeight,
        borderRadius: 14,
        margin: 5,
      }}
      placeholder={<Typography.Text>{props.placeholder}</Typography.Text>}
    >
      {props.children}
    </AntSelect>
  );
};

export default SelectDS;
