import React, { memo } from "react";
import {
  Area,
  CartesianGrid,
  AreaChart as RechartsAreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors } from "../../../assets/styles/colors";
import { DictionaryContext } from "../../../contexts/DictionaryContext";
import { LayoutContext } from "../../../contexts/LayoutContext";
import { convertToUnit, featureFieldNameToUnit, mainUnit, readFromCommonDictionary } from "../../../services/utils";
import ToolTipV2 from "./TooltipV2";

const sampleData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const CustomActiveDot = memo(function CustomCursor(props: any) {
  const { isActive, cx, cy, color } = props;
  return isActive ? (
    <circle
      pointerEvents={"none"}
      cx={cx}
      cy={cy}
      r={6}
      fill="white"
      stroke={color}
      strokeWidth={2}
    />
  ) : (
    <></>
  );
});

export type AreaChartElement = { x: string } & Record<string, number>;

const CustomXAxisTick = ({ x, y, payload, dictionary }: any) => {
  return (
    <text
      x={x}
      y={y}
      dy={6}
      fill={colors.greyText}
      textAnchor="end"
      fontSize={12}
      transform={`rotate(-25, ${x}, ${y})`}
    >
      {readFromCommonDictionary(dictionary, payload.value)}
    </text>
  );
};


interface AreaChartProps {
  data: AreaChartElement[];
  dataRendering: {
    key: string;
    strokeColor: string;
    fillColor: string;
  }[];
  featureKey: string;
  selectedYear?: number;
  intervalXAxis?: boolean;
}

const AreaChart = (props: AreaChartProps) => {
  const { data, dataRendering, featureKey } = props;

  const dictionary = React.useContext(DictionaryContext);
  const { isDarkMode } = React.useContext(LayoutContext);

  // Tooltip logic
  const [hoverIdx, setHoverIdx] = React.useState<number | null>(null);

  const allValues = data.reduce((acc: any, cur: any) => {
    const curCopy: any = { ...cur };
    delete curCopy.x;
    delete curCopy.year;
    // We take only max value of each data set because it fixes the "dominant" unit in the case of a stacked chart
    return [...acc, Math.max(...(Object.values(curCopy) as number[]))];
  }, []);

  const graphUnit = mainUnit(allValues, featureKey, dictionary.features);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsAreaChart
        className="areachart"
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid vertical={false} stroke="#ccc" />
        <XAxis
          dataKey="x"
          axisLine={false}
          tickLine={false}
          dy={20}
          tick={<CustomXAxisTick year={props.selectedYear} />}
          interval={props.intervalXAxis ? "preserveStartEnd" : 0}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          width={40}
          label={{
            value:
              "(Unité: " +
              graphUnit +
              dictionary.features[featureKey].unit +
              ")",
            angle: -90,
            dx: -40,
            fill: colors.grey,
            fontStyle: "italic",
          }}
          tickFormatter={(e) =>
            convertToUnit(
              e,
              featureKey,
              dictionary.features,
              graphUnit
            ).toString()
          }
        />
        {dataRendering.map((d, idx) => (
          <>
            <defs>
              <linearGradient
                id={`gradient-${idx}`}
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: d.strokeColor, stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: d.fillColor, stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey={d.key}
              stackId={1}
              stroke={d.strokeColor}
              strokeOpacity={1}
              strokeWidth={2}
              fill={`url(#gradient-${idx})`}
              fillOpacity={1}
              onMouseEnter={(e) => {
                setHoverIdx(idx);
              }}
              onMouseLeave={(e) => setHoverIdx(null)}
              dot={false}
              activeDot={
                <CustomActiveDot
                  isActive={hoverIdx === idx}
                  color={d.strokeColor}
                />
              }
            />
          </>
        ))}
        {/* <Tooltip /> */}
        <Tooltip
          content={
            <ToolTipV2 hoverIdx={hoverIdx} dataRendering={dataRendering} dictionary={dictionary} />
          }
          cursor={false}
          offset={30}
        />
        {/* {!!props.selectedYear && <ReferenceLine
          x={props.selectedYear}
          stroke={isDarkMode ? colors.lightMode : colors.darkMode}
          strokeDasharray="5 5"
        />} */}
      </RechartsAreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChart;
