import { CaretRightOutlined, HeartOutlined } from "@ant-design/icons";
import { Button, Divider, Select, Tooltip, Typography } from "antd";
import React, { useContext, useState } from "react";
import ReactGA from "react-ga4";
import ExportIcon from "../../../../assets/icons/exploreTarget/ExportIcon";
import ImportIcon from "../../../../assets/icons/exploreTarget/ImportIcon";
import DataIcon from "../../../../assets/icons/exploreTarget/data-topbar";
import AgricultureIcon from "../../../../assets/icons/sectors/AgricultureIcon";
import AllIcon from "../../../../assets/icons/sectors/All";
import BoatIcon from "../../../../assets/icons/sectors/BoatIcon";
import IndustryIcon from "../../../../assets/icons/sectors/IndustryIcon";
import ResidentialIcon from "../../../../assets/icons/sectors/Redidential";
import RoadIcon from "../../../../assets/icons/sectors/RoadIcon";
import ServicesIcon from "../../../../assets/icons/sectors/ServicesIcon";
import WasteIcon from "../../../../assets/icons/sectors/WasteIcon";
import SelectDS from "../../../../components/common/designSystem/SelectDS";
import { DictionaryContext } from "../../../../contexts/DictionaryContext";
import { IAnalysis } from "../../../../models/exploreV2/analysis";
import { IAdditionalLayer } from "../../../../models/exploreV2/configuration";
import { IFilterConfiguration } from "../../../../models/exploreV2/filters";
import { TEmittingSector } from "../../../../models/general/common";
import { readFromCommonDictionary } from "../../../../services/utils";
import {
  MapStateContext,
  MapStateDispatch,
} from "../../contexts/mapStateContext";
import LayerSelection from "./data-section/additional-layers/LayerSelection";
import AnalysisEditorModal from "./data-section/analysis-management/AnalysisEditorModal";
import PersonalSpace from "./data-section/analysis-management/PersonalSpace";
import ExploreFilters from "./data-section/filters/ExploreFilters";
import ExportContent from "./export-section/ExportContent";
import ImportContent from "./import-section/ImportContent";
import MapContext from "../../contexts/mapContext";
import { track } from "../../../../services/analytics/analytics";
import { EXPLORE_TARGET_ANALYSIS } from "../../../../services/analytics/constants";

const GREEN = "#00A947";
const LIGHT_GREEN = "#E6F6ED";
const GREY = "#9BA5B7";
const LIGHT_GREY = "#F9FAFF";

const sidebarBaseClass = `absolute top-0 bg-purple-300 h-full w-[250px] overflow-right-hidden`;
const collapsedClass = "right-[-250px]";
const expandedClass = "right-0";
const flexCenterClass = "flex items-center justify-center";

export const sectorsIconsMapping: Record<
  TEmittingSector,
  (fill?: string) => React.ReactElement
> = {
  industry: (fill?: string) => <IndustryIcon fill={fill} />,
  residential: (fill?: string) => <ResidentialIcon fill={fill} />,
  services: (fill?: string) => <ServicesIcon fill={fill} />,
  roadtransport: (fill?: string) => <RoadIcon fill={fill} />,
  waste: (fill?: string) => <WasteIcon fill={fill} />,
  agriculture: (fill?: string) => <AgricultureIcon fill={fill} />,
  maritime: (fill?: string) => <BoatIcon fill={fill} />,
};

const Subtitle: React.FC<{ title: string }> = ({
  title,
}: {
  title: string;
}) => {
  return <div className="px-3 font-extrabold text-lg">{title}</div>;
};

const SectorButton = ({
  selected,
  icon,
  onClick,
  title
}: {
  selected: boolean;
  icon: React.ReactNode;
  onClick: () => void;
  title: string,
}) => {
  return (
    <Tooltip title={title} placement="top" color="white" overlayInnerStyle={{ color: "#212121" }}>
      <Button
        onClick={onClick}
        className="w-[40px] h-[40px] flex items-center justify-center hover:border-red-600"
        style={{ backgroundColor: selected ? GREEN : "" }}
        shape="circle"
      >
        {icon}
      </Button>
    </Tooltip>
  );
};

interface SidebarProps {
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  sectors: TEmittingSector[];
  subsectors: { emittingSector: string; subsector: string }[];
  selectedSector: TEmittingSector | undefined;
  selectedSubsector: string | undefined;
  onSelectSector: (sector: string) => void;
  onSelectSubsector: (subsector: string) => void;
  additionalLayers?: IAdditionalLayer[];
  onToggleAddictionalLayer?: () => void;
  filters: IFilterConfiguration[];
  zoomThreshold: number;
}

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const {
    isCollapsed,
    onToggleCollapse,
    onSelectSector,
    onSelectSubsector,
    sectors,
    subsectors,
    selectedSector,
    selectedSubsector,
    additionalLayers = [],
    filters,
    zoomThreshold,
  } = props;

  const dictionary = useContext(DictionaryContext);
  const { map } = useContext(MapContext);

  const { visibleAdditionalLayers, activeFilters, selectedFilters } =
    useContext(MapStateContext);
  const mapStateDispatch = useContext(MapStateDispatch);

  const onToggleAddictionalLayer = (layerId: string) => {
    mapStateDispatch({ type: "TOGGLE_ADDITIONAL_LAYER", layerId });
  };

  const [selectedSection, setSelectedSection] = useState<
    "data" | "export" | "import"
  >("data");

  const _onSelectSector = (sector: string) => {
    onSelectSector(sector);
  };

  const _onSelectSubsector = (subsector: string) => {
    onSelectSubsector(subsector);
  };

  const onApplyAnalysis = () => {
    mapStateDispatch({ type: "APPLY_FILTERS" });
    // Zoom to building if needed
    const zoom = map?.getZoom();
    const shouldZoom = selectedFilters?.length && selectedFilters.length > 0 && selectedFilters.every(f => f.tier === 3);
    if (shouldZoom && zoom < zoomThreshold) {
      map?.easeTo({
        zoom: zoomThreshold
      });
    }
    
    track(window, EXPLORE_TARGET_ANALYSIS, { filters: selectedFilters });
    // ReactGA.event({
    //   category: "Explorer",
    //   action: "Analyse jouée",
    //   label: JSON.stringify(props),
    //   value: new Date().getTime(),
    // });
  };

  // TODO : if there are sectors in active entity, use them instead of the default ones
  const availableSubsectors = subsectors?.filter(
    (s) => s.emittingSector === selectedSector
  );

  // Analysis modal handling
  const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState(false);
  const [analysis, setAnalysis] = useState<IAnalysis>();

  const onSaveAnalysis = () => {
    const newAnalysis: IAnalysis = {
      id: "",
      authorId: "",
      entityId: "",
      name: "",
      description: "",
      isShared: false,
      configuration: {
        sector: selectedSector,
        subsector: selectedSubsector,
        filters: activeFilters,
      },
    };
    setAnalysis(newAnalysis);
    setIsAnalysisModalOpen(true);
  };

  return (
    <>
      <div
        className={
          isCollapsed
            ? `${sidebarBaseClass} ${collapsedClass}`
            : `${sidebarBaseClass} ${expandedClass}`
        }
        style={{
          transition: "right 0.3s ease-in-out",
        }}
      >
        {/* Collapse button */}
        <div
          className="absolute w-8 h-14 -left-7 bg-[#FFFFFF] rounded-md flex items-center justify-center top-[300px] z-0 cursor-pointer"
          onClick={onToggleCollapse}
        >
          {isCollapsed ? (
            <CaretRightOutlined rotate={180} />
          ) : (
            <CaretRightOutlined />
          )}
        </div>

        <div className="bg-[#FFFFFF] h-full drop-shadow-lg overflow-y-auto overflow-x-hidden">
          {/* Top bar */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1px 1fr 1px 1fr",
              justifyItems: "center",
              alignItems: "center",
              height: "40px",
              backgroundColor: LIGHT_GREY,
            }}
          >
            <Tooltip title="Analyse" color="white" overlayInnerStyle={{ color: "#212121" }}>
              <div
                className={`h-full w-full ${flexCenterClass} hover:opacity-20 cursor-pointer`}
                style={{
                  backgroundColor:
                    selectedSection === "data" ? LIGHT_GREEN : LIGHT_GREY,
                }}
                onClick={() => setSelectedSection("data")}
              >
                <DataIcon fill={selectedSection === "data" ? GREEN : undefined} />
              </div>
            </Tooltip>
            <div className="w-[1px] h-full bg-[#9BA5B7]" />
            <Tooltip title="Import" color="white" overlayInnerStyle={{ color: "#212121" }}>
              <div
                className={`h-full w-full ${flexCenterClass} hover:opacity-20 cursor-pointer`}
                style={{
                  backgroundColor:
                    selectedSection === "import" ? LIGHT_GREEN : LIGHT_GREY,
                }}
                onClick={() => setSelectedSection("import")}
              >
                <ImportIcon
                  fill={selectedSection === "import" ? GREEN : undefined}
                />
              </div>
            </Tooltip>
            <div className="w-[1px] h-full bg-[#9BA5B7]" />
            <Tooltip title="Export" color="white" overlayInnerStyle={{ color: "#212121" }}>
              <div
                className={`h-full w-full ${flexCenterClass} hover:opacity-20 cursor-pointer`}
                style={{
                  backgroundColor:
                    selectedSection === "export" ? LIGHT_GREEN : LIGHT_GREY,
                }}
                onClick={() => setSelectedSection("export")}
              >
                <ExportIcon
                  fill={selectedSection === "export" ? GREEN : undefined}
                />
              </div>
            </Tooltip>
          </div>

          {/* Sidebar content */}
          <div hidden={selectedSection != "data"}>
            <div className="px-3 my-3 space-y-3">
              <div className="flex items-center">
                <DataIcon fill={GREEN} />
                <div className="w-2" />
                <span
                  className="font-semibold text-xl"
                  style={{ color: GREEN }}
                >
                  Analyse
                </span>
              </div>
              <Subtitle title="Secteur" />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  rowGap: "15px",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <SectorButton
                  selected={selectedSector === "all"}
                  icon={
                    <AllIcon
                      fill={selectedSector === "all" ? "white" : undefined}
                    />
                  }
                  onClick={() => _onSelectSector("all")}
                  title={"Tous"}
                />
                {sectors.map((sector, index) =>
                  sectorsIconsMapping[sector] ? (
                    <SectorButton
                      selected={sector === selectedSector}
                      icon={sectorsIconsMapping[sector](
                        sector === selectedSector ? "white" : undefined
                      )}
                      onClick={() => _onSelectSector(sector)}
                      title={readFromCommonDictionary(dictionary, sector)}
                    />
                  ) : null
                )}
              </div>
              <Subtitle title="Sous-secteur" />
              <SelectDS
                value={selectedSubsector || "all"}
                defaultValue={"all"}
                onChange={(value) => _onSelectSubsector(value)}
              >
                <Select.Option key="all">Tous</Select.Option>
                {availableSubsectors?.map((s) => (
                  <Select.Option key={s.subsector}>
                    {readFromCommonDictionary(dictionary, s.subsector)}
                  </Select.Option>
                ))}
              </SelectDS>
              <Subtitle title="Filtres" />
              <div className="px-3">
                <ExploreFilters filters={filters} />
              </div>
              <div className="flex flex-row justify-between items-center px-2">
                <Button type="primary" onClick={onApplyAnalysis}>
                  Lancer mon analyse
                </Button>
                <Button
                  type="default"
                  icon={<HeartOutlined />}
                  onClick={onSaveAnalysis}
                />
              </div>
              <Divider className="m-0" />
              <div>
                <Subtitle title="Infos additionnelles" />
                <div className="px-3 my-3">
                  {additionalLayers.length > 0 ? (
                    <LayerSelection layers={additionalLayers} />
                  ) : (
                    <Typography.Text style={{ fontStyle: "italic" }}>
                      Aucune couche additionelle configurée
                    </Typography.Text>
                  )}
                </div>
              </div>
            </div>
            <PersonalSpace zoomThreshold={zoomThreshold} />
          </div>

          <div className="px-3 my-3" hidden={selectedSection !== "import"}>
            <div className="flex items-center">
              <ImportIcon fill={GREEN} />
              <div className="w-2" />
              <span
                className="font-semibold text-xl"
                style={{ color: GREEN }}
              >
                Importer une carte
              </span>
            </div>
            <ImportContent />
          </div>

          <div className="px-2 my-3" hidden={selectedSection !== "export"}>
            <div className="flex items-center">
              <ExportIcon fill={GREEN} />
              <div className="w-2" />
              <span
                className="font-semibold text-xl"
                style={{ color: GREEN }}
              >
                Exporter les résultats
              </span>
            </div>
            <ExportContent sectors={sectors} subsectors={subsectors} />
          </div>

        </div>
      </div>
      <AnalysisEditorModal
        key="analysis-editor-modal-creation"
        isCreation={true}
        isOpen={isAnalysisModalOpen}
        onClose={() => setIsAnalysisModalOpen(false)}
        analysis={analysis}
      />
    </>
  );
};

export default Sidebar;
