import React from "react";
import { Image, Space, Tooltip, Typography } from "antd";
import { colors } from "../../assets/styles/colors";
import INFO from "../../assets/icons/info.svg";
import { IInfoTooltip } from "../../models/general/common";
import { LayoutContext } from "../../contexts/LayoutContext";
import { type } from "@testing-library/user-event/dist/type";

const InfoTooltip: React.FC<IInfoTooltip> = ({ title, content }) => {
  const { isDarkMode } = React.useContext(LayoutContext);
  if (!(title || content)) {
    return <></>;
  }
  return (
    <Tooltip
      placement="topRight"
      style={{ padding: 0, margin: 0 }}
      arrow={{ pointAtCenter: true }}
      title={
        <Space direction="vertical" style={{ padding: 10 }}>
          {title && (
            <Typography.Text strong style={{ fontSize: 18 }}>
              {title}
            </Typography.Text>
          )}
          {typeof content === "string" ? (
            <Typography.Text style={{ textAlign: "justify" }}>
              {content}
            </Typography.Text>
          ) : (
            content
          )}
        </Space>
      }
      color={isDarkMode ? colors.darkMode : colors.lightMode}
    >
      <Image
        preview={false}
        src={INFO}
        style={{ width: 16, height: 16 }}
      ></Image>
    </Tooltip>
  );
};

export default InfoTooltip;
