import {
  GoogleMap,
  LoadScript,
  StreetViewPanorama,
} from "@react-google-maps/api";
import { Empty } from "antd";
import React, { Suspense, useContext } from "react";
import ErrorBoundaryWithFallback from "../../../../components/common/ErrorBoundaryWithFallback";
import Loader from "../../../../components/common/Loader";
import { MapStateContext } from "../../contexts/mapStateContext";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const StreetView: React.FC = () => {
  const { selectedLatLng, currentTier } = useContext(MapStateContext);
  const [center, setCenter] = React.useState<
    undefined | { lat: number; lng: number }
  >(undefined);
  const onLoad = React.useCallback((panorama: any) => {
    panorama.setVisible(true);
  }, []);

  React.useEffect(() => {
    setCenter(selectedLatLng?.lng && selectedLatLng?.lat ? { lng: selectedLatLng.lng as number, lat: selectedLatLng.lat as number } : undefined);
  }, [selectedLatLng]);

  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundaryWithFallback fallback={<Empty />}>
        {center && currentTier == 3 ? (
          <LoadScript googleMapsApiKey="AIzaSyCh_S9KV9xZKqJtblegJxC2DhV6DZmt1NY">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
            >
              <StreetViewPanorama
                options={{
                  pov: { heading: 165, pitch: 0 },
                  zoom: 1,
                  position: center,
                  visible: true,
                }}
                onLoad={onLoad}
              />
            </GoogleMap>
          </LoadScript>
        ) : (
          <></>
        )}
      </ErrorBoundaryWithFallback>
    </Suspense>
  );
};

export default StreetView;
